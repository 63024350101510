import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./routes";
import { IconButton, CrossIcon } from "evergreen-ui";
import { settingsActions } from "./actions";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonBStrap from "react-bootstrap/Button";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import HideComponentIfWebViewTrue from "./components/HideComponentIfWebViewTrue";

const Header = (props) => {
  const [isInfoBarCanceled, setInfo] = useState(false);

      if (isInfoBarCanceled) {
        return <div></div>;
      } else {
        if (props.settings && props.settings?.banner?.active) {
          return (
            <div className="headerInfoBar" style={{ backgroundColor: "#416fb4" }}>
              <div>
                <p
                  className="infoBar"
                  style={{ color: props.settings?.banner?.color }}
                >
                  {
                    //Infos Covid-19 : Pendant la crise sanitaire, tous nos techniciens disposent des moyens de protection et appliquent les gestes barrières.
                    props.settings?.banner?.text
                  }
                </p>
              </div>
              <div className="cancelInfoBar">
                <IconButton
                  height={16}
                  icon={CrossIcon}
                  iconSize={12}
                  onClick={() => setInfo(true)}
                />
              </div>
            </div>
          );
        } else return <div></div>;
  } 
};

function App(props) {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [popUp, setPopUp] = useState({
    title: "",
    content: "",
    closable: true,
  });

  /*function contains(target, pattern){
    var value = 0;
    pattern.forEach(function(word){
      value = value + target.includes(word);
    });
    return (value === 1)
  } */

  useEffect(() => {
    /*var arr = [
      'React Hook useEffect has missing dependencie',
      'The tag <e> is unrecognized in this browser',
      'Found 2 elements with non-unique id',
    ]
    const originalWarn2 = console.assert.bind(console.assert)

    console.assert = (msg) => 
      !msg.toString().includes('Warning:') && originalWarn2(msg)
      
    const originalWarn = console.warn.bind(console.warn)

    console.warn = (msg) => 
      !contains(msg.toString(),arr)  && originalWarn(msg)
      //!msg.toString().includes('React Hook useEffect has missing dependencies: ') && originalWarn(msg)
   
      //console.warn = originalWarn
*/
    //window.gtag('config', 'AW-403051804');

    props.setSettings();
    if (props.settings && props.settings.popup) {
      const popup = props.settings.popup;
      setOpenPopUp(popup.active);
      setPopUp({
        title: popup.title,
        content: popup.content,
        closable: popup.closable,
      });
    } else setOpenPopUp(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    if (popUp.closable) setOpenPopUp(false);
  };
  return (
      <BrowserRouter>
    <>
      <div>
        <ToastContainer />
        <HideComponentIfWebViewTrue>
          <Header settings={props.settings} />
        </HideComponentIfWebViewTrue>
        <Routes />
      </div>
      <Dialog
        fullWidth={true}
        open={openPopUp}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{popUp.content}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {popUp.title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popUp.closable ? (
            <ButtonBStrap
              className="btn-gradient"
              onClick={handleClose}
              style={{ margin: 12 }}
            >
              <u>OK</u>
            </ButtonBStrap>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
      </BrowserRouter>
  );
}

function mapState(state) {
  const { loading, settings } = state.settings;
  return { loading, settings };
}

const actionCreators = {
  setSettings: settingsActions.getSettings,
};

const connectedApp = connect(mapState, actionCreators)(App);
export default connectedApp;
