import React from "react";
import "./index.css";
import AppDownloadImage from "../../assets/images/appDownload-image.png";
import AppDownloadImageMobile from "../../assets/images/appDownload-image-mobile.png";

export default function AppDownloadV2() {
  return (
    <div className='appDownloadV2-container'>
      <div className='appDownloadV2-block'>
        <h1>Découvrez notre application !</h1>
        <div
          className='appDownloadV2-image-mobile'
          style={{ backgroundImage: `url(${AppDownloadImageMobile})` }}
        />
        <p>
          Grâce à l’application Neero, réalisez vous-même un pré-diagnostic en
          moins de 10mn et réservez un créneau de réparation. A vous l’atelier
          mobile !
          <br />
          <br />
          L'application est disponible sur les Stores pour tous vos appareils
          Android ou Apple, téléchargez là dès maintenant et testez vos
          appareils
        </p>
        <div className='appDownloadV2-stores'>
          <a
            href='https://apps.apple.com/us/app/neero/id1587685748?itscg=30200&itsct=apps_box_link'
            target='_blank' rel='noopener'
          >
            <img className='apple-store' alt='' />
          </a>
          <a
            target='_blank' rel='noopener'
            href='https://play.google.com/store/apps/details?id=co.trackn.neero&pli=1'
          >
            <img className='google-play' alt='' />
          </a>
        </div>
      </div>
      <div className='appDownloadV2-image'>
        <div style={{ backgroundImage: `url(${AppDownloadImage})` }} />
      </div>
    </div>
  );
}
