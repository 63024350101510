import { externals } from "../config";
import { authHeader } from '../helpers';
import { handleResponseAuth } from '../helpers';

const apiUrl = externals.apiUrl

export const discountService = {
    getDiscount,
    checkCoupon
};

function getDiscount(model, interventions, coupon) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            "model": model?.uuid.toString(),
            "interventions": interventions.map((obj) => ({ "uuid": obj.uuid.toString() })),
            "voucher": coupon
          })
    };
    return fetch(`${apiUrl}/api/v1/requester/refresh-cart`, requestOptions).then(handleResponse)
}

function checkCoupon(coupon) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({ "voucher": coupon.toString() })
    };

    return fetch(`${apiUrl}/api/v1/requester/apply-voucher`, requestOptions).then(handleResponseAuth);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
