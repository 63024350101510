import { externals } from "../config";
import { authHeader } from '../helpers';

const apiUrl = externals.apiUrl

export const orderService = { 
    getOrders,
    getOrder
};

function getOrders() {
    const requestOptions = {
        method: 'GET',
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    };
    return fetch(`${apiUrl}/api/v1/requester/request-orders`, requestOptions)
    .then(handleResponse)
}

function getOrder(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    };
    return fetch(`${apiUrl}/api/v1/requester/request-order/${id}`, requestOptions)
    .then(handleResponse)
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
