import "./EntrepriseReviews.css"
import ProductReviews from "../Products/ProductReviews";
import RightArrowSvg from "../../assets/svgFiles/rightArrow";
import Button from 'react-bootstrap/Button';
import GostLogo from "../../assets/icons/gostLogo.png"

export function EntrepriseReviews({join}) {
    return(
        <div className="EntrepriseReviews-container">
            <div className="EntrepriseReviews-gost" style={{backgroundImage: `url(${GostLogo})`}} />
            <ProductReviews showBookingBtn={false} />
            <div className="EntrepriseReviews-block">
                <h1>Vous souhaitez discuter d’un partenariat avec Neero ?</h1>
                <Button 
                    className="btn-gradient-2 btn-align-center"  
                    onClick={join}
                    style={{marginTop: 30}}
                >
                    <u>{("je souhaite devenir partenaire").toUpperCase()}</u>
                    <RightArrowSvg/>
                </Button>
            </div> 
        </div>
    )
}