import { useEffect } from "react";
import "./index.css";
import { useHistory } from "react-router-dom";
import { contacts } from "../../config";
//import ReactPixel from 'react-facebook-pixel';

export function CguPage() {
  const history = useHistory();
  const email = "mailto:" + contacts.email;
  const telFull = "tel:" + contacts.telFull;
  const mediaTelFull = "tel:" + contacts.mediaTelFull;

  useEffect(() => {
    //ReactPixel.pageView()
  }, []);

  const mailto = e => {
    window.location = email;
    e.preventDefault();
  };

  return (
    <div className='cgu-container'>
      <div className='cgu-header'>
        <h1>
          Conditions générales <u>NEERO.FR</u>
        </h1>
        <h2>
          RCS de Paris sous le n° 880 624 655
          <br />
          103 Boulevard Macdonald 75019 Paris
          <br />
          {contacts.tel}
          <br />
          {contacts.email}
        </h2>
      </div>
      <div className='cgu'>
        <p>Date d’entrée en vigueur : 10/06/2021</p>

        <h2>1. Objet</h2>
        <p>
          La société TRACKN (« <b>NEERO.FR</b> ») propose sur le site internet{" "}
          <u onClick={() => history.push("/")}>www.NEERO.FR</u> (le « 
          <b>Site</b> ») à des particuliers (les « <b>Clients</b> ») un service
          de réparation expresse de leur appareil mobile (smartphone, tablette,
          etc.) (l’« <b>Appareil</b> ») sur le lieu à domicile, du travail ou à
          l’adresse souhaité, par des techniciens certifiés (les « 
          <b>Services</b> »).
        </p>
        <p>
          Les présentes conditions générales (les « <b>Conditions Générales</b>
           ») ont pour objet de définir les modalités et conditions d’accès au
          Site et les modalités de souscription et de fourniture des Services
          ainsi que de définir les droits et obligations des parties dans ce
          cadre.
        </p>
        <p>
          Les Conditions Générales prévalent sur toutes autres conditions
          générales ou particulières non expressément agrées par NEERO.FR.
        </p>
        <p>
          Elles sont accessibles et imprimables à tout moment par un lien direct
          en bas de page du Site et du récapitulatif de la commande.
        </p>

        <h2>2. Accès au Site et aux Services</h2>
        <h3>
          2.1 <span />
          Capacité juridique
        </h3>
        <p>
          Le Site et les Services sont accessibles à toute personne physique
          disposant de la pleine capacité juridique pour s’engager au titre des
          Conditions Générales. La personne physique qui ne dispose pas de la
          pleine capacité juridique ne peut accéder au Site et aux Services
          qu’avec l’accord de son représentant légal.
        </p>
        <h3>
          2.2 <span />
          Site et Services réservés aux particuliers et professionnels
        </h3>
        <p>
          Le Site et les Services s’adressent aux particuliers et aux
          professionnels, entendu comme professionnel toute personne liée
          directement ou indirectement à une activité rémunérée exercée de façon
          non occasionnelle dans tous les secteurs d’activité de l’industrie et
          du commerce qui agit pour ses propres besoins et non en qualité
          d’intermédiaire des services de neero.fr.
        </p>

        <h2>3. Inscription sur le Site</h2>
        <p>
          L’utilisation des Services nécessite que le Client s’inscrive sur le
          Site.
        </p>
        <p>L’inscription peut s’effectuer :</p>
        <h6>
          <span>-</span>Soit en remplissant le formulaire prévu à cet effet ;
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>Soit par la validation du récapitulatif de commande (le
          « Récapitulatif de Commande »).
        </h6>
        <p>
          Le Client doit fournir l’ensemble des informations marquées comme
          obligatoires.
        </p>
        <p>Toute inscription incomplète ne sera pas validée.</p>

        <h2>4. Acceptation des Conditions Générales</h2>
        <p>
          L’acceptation des Conditions Générales par le Client est matérialisée
          par une case à cocher dans le Récapitulatif de Commande. Cette
          acceptation ne peut être que pleine et entière. Toute adhésion sous
          réserve est considérée comme nulle et non avenue. Le Client qui
          n’accepte pas d’être lié par les Conditions Générales ne doit pas
          accéder au Site ni utiliser les Services.
        </p>

        <h2>
          5. Articulation avec l’intervention et les conditions générales de
          Stripe
        </h2>
        <div className='cgu-p-h3'>
          <h3>5.1</h3>
          <p>
            Les Clients sont expressément informés et acceptent que tous les
            paiements effectués à travers le Site, sont gérés par la société
            Stripe France, société agréée en tant qu’établissement de monnaie
            électronique, immatriculée au RCS de Paris sous le n° 807 572 011,
            dont le siège social est situé au 10 boulevard Haussmann 75009 Paris
            (Établissement de monnaie électronique).
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>5.2</h3>
          <p>
            Les Clients contractent directement avec l’Établissement de monnaie
            électronique s’agissant de la mise en œuvre de ces transactions
            financières, en acceptant les conditions générales de
            l’Établissement de monnaie électronique qui figurent en annexe des
            présentes et auxquelles il est expressément fait référence,
            l’acceptation des Conditions Générales selon les modalités prévues à
            l’article 4 emportant ainsi expressément acceptation des conditions
            générales de l’Établissement de monnaie électronique.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>5.3</h3>
          <p>
            En cas de contradiction entre les conditions générales de
            l’Établissement de monnaie électronique et les présentes Conditions
            Générales, ces dernières prévalent.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>5.4</h3>
          <p>
            Dans le cadre des Services, le Client mandate expressément NEERO.FR
            pour transmettre toutes instructions à l’Établissement de monnaie
            électronique en leur nom et pour leur compte.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>5.5</h3>
          <p>
            Le Client est informé et accepte que le refus de l’Établissement de
            monnaie électronique d’accepter son inscription en tant
            qu’utilisateur de leurs propres services, de même que la fin du
            contrat entre le Client et l’Établissement de monnaie électronique,
            quel qu’en soit le motif, entraînera automatiquement et de plein
            droit la résolution du présent contrat entre NEERO.FR et ce Client.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>5.6</h3>
          <p>
            Inversement, la fin du présent contrat entre NEERO.FR et le Client
            entraînera automatiquement et de plein droit la résolution du
            contrat entre ce Client et l’Établissement de monnaie électronique.
          </p>
        </div>

        <h2>6. Description des Services</h2>
        <p>
          Avant toute souscription en ligne et en application notamment des
          dispositions de l’article L111-1 du Code de la consommation, le Client
          peut prendre connaissance des Services sur le Site.
        </p>
        <p>
          Le Client a accès aux Services qui suivent, sous une forme et selon
          les fonctionnalités et moyens techniques que NEERO.FR juge les plus
          appropriés.
        </p>
        <h3>
          6.1 <span />
          Accès à un Espace personnel
        </h3>
        <p>
          La validation du Récapitulatif de Commande entraîne automatiquement
          l’ouverture d’un compte au nom du Client (le « <b>Compte</b> »), lui
          donnant accès à un espace personnel (l’« <b>Espace Personnel</b> »)
          qui lui permet de gérer son utilisation des Services sous une forme et
          selon les moyens techniques que NEERO.FR juge les plus appropriés pour
          rendre lesdits Services.
        </p>
        <p>
          Le Client garantit que toutes les informations qu’il donne dans le
          Récapitulatif de Commande sont exactes, à jour et sincères et ne sont
          entachées d’aucun caractère trompeur.
        </p>
        <p>
          Il s’engage à mettre à jour ces informations dans son Espace Personnel
          en cas de modifications (notamment en cas de changement d’adresse
          email ou d’adresse du lieu de réparation), afin qu’elles correspondent
          toujours aux critères susvisés.
        </p>
        <p>
          Le Client est informé et accepte que les informations saisies aux fins
          de création ou de mise à jour de son Compte valent preuve de son
          identité. Les informations saisies par le Client l’engagent dès leur
          validation.
        </p>
        <p>
          Le Client peut accéder à tout moment à son Espace Personnel après
          s’être identifié à l’aide de son identifiant de connexion ainsi que de
          son mot de passe.
        </p>
        <p>
          Le Client s’engage à utiliser personnellement les Services et à ne
          permettre à aucun tiers de les utiliser à sa place ou pour son compte,
          sauf à en supporter l’entière responsabilité.
        </p>
        <p>
          Il est pareillement responsable du maintien de la confidentialité et
          de la sécurité de son identifiant et de son mot de passe, tout accès
          au Site à l’aide de ces derniers étant réputé effectué par le Client.
          Celui-ci doit immédiatement contacter NEERO.FR aux coordonnées
          mentionnées aux présentes s’il remarque que son Compte a été utilisé à
          son insu. Il reconnaît à NEERO.FR le droit de prendre toutes mesures
          appropriées en pareil cas.
        </p>
        <h3>
          6.2 <span />
          Sélection de la panne et réservation du créneau d’intervention
        </h3>
        <p>
          Dans le cadre de l’exécution des Services, le Client doit respecter
          les étapes suivantes :
        </p>
        <h6>
          <span>-</span>Sélectionner la marque de l’Appareil concerné par la
          réparation,
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>Sélectionner le type de panne à laquelle il est
          confronté parmi celles proposées sur le Site,
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>Sélectionner le créneau horaire d’intervention proposé
          sur le Site,
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>Remplir et valider le Récapitulatif de Commande.
        </h6>
        <p>
          Un mail de confirmation est envoyé au Client à l’adresse email
          indiquée dans son Espace personnel.
        </p>
        <p>
          Dans le cas d’une panne non constatée par le Client ou d’une panne
          supplémentaire à celle validée dans le Récapitulatif de Commande,
          celui-ci peut contacter NEERO.FR sur son Espace Personnel.
        </p>
        <p>
          Un devis sera élaboré par NEERO.FR (le « <b>Devis</b> ») :
        </p>
        <div className='cgu-p-h3'>
          <p>(i)</p>
          <p>
            soit sur la base d&#39;une description précise de la panne et des
            pièces à remplacer. Le Client peut s’aider de l’application mobile
            dédiée à l’aide au diagnostic. Cette application est gratuite et le
            lien de téléchargement est disponible sur le Site à l’adresse
            suivante www.neero.fr ;
          </p>
        </div>
        <div className='cgu-p-h3'>
          <p>(ii)</p>
          <p>
            soit sur la base d&#39;un envoi de l’Appareil à l’atelier afin de
            réaliser un diagnostic payant, accompagné d’une proposition de
            créneau horaire d’intervention. Le diagnostic suppose que le
            technicien soit en possession de l’Appareil. Tout diagnostic par
            téléphone ou par l’intermédiaire de l’application susvisée ne liera
            NEERO.FR qui pourra proposer un diagnotic différent lors de la
            Réparation de l’Appareil.
          </p>
        </div>
        <p>Le Devis sera accessible par le Client sur son Espace Personnel.</p>
        <p>
          Le Client pourra refuser ou accepter le Devis directement sur son
          Espace Personnel.
        </p>
        <p>
          Si le Devis est élaboré sur la base diagnostic approfondi de
          l&#39;Appareil, un forfait diagnostic est facturé au Client 19,90€ HT
          ainsi que les frais postaux pour le renvoi de l’Appareil si le Client
          refuse le Devis qui lui proposé.
        </p>
        <p>
          Dans le cas où le Client accepte le Devis et valide la réparation, le
          diagnostic sera inclus dans le prix de la Réparation. Dans le cas où
          la Réparation s’avère impossible, le prix du diagnostic sera facturé.
        </p>
        <p>
          En cas d’acceptation du Devis, le Client devra valider le créneau
          horaire d’intervention proposé. Si les créneaux horaires proposés ne
          conviennent pas au Client, NEERO.FR pourra proposer de nouveaux
          horaires. Toutefois, après trois refus successifs par le Client des
          créneaux horaires d’intervention proposés par NEERO.FR, ce dernier
          pourra décliner la demande d’intervention, la commande étant alors
          considérée comme non validée.
        </p>
        <h3>
          6.3 <span />
          Modalités d’intervention du technicien
        </h3>
        <h4>
          <span>6.3.1</span>
          La fourniture des Services de réparation peut s’effectuer au domicile
          du Client, en atelier mobile sur le lieu de travail ou au domicile de
          ce dernier, ou par envoi postal, à l’adresse indiquée dans le
          Récapitulatif de Commande, à condition que l’adresse indiquée
          appartienne à un département éligible.
        </h4>
        <p>
          Le choix du lieu de la réparation sera effectué directement sur le
          Site par le Client.
        </p>
        <p>Les départements éligibles sont indiqués sur le Site.</p>
        <p>
          Dans le cas où le Client opte pour l’envoi de l’Appareil par voie
          postal, NEERO.FR mettra à disposition sur son Espace personnel un
          bordereau de transport qui servira à acheminer l’Appareil de son
          domicile au lieu de réparation.
        </p>
        <h4>
          <span>6.3.2</span>
          Le Client est expressément informé et accepte que tous les transports,
          sont gérés par un prestataire dont l’identité sera communiquée par
          NEERO.FR.
        </h4>
        <p>
          Les Clients contractent directement avec ce transporteur s’agissant de
          la mise en œuvre de ces transports, en acceptant leurs conditions
          générales qui seront communiquées par NEERO.FR au moment de la
          passation de la commande.
        </p>
        <p>
          Si l’adresse indiquée ne fait pas partie des départements éligibles,
          le Client pourra envoyer l’Appareil dans les conditions prévues à
          l’article « Obligations du Client ».
        </p>
        <p>
          En cas de détection de pannes supplémentaires au cours de la
          réparation, NEERO.FR établi un nouveau Devis qui sera accessible
          depuis l’Espace Personnel du Client.
        </p>
        <p>
          Après intervention du technicien, s’il s’avère que l’Appareil ne peut
          pas être réparé à domicile ou en atelier mobile, en raison notamment
          de la complexité de la Réparation, le technicien informera le Client
          de la nécessité d’envoyer son Appareil pour une réparation en atelier
          et lui communiquera les délais de traitement prévisionnels ainsi que
          les coordonnées de la personne à contacter.
        </p>
        <p>
          Dans l’hypothèse NEEERO.FR ne parviendrait pas à réparer l’Appareil,
          celui-ci sera restitué au Client en l’état, le Client ne pouvant
          prétendre à aucune indemnisation.
        </p>
        <p>
          Le Client cède la propriété des pièces qui sont remplacées à NEERO.FR
          qui les conservera pour des fins de recyclage ou toute autre
          utilisation.
        </p>
        <h3>
          6.4 <span />
          Fin des Services
        </h3>
        <p>La fin des Services se matérialise par :</p>
        <h6>
          <span>-</span>Soit par la signature d’un bon de réception de
          l’Appareil par le Client,
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>Soit par le paiement par le Client après restitution de
          l’Appareil.
        </h6>
        <h3>
          6.5 <span />
          Autres Services
        </h3>
        <p>
          NEERO.FR se réserve la possibilité de proposer tout autre Service
          qu’elle jugera utile, sous une forme et selon les fonctionnalités et
          moyens techniques qu’elle estimera les plus appropriés pour rendre
          lesdits Services.
        </p>

        <h2>7. Conditions financières</h2>
        <h3>7.1 Prix</h3>
        <p>
          Les prix des Services sont indiqués sur le Site lorsque le Client
          sélectionne l’appareil et le type de panne.
        </p>
        <p>
          Sauf mention contraire, ils sont exprimés en Euros et toutes taxes
          françaises comprises.
        </p>
        <p>
          Le prix applicable est celui affiché sur le Site au moment de la
          validation du Récapitulatif de Commande par le Client.
        </p>
        <p>
          NEERO.FR se réserve le droit, à sa libre discrétion et selon des
          modalités dont elle sera seule juge, de proposer des offres
          promotionnelles ou réductions de prix.
        </p>
        <h3>7.2 Facturation</h3>
        <p>
          Les Services font l’objet de factures qui sont communiquées au Client
          par tout moyen utile, par email et dans son Espace Personnel.
        </p>
        <h3>7.3 Modalités de paiement</h3>
        <p>
          Le prix des Services est exigible à la fin des Services uniquement en
          cas de réussite de la réparation.
        </p>
        <p>Le Client recevra un email de confirmation à cet égard.</p>
        <p>
          Son paiement s’effectuera par carte bancaire, sur place ou en ligne, à
          travers le service de paiement sécurisé en ligne Stripe. Le Client
          procède également à l’empreinte de sa carte bancaire auprès de Stripe
          afin de simplifier ses prochaines Commandes.
        </p>
        <p>
          Le Client garantit à NEERO.FR qu&#39;il dispose des autorisations
          nécessaires pour utiliser le mode de paiement choisi.
        </p>
        <h3>7.4 Retards et incidents de paiement</h3>
        <p>
          Le Client est informé et accepte expressément que tout retard de
          paiement de tout ou partie d’une somme due à son échéance entraînera
          automatiquement, sans préjudice des dispositions des articles
          « sanction des manquements » et dès la première présentation d’une
          mise en demeure par lettre recommandée avec accusé de réception :
        </p>
        <div className='cgu-p-h3'>
          <p>(i)</p>
          <p>
            la déchéance du terme de l’ensemble des sommes dues par le Client et
            leur exigibilité immédiate ;
          </p>
        </div>
        <div className='cgu-p-h3'>
          <p>(ii)</p>
          <p>
            la suspension immédiate des Services en cours jusqu’au complet
            paiement de l’intégralité des sommes dues par le Client,
          </p>
        </div>
        <div className='cgu-p-h3'>
          <p>(iii)</p>
          <p>
            la facturation au profit de NEERO.FR d’un intérêt de retard au taux
            de 1,5 fois (une fois et demie) le taux de l’intérêt légal, assis
            sur le montant de l’intégralité des sommes dues par le Client.
          </p>
        </div>
        <h2>8. Conditions d’annulation des Services</h2>
        <p>
          NEERO.FR offre au Client la possibilité d&#39;annuler le Service
          commandé postérieurement à la validation du Récapitulatif de Commande.
        </p>
        <p>
          A ce titre, NEERO.FR se réserve le droit de facturer des frais
          d&#39;annulation au Client de X% du montant de la réparation.
        </p>

        <h2>9. Durée des Services, désinscription</h2>
        <p>Les Services sont souscrits pour une durée indéterminée.</p>
        <p>
          Le Client peut se désinscrire des Services à tout moment, en adressant
          une demande à cet effet à NEERO.FR par email, aux coordonnées
          mentionnées au début des présentes ou bien directement sur son Espace
          Personnel.
        </p>
        <p>
          La désinscription est effective dans un délai maximum de 7 (sept)
          jours à compter de cette demande.
        </p>
        <p>Elle entraîne la suppression automatique du Compte du Client.</p>
        <p>
          NEERO.FR se réserve par ailleurs le droit de fermer et supprimer tout
          Compte qui resterait inactif pendant une durée continue de 6 (six)
          mois.
        </p>

        <h2>10. Renonciation expresse au droit de rétractation</h2>
        <p>
          Le Client est informé qu’il bénéficie d’un droit de rétractation d’un
          délai de 14 (quatorze) jours à compter de son inscription sur le Site.
          Il peut exercer ce droit en adressant à NEERO.FR aux coordonnées
          mentionnées au début des présentes, avant l’expiration du délai
          susvisé, le formulaire figurant en Annexe des Conditions Générales ou
          toute autre déclaration, dénuée d’ambiguïté, exprimant sa volonté de
          se rétracter.
        </p>
        <p style={{ fontWeight: "bold" }}>
          Le Client est toutefois expressément informé et accepte que, si les
          Services de réparation de l’appareil sont effectués par NEERO.FR selon
          les modalités prévues à l’article « Description des Services », avant
          l’expiration du délai ci-dessus, NEERO.FR aura alors pleinement
          exécuté les Services avant la fin du délai de rétractation. En
          conséquence, le Client renonce expressément, dans cette hypothèse, à
          son droit de rétractation, qui ne pourra donc pas être exercé,
          conformément à l’article L.221-28 du Code de la consommation.
        </p>

        <h2>11. Convention de preuve</h2>
        <p>Le Client reconnaît et accepte expressément :</p>
        <div className='cgu-p-h3'>
          <p>(i)</p>
          <p>
            que les données recueillies sur le Site et les équipements
            informatiques de NEERO.FR font foi de la réalité des opérations
            intervenues dans le cadre des présentes ;
          </p>
        </div>
        <div className='cgu-p-h3'>
          <p>(ii)</p>
          <p>
            que ces données constituent le principal mode de preuve admis entre
            les Parties, notamment pour le calcul des sommes dues à NEERO.FR.
          </p>
        </div>
        <p>Le Client peut accéder à ces données dans son Espace Personnel.</p>

        <h2>12. Obligations du Client</h2>
        <p>
          Sans préjudice des autres obligations prévues aux présentes, le Client
          s’engage à respecter les obligations qui suivent.
        </p>
        <div className='cgu-p-h3'>
          <h3>12.1</h3>
          <p>
            Le Client s’engage, dans son usage des Services, à respecter les
            lois et règlements en vigueur et à ne pas porter atteinte aux droits
            de tiers ou à l’ordre public.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>12.2</h3>
          <p>
            Le Client reconnaît avoir pris connaissance sur le Site des
            caractéristiques et contraintes, notamment techniques, de l’ensemble
            des Services. Il est seul responsable de son utilisation des
            Services.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>12.3</h3>
          <p>
            Dans le cadre de l’exécution des Services, le Client s’engage à
            fournir à NEERO.FR le code de déverrouillage de l’Appareil, à toute
            fin utile, le code d’activation de la carte SIM de l’Appareil.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>12.4</h3>
          <p>
            Le Client s’engage en outre à procéder à la sauvegarde des données
            de l’Appareil avant la réparation pour pouvoir les récupérer en cas
            de suppression accidentelle de données stockées dans l’Appareil du
            Client lors de la réparation.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>12.5</h3>
          <p>
            Si l’adresse indiquée dans le Récapitulatif de Commande ne fait pas
            partie des départements éligibles mentionnés à l’article 6.3
            « Modalités d’intervention du technicien », le Client s’engage à
            envoyer l’Appareil par courrier recommandé avec accusé de réception
            dans un délai maximum de 4 (quatre) jours à compter du jour de la
            validation du Récapitulatif de Commande à l’adresse indiquée dans le
            mail de confirmation de la commande.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>12.6</h3>
          <p>
            Le Client s’engage à faire un usage strictement personnel des
            Services. Il s’interdit en conséquence de céder, concéder ou
            transférer tout ou partie de ses droits ou obligations au titre des
            présentes à un tiers, de quelque manière que ce soit.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>12.7</h3>
          <p>
            Le Client s’engage à fournir à NEERO.FR toutes les informations
            nécessaires et, plus généralement, à coopérer activement avec
            NEERO.FR en vue de la bonne exécution des présentes.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>12.8</h3>
          <p>
            Le Client est informé et accepte que la mise en œuvre des Services
            nécessite qu’il soit connecté à internet et que l’accès au Site
            dépend directement de cette connexion, dont il est seul responsable.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>12.9</h3>
          <p>
            Le Client reconnait et accepte que l’Appareil en réparation est
            peut-être encore sous garantie constructeur ou vendeur et pourrait
            être réparé gratuitement par ces derniers, alors que la réparation
            effectuée par NEERO.FR est payante. Il reconnaît par ailleurs que
            NEERO.FR n’a aucune obligation d’information à cet égard.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>12.10</h3>
          <p>
            Le Client reconnaît et accepte que l’intervention de NEERO.FR sur
            son Appareil entraînera une rupture de garantie du fabricant et/ou
            du vendeur de l’Appareil.
          </p>
        </div>

        <h2>13. Garantie du Client</h2>
        <p>
          Le Client garantit NEERO.FR contre toutes plaintes, réclamations,
          actions et/ou revendications quelconques que NEERO.FR pourrait subir
          du fait de la violation, par le Client de l’une quelconque de ses
          obligations ou garanties aux termes des Conditions Générales.
        </p>
        <p>
          Le Client garantit à NEERO.FR qu’il est propriétaire de l’Appareil. A
          cette fin, il garantit qu’il dispose de tous les droits nécessaires
          pour confier l’Appareil à NEERO.FR aux fins de réalisation des
          Services.
        </p>
        <p>
          Il s’engage à indemniser NEERO.FR de tout préjudice qu’elle subirait
          et à lui payer tous les frais, charges et/ou condamnations qu’elle
          pourrait avoir à supporter de ce fait.
        </p>

        <h2>14. Comportements prohibés</h2>
        <div className='cgu-p-h3'>
          <h3>14.1</h3>
          <div>
            <p>
              Il est strictement interdit d’utiliser les Services aux fins
              suivantes :
            </p>
            <h6>
              <span>-</span>l’exercice d’activités illégales, frauduleuses ou
              portant atteinte aux droits ou à la sécurité des tiers,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>l’atteinte à l’ordre public ou la violation des lois
              et règlements en vigueur,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>l’intrusion dans le système informatique d’un tiers
              ou toute activité de nature à nuire, contrôler, interférer, ou
              intercepter tout ou partie du système informatique d’un tiers, en
              violer l’intégrité ou la sécurité,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>l’envoi d’emails non sollicités et/ou de prospection
              ou sollicitation commerciale,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>les manipulations destinées à améliorer le
              référencement d’un site tiers,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>l’aide ou l’incitation, sous quelque forme et de
              quelque manière que ce soit, à un ou plusieurs des actes et
              activités décrits ci-dessus,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>et plus généralement toute pratique détournant les
              Services à des fins autres que celles pour lesquelles ils ont été
              conçus.
            </h6>
          </div>
        </div>
        <div className='cgu-p-h3'>
          <h3>14.2</h3>
          <p>
            Il est strictement interdit aux Clients de copier et/ou de détourner
            à leurs fins ou à celles de tiers le concept, les technologies, tout
            ou partie des données ou tout autre élément du Site de NEERO.FR.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>14.3</h3>
          <p>
            Sont également strictement interdits : (i) tous comportements de
            nature à interrompre, suspendre, ralentir ou empêcher la continuité
            des Services, (ii) toutes intrusions ou tentatives d’intrusions dans
            les systèmes de NEERO.FR, (iii) tous détournements des ressources
            système du Site, (iv) toutes actions de nature à imposer une charge
            disproportionnée sur les infrastructures de ce dernier, (v) toutes
            atteintes aux mesures de sécurité et d’authentification, (vi) tous
            actes de nature à porter atteinte aux droits et intérêts financiers,
            commerciaux ou moraux de NEERO.FR ou des usagers de son Site, et
            enfin plus généralement (vii) tout manquement aux Conditions
            Générales.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>14.4</h3>
          <p>
            Il est strictement interdit de monnayer, vendre ou concéder tout ou
            partie de l’accès aux Services ou au Site, ainsi qu’aux informations
            qui y sont hébergées et/ou partagées.
          </p>
        </div>

        <h2>15. Sanctions des manquements</h2>
        <p>
          En cas de manquement à l’une quelconque des dispositions des
          Conditions Générales ou plus généralement, d’infraction aux lois et
          règlements en vigueur par un Client, NEERO.FR se réserve le droit de
          prendre toute mesure appropriée et notamment de :
        </p>
        <div className='cgu-p-h3'>
          <p>(i)</p>
          <p>
            suspendre ou résoudre / supprimer ou empêcher l’accès aux Services
            du Client, auteur du manquement ou de l’infraction, ou y ayant
            participé,
          </p>
        </div>
        <div className='cgu-p-h3'>
          <p>(ii)</p>
          <p>
            supprimer tout Contenu mis en ligne sur le Site en lien avec le
            manquement ou l’infraction considéré(e), en totalité ou en partie,
          </p>
        </div>
        <div className='cgu-p-h3'>
          <p>(iii)</p>
          <p>
            publier sur le Site tout message d’information que NEERO.FR jugera
            utile,
          </p>
        </div>
        <div className='cgu-p-h3'>
          <p>(iv)</p>
          <p>
            avertir toute autorité compétente, coopérer avec elles et leur
            fournir toutes les informations utiles à la recherche et à la
            répression d’activités illégales ou illicites,
          </p>
        </div>
        <div className='cgu-p-h3'>
          <p>(v)</p>
          <p>engager toute action judiciaire.</p>
        </div>
        <p>
          Le Client est informé et accepte que l’obligation de paiement du prix
          ainsi que les obligations prévues aux articles « Obligations du Client
          » constituent des obligations essentielles pour NEERO.FR. Dès lors,
          tout manquement du Client à ces obligations pourra entraîner, outre
          les conséquences prévues ci-dessus, la résolution immédiate des
          présentes par NEERO.FR, par tout moyen écrit.
        </p>
        <p>
          Cette résolution entraîne la suppression du Compte du Client, sans
          préjudice des autres conséquences éventuellement induites en
          application des présentes Conditions Générales.
        </p>

        <h2>16. Responsabilité et garantie de NEERO.FR</h2>
        <div className='cgu-p-h3'>
          <h3>16.1</h3>
          <p>
            NEERO.FR s’engage à fournir les Services avec diligence et selon les
            règles de l’art, étant précisé qu’il pèse sur elle une obligation de
            moyens, à l’exclusion de toute obligation de résultat, ce que les
            Clients reconnaissent et acceptent expressément.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>16.2</h3>
          <p>
            NEERO.FR s&#39;engage à effectuer la visite à la date et au créneau
            réservé par le Client En cas d’indisponibilité du technicien un
            nouveau créneau sera proposé au Client.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>16.3</h3>
          <p>
            NEERO.FR ne pourra notamment être responsable de la dégénérescence
            des composants de l’Appareil. Dans ce cas, l’Appareil sera restitué
            en l’état au Client.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>16.4</h3>
          <p>
            NEERO.FR garantit que les pièces de remplacement sont des pièces
            d’origine ou compatibles, non contrefaites.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>16.5</h3>
          <p>
            NEERO.FR ne saurait être tenue responsable des éventuelles pertes de
            données contenues sur l’Appareil dans le cadre de l’exécution des
            Services.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>16.6</h3>
          <div>
            <p>
              Les Services réalisés par NEERO.FR font l’objet d’une garantie
              contractuelle de 6 (six) mois et de 2 (deux) mois pour le
              remplacement de batterie.
            </p>
            <p>
              Il est expressément convenu que la garantie contractuelle est
              notamment exclue :
            </p>
            <h6>
              <span>-</span>pour toute panne distincte de celle ayant fait
              l’objet de la réparation,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>pour toute casse de l’Appareil y compris sur-casse
              d’une réparation effectuée.
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>pour tout oxydation de l’Appareil intervenant avant
              et/ou après la réparation,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>pour tout dégât causé par l’utilisation
              d’accessoires non adaptés,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>pour tout dégât causé par le feu, l’humidité, la
              foudre ou cause externe,
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>pour tout dysfonctionnement des logiciels intégrés
              dans l’Appareil, ainsi que les applications non certifiées par les
              constructeurs (notamment les roots et jailbreak),
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>pour tout dysfonctionnements liés à l’intervention
              non autorisée du Client ou d’un tiers ou détérioration volontaire.
            </h6>
          </div>
        </div>
        <div className='cgu-p-h3'>
          <h3>16.7</h3>
          <div>
            <p>
              Le Client pourra faire application de la garantie contractuelle
              en :
            </p>
            <h6>
              <span>-</span>Effectuant une déclaration depuis son Espace
              personnel ;
            </h6>
            <h6 style={{ marginTop: -25 }}>
              <span>-</span>Ou En prenant contact avec NEERO.FR aux coordonnées
              suivantes :
            </h6>
            <h6 style={{ marginTop: -25, marginLeft: 60, fontWeight: "bold" }}>
              Téléphone : <b href={telFull}>{contacts.tel}</b>
            </h6>
            <h6 style={{ marginTop: -25, marginLeft: 60, fontWeight: "bold" }}>
              Ou email :{" "}
              <b>
                {contacts.email}
              </b>
            </h6>
            <p style={{ marginTop: -20 }}>
              Il devra dans ce cas réaliser une vidéo justifiant du
              dysfonctionnement. NEERO.FR pourra réclamer tout complément
              d’informations à cet égard.
            </p>
            <p>
              L’Appareil devra être envoyée par le Client à l’adresse indiquée
              par NEERO.FR par tout moyen.
            </p>
            <p>
              Dans le cas où le dysfonctionnement ne serait pas pris en charge
              par la garantie contractuelle susvisée, NEERO.FR établiera un
              devis pour procéder à la réparation de l’Appareil.
            </p>
          </div>
        </div>
        <div className='cgu-p-h3'>
          <h3>16.8</h3>
          <p>
            NEERO.FR s’engage à procéder régulièrement à des contrôles afin de
            vérifier le fonctionnement et l’accessibilité du Site. A ce titre,
            NEERO.FR se réserve la faculté d’interrompre momentanément l’accès
            au Site pour des raisons de maintenance. De même, NEERO.FR ne
            saurait être tenue responsable des difficultés ou impossibilités
            momentanées d’accès au Site qui auraient pour origine des
            circonstances qui lui sont extérieures, la force majeure, ou encore
            qui seraient dues à des perturbations des réseaux de
            télécommunication.
          </p>
        </div>
        <div className='cgu-p-h3'>
          <h3>16.9</h3>
          <p>
            En tout état de cause, la responsabilité susceptible d’être encourue
            par NEERO.FR au titre des présentes est expressément limitée aux
            seuls dommages directs avérés subis par le Client.
          </p>
        </div>

        <h2>17. Propriété intellectuelle</h2>
        <h3>
          17.1 <span />
          Propriété intellectuelle de NEERO.FR
        </h3>
        <p>
          Les systèmes, logiciels, structures, infrastructures, bases de données
          et contenus de toute nature (textes, images, visuels, musiques, logos,
          marques, base de données, etc.) exploités par NEERO.FR au sein du Site
          sont protégés par tous droits de propriété intellectuelle ou droits
          des producteurs de bases de données en vigueur.
        </p>
        <p>
          Tous désassemblages, décompilations, décryptages, extractions,
          réutilisations, copies et plus généralement, tous actes de
          reproduction, représentation, diffusion et utilisation de l’un
          quelconque de ces éléments, en tout ou partie, sans l’autorisation de
          NEERO.FR sont strictement interdits et pourront faire l’objet de
          poursuites judiciaires.
        </p>

        <h2>18. Autorisation de diffusion des témoignages</h2>
        <p>
          Pendant la durée de leur utilisation des Services, les Clients
          autorisent NEERO.FR à utiliser les témoignages qu’ils publient sur le
          Site (les « Témoignages ») pour la promotion de celui-ci, selon les
          modalités suivantes :
        </p>
        <h6>
          <span>-</span>Ils consentent à ce que leurs Témoignages soient
          diffusés à titre gracieux par NEERO.FR sur le Site et sur tous autres
          sites internet français ou étrangers, édités par toutes sociétés avec
          lesquelles la Société a des accords,
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>Ils consentent à ce que leurs Témoignages soient
          diffusés par NEERO.FR par tout moyen et sur tout support aux fins de
          promotion du Site,
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>Ils acceptent que leurs Témoignages soient traduits en
          toutes langues,
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>Ils reconnaissent et acceptent que les Témoignages
          pourront faire l’objet de modifications, notamment quant à leur
          cadrage, leur format et leurs couleurs, ainsi que d’altérations ou de
          dégradations dans leur qualité, en fonction des contraintes techniques
          du Site,
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>Ils renoncent à demander à NEERO.FR une quelconque
          rémunération, redevance, indemnité ou compensation financière à ce
          titre.
        </h6>

        <h2>19. Assistance technique</h2>
        <p>
          NEERO.FR propose au Client une assistance technique accessible par
          email à l’adresse suivante :{" "}
          <u onClick={mailto}>
            {contacts.email}
          </u>{" "}
          lui permettant de déclarer toute difficulté rencontrée lors de
          l’utilisation du Site.
        </p>

        <h2>20. Données à caractère personnel</h2>
        <p>
          NEERO.FR pratique une politique de protection des données personnelles
          dont les caractéristiques sont explicitées dans le document intitulé
          « 
          <span style={{ marginLeft: 0, textDecoration: "underLine" }}>
            Charte relative à la protection des données à caractère personnel
          </span>
           », dont le Client est expressément invité à prendre connaissance.
        </p>

        <h2>21. Force majeure</h2>
        <p>
          En aucun cas, les parties ne pourront être tenues pour responsables
          des manquements ou retards dans l’exécution des Conditions Générales
          dus à l’un des cas de force majeure telle que défini par l’article
          1218 du Code civil et reconnus par la jurisprudence, en ce compris
          notamment les grèves, activités terroristes, émeutes, insurrections,
          guerres, actions gouvernementales, épidémies, catastrophes naturelles
          ou défaut imputable à un prestataire tiers de télécommunication.
        </p>
        <p>
          La survenance d’un cas de force majeure suspend l’exécution des
          Conditions Générales dès réception par l’une des parties de
          l’information transmise par lettre recommandée avec demande d’avis de
          réception par l’autre partie.
        </p>
        <p>
          Si un cas de force majeure faisant obstacle à l’exécution de ses
          obligations par une partie se poursuit au-delà d’une durée de 30
          (trente) jours, les Conditions Générales pourront être résiliées le
          cas échéant immédiatement et de plein droit, sans formalités
          judiciaires, par l’une ou l’autre des parties, par tout moyen écrit,
          sans qu’aucune des parties n’ait à verser à l’autre une quelconque
          indemnité.
        </p>

        <h2>22. Modifications</h2>
        <p>
          NEERO.FR se réserve la faculté de modifier à tout moment les
          Conditions Générales.
        </p>
        <p>
          Le Client inscrit sera informé de ces modifications par tout moyen
          utile 14 (quatorze) jours au moins avant leur entrée en vigueur.
        </p>
        <p>
          Le Client qui n’accepte pas les conditions générales modifiées doit se
          désinscrire des Services selon les modalités prévues à l’article
          « Durée des Services, désinscription ».
        </p>

        <h2>23. Médiation</h2>
        <p>
          Le Client dispose du droit de recourir gratuitement à un médiateur de
          la consommation en vue de la résolution amiable de tout litige portant
          sur l’exécution des présentes qui l&#39;opposerait à NEERO.FR, dans
          les conditions prévues aux articles L611-1 et suivants et R612-1 et
          suivants du Code de la consommation.
        </p>
        <p>
          Il peut contacter à cette fin le médiateur de la consommation
          suivant :
        </p>
        <p style={{ textAlign: "center", marginTop: 45 }}>
          Centre de médiation de la consommation de conciliateurs de justice{" "}
          <b>(CM2C)</b>
          <br />
          Adresse postale : 14 rue Saint Jean 75017 Paris
          <br />
          Téléphone : <b href={mediaTelFull}>{contacts.mediaTel}</b>
          <br />
          <u
            onClick={e => {
              e.preventDefault();
              window.open(contacts.webSite, "_blank");
            }}
          >
            {contacts.webSite}
          </u>
          <br />
        </p>

        <h2>24. Loi applicable</h2>
        <p>
          Les présentes Conditions Générales sont régies par la loi française.
        </p>
      </div>
    </div>
  );
}
