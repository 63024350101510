import { settingsConstants } from '../constants';
import { settingsService } from '../services';

export const settingsActions = {
    getSettings,
    checkAddress
};

function getSettings() {
    return dispatch => {
        dispatch(request());
        settingsService.getSettings()
            .then(
                response => { 
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure());
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: settingsConstants.SETTINGS_REQUEST } }
    function success(settings) { return { type: settingsConstants.SETTINGS_SUCCESS, settings } }
    function failure() { return { type: settingsConstants.SETTINGS_FAILURE } }
}

function checkAddress(address) {
    return new Promise((resolve, reject) => 
        settingsService.checkAddress(address)
            .then((response) => {
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        )
};
