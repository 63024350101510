import "./index.css";
import { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/Button";
import { FAQ_QR } from "../../config/staticData";
//import ReactPixel from 'react-facebook-pixel';

export function FaqPage() {
  const [recherche, setRecherche] = useState("");
  const [rechercheInput, setRechercheInput] = useState("");

  const handleChange = () => event => {
    setRechercheInput(event.target.value);
  };

  useEffect(() => {
    //ReactPixel.pageView()
  }, []);

  return (
    <div className='faq-container'>
      <div className='faq'>
        <h1>Foire aux questions</h1>
        <div className='faq-header'>
          <TextField
            className='searchBar'
            label='Rechercher'
            variant='outlined'
            type='text'
            onChange={handleChange()}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={() => setRecherche(rechercheInput)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            className='btn-gradient'
            onClick={() => setRecherche(rechercheInput)}
          >
            <u>Recherhcer</u>
          </Button>
        </div>
        <div className='qr-container'>
          {FAQ_QR.map(qr =>
            qr.question.includes(recherche) ||
            qr.reponse.includes(recherche) ? (
              <div>
                <h2>💡 {qr.question}</h2>
                <p>{qr.reponse}</p>
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
}
