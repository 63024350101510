import React, { useEffect } from "react";
import "./index.css";
import FooterV2 from "../../components/FooterV2";
//import ReactPixel from 'react-facebook-pixel';
import Button from "react-bootstrap/Button";
import RightArrowSvg from "../../assets/svgFiles/rightArrow";
import { useHistory, useLocation } from "react-router-dom";
import GoogleMapsRating from "../../components/GoogleMapsRating";
import PeImage from "../../assets/images/pe.png";
import RDeImage from "../../assets/images/Image_Back.png";
import RDeImageMobile from "../../assets/images/Entreprise_image.png";
import { PEngagementsItems } from "./StaticData";
import { PRODUCT_ROUTES } from "../../config/api";
import ProductReparations from "./ProductReparations";
import ProductReviews from "./ProductReviews";

export function PorductsPage() {
  const history = useHistory();
  const location = useLocation();
  let ProductRepair = PRODUCT_ROUTES.children.find(
    obj => obj.path === location.pathname.split("-")[1]
  );

  useEffect(() => {
    // ReactPixel.pageView()
  }, []);

  const goToBookingPage = () => history.push("/demande");
  const goToContactPage = () => history.push("/contact");
  //const goToProductPage = (i) => history.push(`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[i].path}`)

  return (
    <div className='product-container'>
      <div className='product-header-container'>
        <div className='product-header-mobile'>
          <Button className='btn-gradient-2' onClick={goToBookingPage}>
            <u>{"réserver ma réparation".toUpperCase()}</u>
            <RightArrowSvg />
          </Button>
          <div className='product-google-rating'>
            <GoogleMapsRating />
          </div>
        </div>
        <div className='product-header-image'>
          <div style={{ backgroundImage: `url(${ProductRepair.image})` }} />
        </div>
        <div className='product-header'>
          {ProductRepair.text}
          <h3>à domicile ou au travail</h3>
          <div className='product-header-desktop'>
            <Button className='btn-gradient-2' onClick={goToBookingPage}>
              <u>{"réserver ma réparation".toUpperCase()}</u>
              <RightArrowSvg />
            </Button>
            <div className='product-google-rating'>
              <GoogleMapsRating />
            </div>
          </div>
        </div>
      </div>

      <div className='product-engagement-container'>
        <h1>Nos engagements </h1>
        <div className='product-engagement-container2'>
          <div className='product-engagement'>
            <div className='product-engagement-block'>
              {PEngagementsItems.map((pe, index) => (
                <div className='pe-item' key={index}>
                  {pe.icon}
                  <p>{pe.title}</p>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${PeImage})` }}
            className='pe-image'
          />
        </div>
      </div>

      <div className='product-repair-container'>
        <div
          style={{ backgroundImage: `url(${RDeImage})` }}
          className='product-repair-image'
        />

        <div className='product-repair-block'>
          <h1>
            Réparation à domicile en <span>atelier mobile</span>
          </h1>
          <div
            style={{ backgroundImage: `url(${RDeImageMobile})` }}
            className='product-repair-image-mobile'
          />
          <p>
            Nos techniciens interviennent avec un atelier mobile entièrement
            équipé à l'adresse de votre choix, pour réparer vos appareils en 30
            minutes.
          </p>
          <p>Prise de rendez-vous en 2mn en ligne.</p>
          <Button
            className='btn-gradient-2'
            style={{ marginTop: 50 }}
            onClick={goToBookingPage}
          >
            <u>{"faire une demande de réparation".toUpperCase()}</u>
            <RightArrowSvg />
          </Button>
        </div>
      </div>

      <ProductReviews />

      <div className='product-contact-container'>
        <div className='product-contact-bock'>
          <h1>Besoin de discuter avec un technicien ?</h1>
          <p>
            Contactez notre équipe pour un diagnostic gratuit et immédiat 📱
          </p>
          <Button className='btn-gradient-2' onClick={goToContactPage}>
            <u>{"Nous contacter".toUpperCase()}</u>
            <RightArrowSvg />
          </Button>
        </div>
      </div>

      <ProductReparations />

      <FooterV2 />
    </div>
  );
}
