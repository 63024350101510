import React from "react";
import "./index.css";
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import ButtonBStrap from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Spinner } from 'evergreen-ui'
import { connect } from 'react-redux';
import { userActions } from '../../../actions';
import { SmallScreenContext } from "../../../helpers";
import { RegisterPage } from "../Register";
import { regex } from "../../../config";
 
const reg = regex.email

const useStyles = makeStyles((theme) => ({
    margin: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    }
  }));
  
function LoginPage(props) {

    const classes = useStyles();
    const [values, setValues] = React.useState({
        email: '',
        password: '',
        showPassword: false,
        emailErrorMsg: '',
        passwordErrorMsg: '',
        emailValide: false,
        passwordValide: false,
        loading: false
    });
    const [emailCheck, setEmailCheck] = React.useState({
        isInEmailcheckForm : true /*!localStorage.getItem('mailSend')*/,
        email: '',
        emailErrorMsg: '',
        emailValide: false,
        loading: false
    })
    const [isRegistering, setIsRegistering] = React.useState(false)
    //const [isResetPassword, setIsResetPassword] = React.useState(false)
    
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === 'email') emailValidate(event.target.value)
        else passwordValidate(event.target.value)
    };
    
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const emailValidate = (text) => {
        if (reg.test(text) === false) {
            setValues({ ...values, email:'', emailErrorMsg: 'Adresse e-mail invalide', emailValide: false });   
        }
        else {
            setValues({ ...values, email:text, emailErrorMsg: '', emailValide: true }); 
        }
    } 

    const passwordValidate = (text) => {
        if (text.length < 8) {
            setValues({ ...values, password:'', passwordErrorMsg: 'Saisir au moins 8 caractères',  passwordValide: false });  
        }
        else{
            setValues({ ...values, password: text, passwordErrorMsg: '', passwordValide: true }); 
        }
    }

    React.useEffect(() => {
        setValues({ 
            ...values,
            loading: false,
         });
         setEmailCheck({ 
            ...emailCheck,
            loading: false,
         });
         //eslint-disable-next-line
    }, [props.openDialog])

    const login = (e) => {
        e.preventDefault();
        if (values.emailValide && values.passwordValide){
            props.login(values.email, values.password, props.demand, props.resume)
            setValues({ 
                ...values,
                loading: true,
                showPassword: false,
                emailErrorMsg: '',
                passwordErrorMsg: ''
            });
            window.scrollTo({top:0, left:0,behavior: 'smooth'})
            document.querySelector('body').scrollTo({top:0, left:0,behavior: 'smooth'});
        }
        else{
            if (!values.email) 
                if (!values.password) setValues({ ...values, emailErrorMsg: 'Adresse e-mail invalide', passwordErrorMsg: 'Saisir au moins 8 caractères' }); 
                else setValues({ ...values, emailErrorMsg: 'Adresse e-mail invalide' }); 
            else if (!values.password) setValues({ ...values, passwordErrorMsg: 'Saisir au moins 8 caractères' }); 
        }
       // setValues({ ...values, emailErrorMsg:'',emailValide:false, passwordErrorMsg:'', passwordValide:false }); 
    }

    const history = useHistory()

    /*const handleEmailChange = (event) => {
        let email = event.target.value
        if (reg.test(email) === false) {
            setEmailCheck({ ...emailCheck, email:'', emailErrorMsg: 'Adresse e-mail invalide', emailValide: false });   
        }
        else {
            setEmailCheck({ ...emailCheck,  email:email, emailErrorMsg: '', emailValide: true }); 
        }
    };

    const emailChecking = (e) => {
        e.preventDefault()
        if (emailCheck.emailValide){
            props.forgottenPassword(emailCheck.email)
            setEmailCheck({ 
                ...emailCheck,
                loading: true,
                emailErrorMsg: '',
             });
        }
        else{
            setValues({ ...values, emailErrorMsg: 'Adresse e-mail invalide' });
        }
        window.scrollTo({top:0, left:0,behavior: 'smooth'})
        document.querySelector('body').scrollTo({top:0, left:0,behavior: 'smooth'});
    }

    const emailCheckForm = () => {
        return(
            <div className='from-login'>
                <h2 style={{marginTop: 21}}>Entrez votre adresse email</h2> 
                <FormControl>
                    <TextField
                        id="outlined-error-helper-text"
                        label="E-mail"
                        onKeyPress={(e) => { e.key === 'Enter' && emailChecking(e) }}
                        //error
                        //helperText="Incorrect entry."
                        type="email"
                        onChange={handleEmailChange}
                        variant="outlined"
                        color={emailCheck.emailValide ? "primary" : "secondary"}
                    />
                    <em>{emailCheck.emailErrorMsg}</em>
                </FormControl>
                <div style={{paddingTop: 10}} />
                <ButtonBStrap className="btn-gradient" onClick={emailChecking} style={{marginTop:10}}>
                    <u>Réinitialiser</u>
                </ButtonBStrap>
                { emailCheck.loading? <Spinner style={{alignSelf: "center", paddingTop: 20}}/> :null }
                <div className="add-some-padding-top"/>
            </div>
        )
    }
*/
    return (
        <SmallScreenContext.Consumer>
            { (context) => (
                <div>
                    { isRegistering ? <RegisterPage openDialog={props.openDialog}/> :
                    <form className='from-login' onSubmit={login}>
                        <div style={{display: "flex", flexDirection: "row", marginTop: 15}}>   
                            <p>Je n’ai pas encore de compte.</p>
                            <Button className="btn-register" appearance="minimal" onClick={() => {
                                setIsRegistering(true)
                                window.scrollTo({top:0, left:0,behavior: 'smooth'})
                                document.querySelector('body').scrollTo({top:0, left:0,behavior: 'smooth'});
                            }}>
                                <a>Inscription</a>{/* eslint-disable-line */}
                            </Button>
                        </div>
                        {
                            //isResetPassword ? emailCheckForm()
                            //:
                            <div className='from-login'>
                                <h2>Connexion</h2>
                                <FormControl >
                                    <TextField
                                        id="outlined-error-helper-text"
                                        label="E-mail"
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        //error
                                        //helperText="Incorrect entry."
                                        type="email"
                                        onChange={handleChange('email')}
                                        variant="outlined"
                                        color={values.emailValide ? "primary" : "secondary"}
                                    />
                                    <em>{values.emailErrorMsg}</em>
                                </FormControl>
                                <FormControl className={clsx(classes.margin)} variant="outlined">
                                    <InputLabel  color={values.passwordValide ? "primary" : "secondary"}  htmlFor="outlined-adornment-password">
                                        Mot de passe
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        onChange={handleChange('password')}
                                        onKeyPress={(e) => { e.key === 'Enter' && login(e) }}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        labelWidth={100}
                                        color={values.passwordValide ? "primary" : "secondary"}
                                    />
                                    <em>{values.passwordErrorMsg}</em>
                                <Button className="btn-register" style={{alignSelf: 'flex-start', marginTop: 10}} appearance="minimal" onClick={() => {
                                    history.push('login/resetpassword')
                                    /*setIsResetPassword(true)
                                    window.scrollTo({top:0, left:0,behavior: 'smooth'})
                                    document.querySelector('body').scrollTo({top:0, left:0,behavior: 'smooth'});*/
                                }}>
                                    <a>Mot de passe oublié ?</a> {/* eslint-disable-line */}
                                </Button>
                                </FormControl>
                                { values.loading ? <Spinner style={{alignSelf: "center", marginTop: -40}}/> : null }
                                <div style={{flexDirection: 'row', flexWrap: 'nowrap', marginTop: -20}}>
                                    <ButtonBStrap className="btn-gradient" onClick={login}>
                                        <u>Se connecter</u>
                                    </ButtonBStrap>
                                </div>
                            </div>
                            }
                    </form>
                }
                </div>
            )}
        </SmallScreenContext.Consumer>
    );
}
 


function mapState(state) {
    const { demand, resume, alert } = state;
    const { loggingIn } = state.authentication;
    return { loggingIn, alert, demand, resume };
}

const actionCreators = {
    login: userActions.login,
    forgottenPassword: userActions.forgottenPassword
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };