import { manufacturersConstants } from '../constants';
import { manufacturersService } from '../services';
import { alertActions } from '.';

export const manufacturersActions = {
    getManufacturers,
    getModels,
    initModels,
    getModelTypes,
    initModelTypes,
    getManufacturersWithModels,
    initManufacturers
};

function getManufacturersWithModels() {
    return dispatch => {
        dispatch(request());
        manufacturersService.getManufacturers()
            .then(
                manufacturers => { 
                    var manufacturersWithModels = manufacturers.data
                    if(manufacturersWithModels){
                        const fetches = [];
                        for(let i = 0; i < manufacturersWithModels.length; i++){
                            fetches.push(
                                manufacturersService.getModels(manufacturersWithModels[i].uuid)
                                .then(
                                    models => { 
                                        manufacturersWithModels[i].models = models.data
                                    },
                                    error => {
                                        dispatch(failure());
                                        dispatch(alertActions.error(error.toString()));
                                    }
                                )
                            )
                        }
                        Promise.all(fetches).then(function() {
                            dispatch(success(manufacturersWithModels));
                        });
                    }
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: manufacturersConstants.SET_MANUFACTURES_WITH_MODELS_LOADING } }
    function success(manufacturersWithModels) { return { type: manufacturersConstants.SET_MANUFACTURES_WITH_MODELS, manufacturersWithModels } }
    function failure() { return { type: manufacturersConstants.SET_MANUFACTURES_WITH_MODELS_FAILED } }
}

function getModelTypes() {
    const typesToHide = ["3","5","7"]
    return dispatch => {
        dispatch(request());
        manufacturersService.getModelTypes()
            .then(
                modelTypes => { 
                    dispatch(success(modelTypes.data.filter((type) => !typesToHide.includes(type.id))))
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: manufacturersConstants.SET_MODEL_TYPES_LOADING } }
    function success(modelTypes) { return { type: manufacturersConstants.SET_MODEL_TYPES, modelTypes } }
    function failure() { return { type: manufacturersConstants.SET_MODEL_TYPES_FAILED } }
}

function  initModelTypes() { return {type:manufacturersConstants.INIT_SET_MODEL_TYPES}}

function getManufacturers() {
    return dispatch => {
        dispatch(request());
        manufacturersService.getManufacturers()
            .then(
                manufacturers => { 
                    dispatch(success(manufacturers.data));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: manufacturersConstants.SET_LOADING } }
    function success(manufacturers) { return { type: manufacturersConstants.SET_MANUFACTURES, manufacturers } }
    function failure() { return { type: manufacturersConstants.SET_MANUFACTURES_FAILED } }
}

function getModels(id) {
    return dispatch => {
        dispatch(request());
        manufacturersService.getModels(id)
            .then(
                models => { 
                    dispatch(success(models.data));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: manufacturersConstants.SET_MODELS_LOADING } }
    function success(models) { return { type: manufacturersConstants.SET_MODELS, models } }
    function failure() { return { type: manufacturersConstants.SET_MODELS_FAILED } }
}

function  initModels() { return {type:manufacturersConstants.INIT_MODELS}}

function  initManufacturers() { return {type:manufacturersConstants.INIT_MANUFACTURES}}
