import React, { useState, useEffect } from 'react';
import ButtonBStrap from 'react-bootstrap/Button';
import './index.css';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { SmallScreenContext } from '../../helpers';
import { ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon } from 'evergreen-ui';
import {
  DemandeFormP1,
  DemandeFormP2,
  DemandeFormP3,
  DemandeFormP4,
} from './Forms';
import { ResumeSlide } from './index';
import {
  demandActions,
  alertActions,
  resumeActions,
  interventionsActions,
  manufacturersActions,
} from '../../actions';
import { connect } from 'react-redux';
//import ReactPixel from 'react-facebook-pixel';
import TunnelCmdItem from '../../components/TunnelCmdItem';
import ChangingProgressProvider from '../../components/ChangingProgressProvider';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { TunnelCmdSteps } from '../../config/staticData';

function DemandePage(props) {
  const [resume, setResume] = useState(false);
  const [isPageReloaded, setPageReloaded] = useState(false);
  const [isWebview, setIswebview] = useState(false);
  const [brandId, setBrandId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('user'));

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem('user'));
  }, [props.authentication]);

  useEffect(() => {
    setPageReloaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.performance]);

  const history = useHistory();
  const location = useLocation();

  const hideNavBarMenu = (context) => {
    context.hideMenu();
    setPageReloaded(false);
  };

  const returnToHome = (context) => {
    context.showMenu();
    history.push('/');
  };

  const previousPage = (context) => {
    if (props.page === 1) returnToHome(context);
    else props.previousPage();
  };
  useEffect(() => {
    if (!location.state?.saveData) {
      props.initDemand();
      props.initResume();
      props.initManufacturers();
    }

    window.addEventListener('message', (event) => {
      try {
        let obj = JSON.parse(event.data);
        props.getInterventions(obj.model.uuid);
        props.setAddressDemand(obj.address);
        props.setAddressResume(obj.address.address1 + obj.address.address2);
        props.setModelDemand(obj.model);
        props.setModelResume(
          obj.model.manufacturer.name + ' - ' + obj.model.name
        );
        props.getModels(obj.model.manufacturer.uuid);
        if (obj.token)
          localStorage.setItem(
            'user',
            JSON.stringify({
              token: obj.token,
              refresh_token: obj.refresh_token,
            })
          );
        setIswebview(true);
        setBrandId(obj.model.manufacturer.uuid);
        props.setPage(2);
      } catch {}
    });
    // ReactPixel.pageView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DemandeForm = (context) => {
    switch (props.page) {
      case 1:
        return (
          <DemandeFormP1
            isSmallScreen={context.isSmallScreen}
            showMenu={context.showMenu}
            isWebview={isWebview}
            brandId={brandId}
            openDialog={true}
          />
        );
      case 2:
        return <DemandeFormP2 isSmallScreen={context.isSmallScreen} />;
      case 3:
        return <DemandeFormP3 isSmallScreen={context.isSmallScreen} />;
      case 4:
        return (
          <DemandeFormP4
            isSmallScreen={context.isSmallScreen}
            context={context}
          />
        );
      default:
        return (
          <DemandeFormP1
            isSmallScreen={context.isSmallScreen}
            showMenu={context.showMenu}
          />
        );
    }
  };

  useEffect(() => {
    props.clearAlerts();
    setResume(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <SmallScreenContext.Consumer>
      {(context) => (
        <div className="demand-container">
          <div className="demand-navbar">
            <ButtonBStrap
              className="btn-return"
              onClick={() => previousPage(context)}
            >
              <div>
                <ChevronLeftIcon size={17} className="icon-return" />
                <u> Retour</u>
              </div>
            </ButtonBStrap>
            {/* eslint-disable-next-line */}
            <a onClick={() => returnToHome(context)}>
                <div className="headerLogo2" />
            </a>
          </div>
          {context.isSmallScreen ? (
            <div className="small-demand-core">
              <div className="small-tunnel-cmd">
                <div style={{ width: 45, height: 45 }}>
                  <ChangingProgressProvider
                    values={[
                      0,
                      props.page === 4 && isLoggedIn ? 5 : props.page,
                    ]}
                  >
                    {(percentage) => (
                      <CircularProgressbar
                        value={percentage}
                        maxValue={5}
                        strokeWidth={12}
                        background={true}
                        text={percentage + '/' + TunnelCmdSteps.length}
                        styles={buildStyles({
                          strokeLinecap: 'butt',
                          textSize: '35px',
                          pathTransitionDuration: 0.5,
                          // pathTransition: 'none',
                          pathColor: '#722CC8',
                          textColor: '#722CC8',
                          trailColor: 'white',
                          backgroundColor: '#EEEBF3',
                        })}
                      />
                    )}
                  </ChangingProgressProvider>
                </div>
                {props.page === 4 && isLoggedIn ? (
                  <p>
                    <span>{TunnelCmdSteps[props.page].icon}</span>
                    {TunnelCmdSteps[props.page].text}
                  </p>
                ) : (
                  <p>
                    <span>{TunnelCmdSteps[props.page - 1].icon}</span>
                    {TunnelCmdSteps[props.page - 1].text}
                  </p>
                )}
              </div>
              {resume ? null : (
                <div className="recap-btn-container">
                  <button
                    style={{ backgroundColor: 'transparent', border: 'none' }}
                    onClick={() => setResume(true)}
                  >
                    <ChevronDownIcon color="white" size={20} />
                    <p>Voir le récapitulatif</p>
                  </button>
                </div>
              )}
              {resume ? (
                <div className="demand-resume">
                  <ResumeSlide isSmallScreen={context.isSmallScreen} />
                </div>
              ) : (
                <div className="demand-form">{DemandeForm(context)}</div>
              )}
              {resume ? (
                <div
                  className="recap-btn-container"
                  style={{ backgroundColor: 'white' }}
                >
                  <button
                    style={{ backgroundColor: 'transparent', border: 'none' }}
                    onClick={() => setResume(false)}
                  >
                    <ChevronUpIcon color="#722CC8" size={20} />
                    <p style={{ color: '#722CC8' }}>Cacher le récapitulatif</p>
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <div className="tunnel-cmd">
                {TunnelCmdSteps.map((obj, index) =>
                  !index ? (
                    <div key={index}>
                      <TunnelCmdItem
                        text={obj.text}
                        icon={obj.icon}
                        page={props.page}
                        pageId={index + 1}
                        isLoggedIn={isLoggedIn}
                      />
                    </div>
                  ) : (
                    <div style={{ display: 'flex' }} key={index}>
                      <h6>/</h6>
                      <TunnelCmdItem
                        text={obj.text}
                        icon={obj.icon}
                        page={props.page}
                        pageId={index + 1}
                        isLoggedIn={isLoggedIn}
                      />
                    </div>
                  )
                )}
              </div>
              <div className="demand-core">
                <div className="demand-form">{DemandeForm(context)}</div>
                <div className="demand-resume">
                  <ResumeSlide isSmallScreen={context.isSmallScreen} />
                </div>
              </div>
            </div>
          )}
          {isPageReloaded ? hideNavBarMenu(context) : null}
        </div>
      )}
    </SmallScreenContext.Consumer>
  );
}

function mapState(state) {
  const { alert, authentication } = state;
  const { page } = state.demand;
  return { page, alert, authentication };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  setPage: demandActions.setPage,
  previousPage: demandActions.previousPage,
  setAddressDemand: demandActions.setAddress,
  setModelDemand: demandActions.setModel,
  setInterventions: demandActions.setInterventions,
  setAddressResume: resumeActions.setAddress,
  setModelResume: resumeActions.setModel,
  getInterventions: interventionsActions.getInterventions,
  setPrice: resumeActions.setPrice,
  getModels: manufacturersActions.getModels,
  initDemand: demandActions.init,
  initResume: resumeActions.init,
  initManufacturers: manufacturersActions.initManufacturers,
};

const connectedDemandePage = connect(mapState, actionCreators)(DemandePage);
export { connectedDemandePage as DemandePage };
