import { externals } from "../config";

const apiUrl = externals.apiUrl

export const contactService = {
    contact
};

function contact(info) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            "name": info.nom + " " + info.prenom,
            "email": info.email,
            "phone": info.tel,
            "subject": info.subject,
            "message": info.message
        })
    };

    return fetch(`${apiUrl}/api/v1/requester/contact`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}