import React from "react";
import "./GoogleMapsRating.css";
import GoogleIcon from "../assets/icons/google.png";
import StarSvg from "../assets/icons/starRating.jpg";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("defer", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

export default function GoogleMapsRating() {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [rating, setRating] = React.useState(5);
  const loaded = React.useRef(false);

  React.useEffect(() => {
    if (typeof window !== "undefined" && !loaded.current) {
      if (
        !document.querySelector("#google-maps") &&
        !document.querySelector("#script-maps")
      ) {
        loadScript(
          "https://maps.googleapis.com/maps/api/js?key=" +
            process.env.REACT_APP_GMAPS_API_KEY +
            "&libraries=places",
          document.querySelector("head"),
          "script-maps"
        );

        setIsLoaded(true);
      }

      loaded.current = true;
    }
  }, []);

  React.useEffect(() => {
   // setTimeout(function () {
      //if (window.google) {
        // var _request = {
        //   placeId: 'ChIJVVJjHcNt5kcRwD8-Gtb8qtw', // neero.fr: ChIJVVJjHcNt5kcRwD8-Gtb8qtw
        //   fields: ['rating']
        // };
        setRating(4.9);

        // function callback(place, status) {

        //   if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        //       setRating(place.rating)
        //       console.log('::::RRRRR',place.rating);
        //   }
        // }

        // var service = new window.google.maps.places.PlacesService(document.getElementById("placeAttr")); // map is your map object
        // service.getDetails(_request, callback);
     // }
   // }, 1000);
  }, [isLoaded]);

  const fillStars = (nb) => {
    let stars = [];
    for (var i = 0; i < nb; i++) {
      stars.push(i);
    }
    return (
      <div className="google-rating-star-container">
        {stars.map((s, index) => (
          <div key={index} style={{ backgroundImage: `url(${StarSvg})` }} />
        ))}
      </div>
    );
  };

  return (
    <div className="google-rating-container">
      <div id="placeAttr"></div>
      <div
        className="google-rating-icon"
        style={{ backgroundImage: `url(${GoogleIcon})` }}
      />
      {fillStars(rating)}
      <h2>{rating}</h2>
    </div>
  );
}
