import React, { useState, useEffect } from "react";
import GoogleMapsAutocomplete from "../../components/GoogleMapsAutocomplete";
import Button from "react-bootstrap/Button";
import "./index.css";
import { demandActions, resumeActions, settingsActions } from "../../actions";
import { connect } from "react-redux";
import { SmallScreenContext } from "../../helpers";
import { useHistory } from "react-router-dom";
import FooterV2 from "../../components/FooterV2";
import { zipDefault } from "../../config";
//import ReactPixel from 'react-facebook-pixel';

function MissionPage(props) {
  const [isAddressOption, setIsAddressOption] = useState(false);
  const [addressInput, setAddressInput] = useState("");
  const [valideGMAdress, setValideGMAdress] = useState(false);

  const history = useHistory();

  const handleAddressInputChange = input => {
    setAddressInput(input);
    setIsAddressOption(false);
  };

  useEffect(() => {
    //ReactPixel.pageView()
  }, []);

  const handleAddressOptionChange = (autocompleteresult, geocodeResult) => {
    let zipCode = geocodeResult.address_components.filter(function (obj) {
      return obj.types[0] === "postal_code";
    });
    let city = geocodeResult.address_components.filter(function (obj) {
      return obj.types[0] === "locality";
    })[0].long_name; //administrative_area_level_2
    let address = {
      address1: autocompleteresult.structured_formatting.main_text,
      address2: autocompleteresult.structured_formatting.secondary_text,
      city: city,
      zip: zipCode.length > 0 ? zipCode[0].long_name : zipDefault.notFound,
      longitude: geocodeResult.geometry.location.lng(),
      latitude: geocodeResult.geometry.location.lat(),
    };
    // ReactPixel.track('SEARCH', null)
    settingsActions
      .checkAddress(address)
      .then(res => {
        if (res.available) {
          setValideGMAdress(true);
          setIsAddressOption(true);
          props.setAddressResume(autocompleteresult.description);
          props.setAddressDemand(address);
        } else {
          setValideGMAdress(false);
          props.setAddressResume(null);
          props.setAddressDemand(null);
        }
        setIsAddressOption(true);
      })
      .catch(error => {
        //console.log('*****',error)
      });
  };

  const handleSubmit = context => {
    if (!isAddressOption) {
      let address = {
        address1: addressInput,
        address2: "",
        city: addressInput,
        zip: zipDefault.notUsed,
        longitude: 0,
        latitude: 0,
      };
      settingsActions
        .checkAddress(address)
        .then(res => {
          if (res.available) {
            props.setAddressResume(addressInput);
            props.setAddressDemand(address);
          } else {
            setAddressInput("");
            setIsAddressOption("");
            props.setAddressResume(null);
            props.setAddressDemand(null);
            history.push("/address-unavailable");
          }
        })
        .catch(error => {
          //console.log('*****',error)
        });
    } else {
      if (valideGMAdress) {
        context.hideMenu();
        history.push("/demande");
      } else {
        setAddressInput("");
        history.push("/address-unavailable");
      }
    }
  };

  return (
    <SmallScreenContext.Consumer>
      {context => (
        <div className='mission-container'>
          <div className='background-image-mission'>
            <div
              className={context.isSmallScreen ? "small-mission" : "mission"}
            >
              <h1>
                La (super) mission de Neero : vous proposer des solutions de
                réparations rapides, de qualité... sans vous déplacer !
              </h1>
              <p>
                Quel que soit le modèle de votre smartphone ou tablette, nous
                réparons tous les types de pannes : écran de téléphone cassé,
                problème de stockage, batterie qui faiblit, optimisation de
                l’espace... <br />
                On se déplace à l'endroit de votre choix pour y effectuer la
                réparation sur place.
              </p>
              <p>Envie de tester ? C'est par ici 👇</p>
              <div className='gm-mission'>
                <GoogleMapsAutocomplete
                  optionCallback={handleAddressOptionChange}
                  inputCallback={handleAddressInputChange}
                  width={context.isSmallScreen ? "80%" : 500}
                />
              </div>
              <div style={{ paddingTop: 20, paddingBottom: "10%" }}>
                <Button
                  className='btn-gradient'
                  onClick={() => handleSubmit(context)}
                >
                  <u>Voir les disponibilités</u>
                </Button>
              </div>
            </div>
          </div>
          <div className='mission-section-1'>
            <img className='mission-car' alt='' />
            <div>
              <h1>Pourquoi un atelier mobile ?</h1>
              <p>
                Réparer son smartphone c’est souvent une question d’urgence 🚨
              </p>
              <p>
                Une casse arrive toujours quand on a le plus besoin de son
                appareil ! Chez Neero, nous en sommes conscients : c'est
                pourquoi, nous avons lancé un service révolutionnaire qui vous
                facilitera la vie.
              </p>
              <p>
                Fini le temps perdu. Fini le stress, mais surtout l’attente de
                récupérer son téléphone en restant injoignable pendant X temps.
              </p>
              <p>
                Nous avons transformé une flotte de trucks en de véritables
                ateliers mobiles, équipés de tous les outils nécessaires à notre
                métier.
              </p>
              <p>
                Finis les déplacements et les changements de plans de dernière
                minute pour faire réparer vos appareils. A vous de nous dire où
                et quand, on s’occupe du comment📍🗓
              </p>
              <p>
                En plus de cela, nos services sont sur-mesure, nos techniciens
                sont sympas et nous proposons 1 an de garantie sur nos
                réparations. 👏🏻
              </p>
              <p>Avec Neero, votre téléphone n’a jamais été aussi mobile.</p>
            </div>
            <div></div>
          </div>
          <div className='mission-section-2'>
            <h1>Quelle histoire !</h1>
            <p>
              Forts d’une expérience de 20 ans dans le domaine de la réparation
              de téléphone en service après vente et inter-entreprises, les
              fondateurs de Neero lancent une solution unique en France : un
              atelier mobile de réparation de produits connectés, à domicile, au
              bureau, ou en bas de chez vous.
            </p>
            <p>
              Passionnés de technologies et conscients de la place grandissante
              des smartphones dans nos vies quotidiennes, nous proposons un
              service de
            </p>
            <p>
              proximité pour vous éviter la dure séparation (💔) avec vos
              appareils et les ramener à la vie le plus rapidement possible !
            </p>
            <p>
              Notre application d'aide au diagnostic, développée par nos
              experts, et destinée aux utilisateurs soucieux du bon
              fonctionnement de leurs appareils, vous aidera à réaliser des
              tests complets sur vos appareils et identifier clairement les
              pannes sur vos appareils. Nos réparations se font en totale
              transparence.
            </p>
            <p>Vous partagez notre vision et désirez en savoir plus ?</p>
            <p>Nous serions ravis d'échanger avec vous !</p>
            <p>
              <a
                onClick={() => history.push("/contact")}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                Ecrivez-nous
              </a>
            </p>{" "}
            {/* eslint-disable-line */}
          </div>
          <FooterV2 />
        </div>
      )}
    </SmallScreenContext.Consumer>
  );
}

const actionCreators = {
  setAddressDemand: demandActions.setAddress,
  setAddressResume: resumeActions.setAddress,
};

const connectedMissionPage = connect(null, actionCreators)(MissionPage);
export { connectedMissionPage as MissionPage };
