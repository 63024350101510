export const externals = {
    apiUrl: process.env.REACT_APP_API_BASE_URL,
    defaultPhoneImage: process.env.REACT_APP_CDN_BASE_URL+'/media/cache/resolve/thumb120/catalog/default_phone_image.png',
    phoneImagesUrl: process.env.REACT_APP_CDN_BASE_URL+'/media/cache/resolve/thumb120/'
}

export const regex = {
    //eslint-disable-next-line
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/,
    zip: /^(?:[0-8]\d|9[0-8])\d{3}$/,
    phone: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
}

export const staticId = {
    InterventionUnvailable:  "2d4b0054-8832-4cef-8efc-3d520beda3c4",
    InterventionUnknow: "1def42d7-e017-4017-84f6-d4cd658453b4",
    appleUuid: "df132fbe-b0c3-11eb-9596-fa163e8933f6"
}

export const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

export const zipDefault = {
    notUsed: "00000",
    notFound: "99999"
}

export const discounts = {
    interventionDiscount: 0.5
}

export const socialMediaUrl = {
    facebook: 'https://www.facebook.com/neero.fr',
    instagram: 'https://www.instagram.com/neero.fr',
    linkedIn: 'https://www.linkedin.com/company/neero-fr',
    tiktok: 'https://www.linkedin.com/company/neero-fr',
    comments: 'https://g.page/r/CcA_PhrW_KrcEAo',
    googleReviews: 'https://goo.gl/maps/MTBJsLhz8Jec1AHt6',
    androidApp: 'https://play.google.com/store/apps/details?id=co.trackn.neero',
    iosApp: 'https://apple.co/3bkbU6m'
}

export const contacts = {
    email: "contact@neero.fr",
    tel: "01 80 88 57 00",
    telFull: "+330180885700",
    mediaTel: "06 09 20 48 86",
    mediaTelFull: "+330609204886",
    webSite: "https://www.cm2c.net"
}

export const typeFormUrl = {
    nousRejoindre: 'https://neero.typeform.com/to/FJmMnR',
    entreprise: 'https://neero.typeform.com/to/U9LN0u6M'
}