import { interventionsConstants } from '../constants';

const initialState = {
    interventions: null,
    loading: false,
}

export function interventions(state = initialState, action) {
  switch (action.type) {
    case interventionsConstants.INIT:
        return {
            interventions: null,
            loading: false,
        };
    case interventionsConstants.SET_INTERVENTIONS:
        return{
            ...state,
            interventions: action.interventions,
            loading: false
        }
    case interventionsConstants.SET_LOADING:
        return{
            ...state,
            loading: true
        }
    case interventionsConstants.INTERVENTIONS_FAILED:
        return {
            ...state,
            loading: false
        }
    default:
        return state
  }
}