import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './helpers';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'
//import ReactPixel from 'react-facebook-pixel';

// facebook pixel
// optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };
/*ReactPixel.init(
  process.env.REACT_APP_FB_PIXEL_API_KEY,
  options
);*/
//ReactPixel.pageView(); // For tracking page view
// google tag manager
//TagManager.initialize({ gtmId: process.env.REACT_APP_AD_WORDS_API_ID })
TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_ENV
})

// google analytic
ReactGA.initialize(process.env.REACT_APP_GA4_ID);
//ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.send("pageview");

// sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_API_DSN,
  integrations: [new Integrations.BrowserTracing()],
});

ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
