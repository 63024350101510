import { interventionsConstants } from '../constants';
import { interventionsService } from '../services';
import { alertActions } from '.';

export const interventionsActions = { 
    getInterventions,
    initInterventions
 };

function getInterventions(id) {
    return dispatch => {
        dispatch(request());
        interventionsService.getInterventions(id)
            .then(
                interventions => { 
                    dispatch(success(interventions.data));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: interventionsConstants.SET_LOADING } }
    function success(interventions) { return { type: interventionsConstants.SET_INTERVENTIONS, interventions } }
    function failure() { return { type: interventionsConstants.INTERVENTIONS_FAILED } }
}

function initInterventions() {
    return { type: interventionsConstants.INIT };
}