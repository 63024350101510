import React, {useEffect, useState} from 'react';
import './index.css';
import ButtonBStrap from 'react-bootstrap/Button';
import InterventionSelect from '../../../components/InterventionSelect';
import {demandActions, discountActions, resumeActions, slotsActions,} from '../../../actions';
import {connect} from 'react-redux';
import {Spinner} from 'evergreen-ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {staticId} from '../../../config';
import DemandeFooter from './DemandeFooter';
const interventionUnvailable={
    uuid: staticId.InterventionUnvailable,
    publicIntervention: {name: "Ma panne n'est pas dans la liste"},
    price: null,
    isChecked: false,
    image: null,
    discount: false,
}

const interventionUnknow={
    uuid: staticId.InterventionUnknow,
    publicIntervention: {name: "J'ignore ma panne"},
    price: null,
    isChecked: false,
    image: null,
    discount: false,
}
const createSelectedIdsArray = (interventions, demandInterventions) => {
    if (demandInterventions && Array.isArray(demandInterventions)) {

        if (demandInterventions.filter(interv => interv.uuid === staticId.InterventionUnvailable).length === 0) {
            demandInterventions.push(interventionUnvailable);
            demandInterventions.push(interventionUnknow);
        }

        return demandInterventions;

    }

        let tabs = [];
        let i = 0;
        if (interventions) {
            for (i = 0; i < interventions.length; i++) {
                tabs[i] = interventions[i];
            }
            tabs[i] = interventionUnvailable;
            tabs[i + 1] = interventionUnknow;

        return tabs;
    }
};

function DemandeFormP2(props) {
    const [selectedIds, setSelectedIds] = useState(
        createSelectedIdsArray(props.interventions, props.demandInterventions)
    );
    const [openDialog, setOpenDialog] = useState(false);

    const onSelect = (id, isChecked, discount) => {
        for (var i in selectedIds) {
            // if (selectedIds[i].id === id) {
            if (selectedIds[i].uuid === id) {
                if (
                    id === staticId.InterventionUnknow ||
                    id === staticId.InterventionUnvailable
                ) {
                    for (var j in selectedIds) {
                        selectedIds[j].isChecked = false;
                        if (isChecked) selectedIds[j].disabled = true;
                        else selectedIds[j].disabled = false;
                    }
                }
                selectedIds[i].isChecked = isChecked;
                selectedIds[i].discount = discount;
                var checkDiscount = selectedIds.filter(function (obj) {
                    return (
                        // obj.id !== staticId.InterventionUnknow &&
                        obj.uuid !== staticId.InterventionUnknow &&
                        // obj.id !== staticId.InterventionUnvailable &&
                        obj.uuid !== staticId.InterventionUnvailable &&
                        obj.isChecked === true
                    );
                });
                if (checkDiscount?.length)
                    props.getDiscount(props.model, checkDiscount, null);
                else {
                    props.initDiscount();
                    //props.initCoupon()
                }
                setSelectedIds(selectedIds.slice());
                props.setInterventions(selectedIds);
                props.setPrice(
                    selectedIds.filter(function (obj) {
                        return obj.isChecked === true;
                    })
                );
                //props.getDiscount(props.model, selectedIds.filter(function( obj ) { return obj.isChecked === true}), props.coupon)
                break;
            }
        }
    };

    const handleSubmit = () => {
        if (
            selectedIds.filter(function (obj) {
                return obj.isChecked === true;
            }).length === 0
        ) {
            setOpenDialog(true);
        } else {
            props.getSlots();
            props.nextPage();
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        setSelectedIds(
            createSelectedIdsArray(props.interventions, props.demandInterventions)
        );
    }, [props.interventions, props.demandInterventions]);

    const {demandInterventions} = props;

    //console.log('selectedIds:', selectedIds)

    return (
        <>
            <div className="demande-from-container">
                <h1>Que voulez-vous faire réparer ?</h1>
                <p>Sélectionnez votre ou vos pannes.</p>
                <h6>
                    Réservez et ne payez qu'une fois la réparation réussie,
                    <br/>
                    bénéficiez de 50% de réduction sur la 2ème panne de votre appareil.
                </h6>
                {/*<p style={{fontSize: 11}}>*Offre valable sur la panne la moins chère.</p>*/}
                {props.loading ? (
                    <Spinner style={{paddingTop: 20, paddingBottom: 50}}/>
                ) : (
                    <InterventionSelect
                        interventions={selectedIds}
                        // interventions={demandInterventions}
                        isSmallScreen={props.isSmallScreen}
                        onSelect={onSelect}
                    />
                )}

                {selectedIds && selectedIds.length ? (
                    <ButtonBStrap className="btn-gradient" onClick={handleSubmit}>
                        <u>Étape suivante</u>
                    </ButtonBStrap>
                ) : null}
            </div>
            <Dialog
                fullWidth={true}
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Attention'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Veuillez sélectionner au moins une panne
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonBStrap
                        className="btn-gradient"
                        onClick={handleClose}
                        style={{margin: 10}}
                    >
                        <u>Ok</u>
                    </ButtonBStrap>
                </DialogActions>
            </Dialog>
            <DemandeFooter/>
        </>
    );
}

function mapState(state) {
    const {interventions, loading} = state.interventions;
    const {model} = state.demand;
    const {coupon} = state.discount;
    const demandInterventions = state.demand.interventions;
    return {interventions, loading, demandInterventions, model, coupon};
}

const actionCreators = {
    setInterventions: demandActions.setInterventions,
    nextPage: demandActions.nextPage,
    setPrice: resumeActions.setPrice,
    getSlots: slotsActions.getSlots,
    getDiscount: discountActions.getDiscount,
    initDiscount: discountActions.initDiscount,
    initCoupon: discountActions.initCoupon,
    checkCoupon: discountActions.checkCoupon,
};

const connectedDemandeFormP2 = connect(mapState, actionCreators)(DemandeFormP2);
export {connectedDemandeFormP2 as DemandeFormP2};
