import "./index.css";
import { externals } from '../../config'

const imagesUrl = externals.phoneImagesUrl
const imageDefault = externals.defaultPhoneImage
  
function ModelsList({models, selected, onSelect }) {
    
    const Card = ({model}) => {
        const imageUrl = imagesUrl + model.image
        const selectModel = () => onSelect(model)

        return (
            <div 
                key={model.uuid} 
                className='diplay-model' 
                style={{borderColor: model.uuid === selected?.uuid? "#6232A0": null}}
                onClick={selectModel}
            >
                <img src={imageUrl? imageUrl: imageDefault} alt={model.name} />
                <p>{model.name}</p>
            </div>
        )
    }
    return (
        <div className='diplay-models'>
            {models?.map((model,i)=>(
                <div key={i}>
                    <Card  model={model}/>
                </div>
            ))}
        </div>
    );
  }

  export default ModelsList