import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

const HeaderNewVersion = () => {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);

  return (
    <>
      <header className='main_header_new_version'>
        <div className='container'>
          <div className='logo'>
            <Link to='/'>
              <img src='/logo.png' alt='neero' />
            </Link>
          </div>
          <ul className='navbar'>
            <li className='nav_item'>
              <Link className='navitem_link' to='/'>
                Accueil
              </Link>
            </li>
            <li className='nav_item'>
              <Link
                className='navitem_link'
                to='/application-mobile-diagnostic-neero'
              >
                L'application mobile
              </Link>
            </li>
            <li className='nav_item'>
              <Link className='navitem_link' to='/contact'>
                Contact
              </Link>
            </li>
            <li className='nav_item'>
              <Link className='navitem_link' to='/espace'>
                Mon compte
              </Link>
            </li>
          </ul>
          <div className='cta'>
            <Link className='cta_btn' to='/demande'>
              Réserver une réparation
            </Link>
          </div>
          <div
            className='menu_mobile'
            onClick={() => setDrawerIsVisible(prevState => !prevState)}
          >
            <i className='fas fa-bars'></i>
          </div>
        </div>
      </header>
      <div
        style={
          drawerIsVisible
            ? {
                transform: "translateX(0%)",
              }
            : {
                transform: "translateX(-100%)",
              }
        }
        className='main_drawer_app'
      >
        <i className='fas fa-times' onClick={() => setDrawerIsVisible(false)}></i>
        <ul className='navbar'>
          <li className='nav_item'>
            <Link
              className='navitem_link'
              to='/'
              onClick={() => setDrawerIsVisible(false)}
            >
              Accueil
            </Link>
          </li>
          <li className='nav_item'>
            <Link
              onClick={() => setDrawerIsVisible(false)}
              className='navitem_link'
              to='/application-mobile-diagnostic-neero'
            >
              L'application mobile
            </Link>
          </li>
          <li className='nav_item'>
            <Link
              onClick={() => setDrawerIsVisible(false)}
              className='navitem_link'
              to='/contact'
            >
              Contact
            </Link>
          </li>
          <li className='nav_item'>
            <Link
              onClick={() => setDrawerIsVisible(false)}
              className='navitem_link'
              to='/espace'
            >
              Mon compte
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default HeaderNewVersion;
