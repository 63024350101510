import { useEffect } from "react";
import "./index.css";
import { useHistory } from "react-router-dom";
import { contacts } from "../../config";
//import ReactPixel from 'react-facebook-pixel';

export function AddressUnavailable(props) {
  const history = useHistory();
  const openCrisp = () => {
    window.$crisp.push(["do", "chat:open"]);
  };
  const goToDemande = () => {
    //props.context.showMenu()
    history.push({
      pathname: "/demande",
      state: { saveData: true },
    });
  };
  useEffect(() => {
    // ReactPixel.pageView()
  }, []);
  return (
    <>
      <div className='addressUnavailable-container'>
        <h1>😕</h1>
        <h1>Oups, notre équipe n'est pas encore opérationnelle chez vous </h1>
        <p>
          Mais pas d'inquiétude, nos techniciens pourront vous proposer d'autres
          solutions pour réparer votre petit précieux.
        </p>
        <p>Contactez-notre équipe :</p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ul>
            <li>
              Par téléphone{" "}
              <u href={"tel:" + contacts.telFull}>{contacts.tel}</u>
            </li>
            <li>
              Sur le chat: <u onClick={openCrisp}>ouvrir</u>
            </li>
            <li>
              Par email: <u onClick={() => history.push("/contact")}>contact</u>
            </li>
          </ul>
        </div>
        <p>
          Vous pouvez aussi choisir une autre{" "}
          <u onClick={goToDemande}>adresse</u>
        </p>
      </div>
    </>
  );
}
