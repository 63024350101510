import "./index.css";
  
function TunnelCmdItem({text, icon, page, pageId, isLoggedIn, isFinished}) {
    let seen = false
    let onPage = false
    if(isFinished) seen = true
    else{
        if(pageId === 4 && page === 4){
            if(isLoggedIn) seen = true
            else onPage = true
        }
        else {
            if (page === pageId ) onPage = true
            else{
                if(page === 4 && pageId === 5 && isLoggedIn) onPage = true
                else if(page > pageId) seen = true
            }
        }
    }

    return (
        <div 
            className="tunnel-cmd-item" 
            style={{
                backgroundColor: onPage? '#EEEBF3': seen? '#722CC8': "white",  
                borderColor: onPage || seen ? "#722CC8": null
            }}
        >
            <p style={{color: onPage? "#722CC8": seen? "white": "#2C2C2C"}}><span>{icon}</span> {text}</p>
        </div>
    );
  }

  export default TunnelCmdItem