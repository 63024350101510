import { userService } from "../services/user.service"

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export function handleResponseAuth(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) userService.refresh(JSON.parse(localStorage.getItem('user')).refresh_token)
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}