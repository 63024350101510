import React, { Component } from "react";
import  CommandesTable from "../../components/CommandesTable";
import './index.css';

export default class CommandesCard extends Component {

  render() {
    return (
        <div className='cmd-card'>
            {
            this.props.orders && this.props.orders.length > 0?
              <CommandesTable rows={this.props.orders} />
              :
              <p className="cmd-null">Vous n’avez aucune commande</p>
            }
        </div>
    );
  }
}