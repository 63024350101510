import EI1 from "../assets/icons/ei1.svg";
import EI2 from "../assets/icons/ei2.svg";
import EI3 from "../assets/icons/ei3.svg";
import EI4 from "../assets/icons/ei4.svg";
import EI5 from "../assets/icons/ei5.svg";
import EI6 from "../assets/icons/ei6.svg";
import EI7 from "../assets/icons/ei7.svg";
import II1 from "../assets/icons/ii1.svg";
import II2 from "../assets/icons/ii2.svg";
import II3 from "../assets/icons/ii3.svg";
import II4 from "../assets/icons/ii4.svg";
import II5 from "../assets/icons/ii5.svg";
import { contacts } from "./index";

export const TunnelCmdSteps = [
    { text:"Informations sur l’appareil", icon:'📱'},
    { text:"Indiquez les pannes", icon:'🔧'},
    { text:"Vos disponibilités", icon:'📅'},
    { text:"Vos informations", icon:'📝'},
    { text:"Validation de la demande", icon:'✅'}
]

export const EngagementsItems = [
    {icon: <div style={{backgroundImage: `url(${EI1})`}} />, title: 'Déplacement Offert'},
    {icon: <div style={{backgroundImage: `url(${EI2})`}} />, title: 'Prix Transparents'},
    {icon: <div style={{backgroundImage: `url(${EI3})`}} />, title: 'Techniciens Certifiés'},
    {icon: <div style={{backgroundImage: `url(${EI4})`}} />, title: 'Réparation en 20mn'},
    {icon: <div style={{backgroundImage: `url(${EI5})`}} />, title: 'Garantie 12 mois'},
    {icon: <div style={{backgroundImage: `url(${EI6})`}} />, title: 'Pièces de Qualité Officielle'},
    {icon: <div style={{backgroundImage: `url(${EI7})`}} />, title: 'Réservation en 2mn'},
]

export const InterventionsItems = [
    {icon: <div style={{backgroundImage: `url(${II1})`}} />, title: 'Batterie'},
    {icon: <div style={{backgroundImage: `url(${II2})`}} />, title: 'Caméras'},
    {icon: <div style={{backgroundImage: `url(${II3})`}} />, title: 'Charge'},
    {icon: <div style={{backgroundImage: `url(${II4})`}} />, title: 'Audio'},
    {icon: <div style={{backgroundImage: `url(${II5})`}} />, title: 'Ecran'},
]

export const ReviewsItems = [
    {id: 0, name: 'Jimmy Mohamed', rating: 6, date: 'Visité en janvier', text: "Service parfait, réparation directement en bas de chez vous (ou ailleurs) dans un camion tout équipé, sur rendez vous avec tarif connu d'avance et respecté ! Mon téléphone a été réparé en 10 minutes chrono montre en main !  Rapport qualité/prix imbattable avec technicien très aimable. Bref, je recommande les yeux fermés !"},
    {id: 1, name: 'Steve S.', rating: 6, date: 'Visité en janvier', text: "Les équipes sont extrêmement réactives avec des délais rapides d'intervention,  pour un tarif raisonnable au regard de ce que propose la concurrence. Les intervenants sont également fort sympathiques. C'est très appréciable d'avoir pu réparer mon Ipad sans avoir eu à me déplacer."},
    {id: 2, name: 'Franck Herbault', rating: 6, date: 'Visité en décembre 2021', text: "La société Neero est intervenue pour remplacer à domicile la dalle de l’IPhone de mon fils. Accueil sympa, détection rapide de la panne, intervention efficace et rapide. Le rdv était prévu entre a14 h et 16 h - ils sont arrivés à 15 h en téléphonant avant pour prévenir. Ça fait plaisir d’avoir des Pro comme ça. A recommander 👍👍👍"},
    {id: 3, name: 'H Merk', rating: 6, date: 'Visité en décembre 2021', text: "Technicien professionnel et très sympa. Il est venu à la station avec son camio. et m’a réparé mon iPhone sur place en 20 minutes . Je recommande"},
    {id: 4, name: 'Ala-din Jamai', rating: 6, date: 'Visité en décembre 2021', text: "Service hallucinant! techniciens super cool et compétents, arrivés à l'heure"},
    {id: 5, name: 'Marie TOLA', rating: 6, date: 'Visité en décembre 2021', text: "Super professionnel, efficace, rapide. Service au top pour une galère de téléphone le dimanche . Merci 🙏🏽"},
    {id: 6, name: 'Gabriel Teboul', rating: 6, date: 'Visité en avril 2021', text: "C'est la deuxième fois que je fais appel à eux et je dois dire que le service est bluffant. Super réactif, prix adapté et sympathique en plus. les réparations sont parfaites, le résultat est juste parfait."},
]

export const CITIES_TAG = [
    {name: "Paris", indice: "(75)"},
    {name: "Yvelines", indice: "(78)"},
    {name: "Hauts de Seine", indice: "(92)"},
    {name: "Seine Saint Denis", indice: "(93)"},
    {name: "Val de Marne", indice: "(94)"},
    {name: "Val d'Oise", indice: "(95)"},
]

export const FAQ_QR = [
    {
        question:"Comment faire appel aux services de Neero ?",
        reponse:"C'est simple : il suffit de passer commande sur le site et de choisir l'adresse et le créneau qui vous convient. Notre réparateur se déplacera pour la réparation, chez vous ou à votre bureau."
    },
    {
        question:"Pendant la période du covid, comment faites-vous ?",
        reponse:"Pas d'inquiétude, pendant la crise sanitaire (ou en dehors d'ailleurs !), nous mettons en place des ateliers mobiles. Le réparateur répare votre appareil en bas de chez vous dans son truck, vos contacts seront limités et votre appareil désinfecté avant et après réparation."
    },
    {
        question:"Quels sont les délais de prise de rendez-vous ?",
        reponse:"Il n'y a aucun délai imposé, vous pouvez réserver le créneau qui vous convient sur l'agenda en ligne de nos réparateurs."
    },
    {
        question:"C'est vrai que le réparateur réalise l'intervention de mon appareil devant moi ?",
        reponse:"Tout à fait ! tout est transparent et l'intervention est réalisée sous vos yeux."
    },
    {
        question:"La réparation se fait en combien de temps ?",
        reponse:"Dans la plupart des cas, la réparation ne dure pas plus de 30 mn."
    },
    {
        question:"Puis-je annuler ou déplacer mon rendez-vous ?",
        reponse:"Oui, vous pouvez l'annuler ou le déplacer sans frais à tout moment au plus tard 24h avant le jour J depuis votre espace client ou en contactant notre service client."
    },
    {
        question:"Je ne peux pas recevoir le réparateur à mon domicile ou à mon bureau, quelles solutions proposez-vous ?",
        reponse:"Vous avez le choix de sélectionner un lieu où vous pourrez vous rendre le temps de la réparation sinon, notre réparateur se déplace avec un atelier mobile pour une réparation en bas de chez vous."
    },
    {
        question:"Avez-vous un magasin où je peux me rendre ?",
        reponse:"Nous n'avons pas de magasin, tous nos réparateurs se déplacent chez nos clients pour des réparations à domicile."
    },
    {
        question:"Quels sont les moyens de paiements acceptés pour la réparation ?",
        reponse:"Nous acceptons les paiements en carte bancaire directement via notre site internet."
    },
    {
        question:"Quand est-ce que mon paiement sera débité ?",
        reponse:"Pour la commande, le module de paiement ne garde que l'empreinte de votre carte bancaire. Le paiement n'est encaissé qu'une fois la réparation faite et réussie"
    },
    {
        question:"Puis-je payer en plusieurs fois ?",
        reponse:"Nous faisons de notre mieux pour vous proposer les meilleurs services. Le paiement en plusieurs fois fait partie des prochaines évolutions de notre site."
    },
    {
        question:"Je ne connais pas la panne de mon smartphone",
        reponse:"Pas de panique, un de nos réparateurs peut vous aider à diagnostiquer votre appareil. Nous sommes joignables par chat sur le site ou par téléphone au numéro : "+ contacts.tel +"."
    },
    {
        question:"Mon téléphone ne démarre pas, comment connaître la panne ?",
        reponse:"Si vous n'arrivez pas à identifier la panne de votre appareil, n'hésitez pas à contacter nos réparateurs depuis le chat sur notre site internet ou par téléphone directement."
    },
    {
        question:"Mon appareil a plusieurs pannes, comment ça se passe ?",
        reponse:"Nous appliquons une remise sur les appareils qui présentent plusieurs pannes. Notre service client est à votre disposition pour plus d'informations."
    },
    {
        question:"Ma réparation est-elle garantie ?",
        reponse:"Bien sûr ! Nos réparations sont garanties 6 mois hors casse et oxydation. Pour plus d'information, consultez nos CGU ou notre service client."
    },
    {
        question:"Ma garantie constructeur est-elle sauvegardée ?",
        reponse:"Pour des raisons évidentes d'exclusivité, les constructeurs excluent les appareils réparés de leurs garanties. Mais chez Neero, nous garantissons toutes nos réparations pendant 6 mois."
    },
    {
        question:"Mes données seront-elles perdues ?",
        reponse:"Non, nous ne touchons pas à vos données dans 90% des pannes. Si votre appareil présente un problème de carte mère, le réparateur vous indiquera le risque que cela survienne avant intervention."
    },
    {
        question:"Quelles pièces utilisez-vous ?",
        reponse:"On utilise exclusivement des pièces d'origine quand les constructeurs le permettent. Sinon, on sélectionne les pièces de qualité équivalente afin de garantir un fonctionnement similaire"
    },
    {
        question:"Les réparateurs sont-ils certifiés ?",
        reponse:"Nos réparateurs sont tous expérimentés et certifiés par GSM Master, organisme officiel de formation pour les appareils électroniques nomades (mobiles et tablettes). Ils bénéficient ensuite d’une formation continue pour pouvoir réparer les nouveaux modèles."
    },
    {
        question:"Je ne trouve pas mon appareil dans le catalogue",
        reponse:"Nous réparons toutes les marques du marché, il vous suffit de contacter notre service client par chat ou téléphone. Ils activeront la réparation dont vous aurez besoin en quelques minutes."
    },
    {
        question:"Je ne trouve pas la marque de mon appareil dans le catalogue",
        reponse:"Aucun problème ! Nous intervenons sur tous les modèles du marché, contactez notre service client par chat ou téléphone."
    },
    {
        question:"Je suis un professionnel, avez-vous des offres dédiées ?",
        reponse:"Bien sûr ! Notre service commercial se fera un plaisir de vous rappeler pour en discuter, n'hésitez pas à nous solliciter."
    },
    {
        question:"Je souhaite revendre mon appareil, vous le reprenez ?",
        reponse:"Notre démarche écologique inclut la reprise d'appareils usagés et hors service. Consultez notre service client."
    },
    {
        question:"Intervenez-vous dans toute la France ?",
        reponse:"Notre réseau de réparateurs couvre tout le territoire mais pour l’instant l’intervention à domicile est disponible dans les grandes métropoles. En attendant, vous pouvez nous faire parvenir votre appareil par courrier pour une réparation sous 48h dans notre atelier. Contactez notre service client par chat ou téléphone pour avoir plus de détails."
    },
    {
        question:"Que deviennent les pièces changées après réparation ?",
        reponse:"Toutes les pièces collectées, quel que soit leur état, sont recyclées en interne. 70% sont ré-utilisées ou reconditionnées avant de l'être. Pour les autres, nos partenaires les intègrent dans des circuits spécialisés afin de les décomposer et en extraire les métaux."
    },
]