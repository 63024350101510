import { demandConstants } from '../constants';

const initialState = {
  model: null,
  interventions: null,
  address: null,
  appointmentSlots: null,
  page: 1,
  modelsType: null,
  orderSent: false,
  lastOrder: null,
  loading: false,
  date: new Date(),
};

export function demand(state = initialState, action) {
  switch (action.type) {
    // For Local state
    case demandConstants.INIT:
      //console.log('::::::>>>>')
      return {
        model: null,
        interventions: null,
        address: null,
        appointmentSlots: null,
        modelsType: null,
        page: 1,
        orderSent: false,
        loading: false,
        date: new Date(),
      };
    case demandConstants.SET_MODEL:
      return {
        ...state,
        model: action.model,
      };
    case demandConstants.SET_INTERVENTIONS:
      return {
        ...state,
        interventions: action.interventions,
      };
    case demandConstants.SET_INTERVENTIONSDEMAND:
      return {
        ...state,
        interventions: action.payload,
      };
    case demandConstants.SET_ADDRESS:
      return {
        ...state,
        address: action.address,
      };
    case demandConstants.SET_SLOTS:
      return {
        ...state,
        appointmentSlots: action.slots,
        date: new Date(),
      };
    case demandConstants.SET_MODELS_TYPE:
      return {
        ...state,
        modelsType: action.modelsType,
      };
    case demandConstants.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case demandConstants.NEXT_PAGE:
      return {
        ...state,
        page: state.page === 4 ? 1 : state.page + 1,
      };
    case demandConstants.PREVIOUS_PAGE:
      return {
        ...state,
        page: state.page === 1 ? 1 : state.page - 1,
      };
    // FOR request-order
    case demandConstants.ORDER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case demandConstants.ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        orderSent: false,
      };
    case demandConstants.ORDER_SUCCESS:
      return {
        ...state,
        lastOrder: action.order,
        orderSent: true,
        loading: false,
      };

    case demandConstants.ORDER_FAILURE:
      return {
        ...state,
        orderSent: false,
        loading: false,
      };
    default:
      return state;
  }
}
