import { authHeader } from '../helpers';
import { externals } from "../config";

const apiUrl = externals.apiUrl

export const userService = {
    login,
    forgottenPassword,
    resetPassword,
    getUserInfo,
    logout,
    register,
    getAll,
    getById,
    update,
    refresh,
    delete: _delete
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            "username": username,
            "password": password
          })
    };

    return fetch(`${apiUrl}/api/v1/requester/login-check`, requestOptions)
        .then(handleResponseLogin)
        .then(user => {
            // store user details in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function forgottenPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({ "email": email })
    };

    return fetch(`${apiUrl}/api/v1/requester/forgotten-password`, requestOptions)
        .then(handleResponse)
        .then(response => {
            localStorage.setItem('mailSend', JSON.stringify(true))
            //localStorage.setItem('resetToken', JSON.stringify(response.resetToken));
            return response;
        });
}

function resetPassword(resetToken, newPassword) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({ "resetToken": resetToken, "newPassword": newPassword })
    };

    return fetch(`${apiUrl}/api/v1/requester/reset-password`, requestOptions).then(handleResponse)
}

function refresh(refreshToken) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("refresh_token", refreshToken);

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
    };

    return fetch(`${apiUrl}/api/v1/requester/token/refresh`, requestOptions)
    .then(handleResponseRefresh)
    .then(tokens => {
        // store user details in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(tokens));
        return tokens;
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/users`, requestOptions).then(handleResponse);
}

function getUserInfo() {
    const requestOptions = {
        method: 'GET',
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        redirect: 'follow'
    };

    return fetch(`${apiUrl}/api/v1/requester/user/infos`, requestOptions).then(handleResponse)
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            "civility": user.civility,
            "firstname": user.prenom,
            "lastname": user.nom,
            "email": user.email,
            "phone": user.phone,
            "password": user.password,
            "address1": user.adresse,
            "address2": user.adresseComplement,
            "company": user.raisonSociale,
            "city": user.ville,
            "zip": user.codePostal,
            "optin": user.optin
          })
    };

    return fetch(`${apiUrl}/api/v1/requester/signup`, requestOptions)
        .then(handleResponses)
        .then(user => {
            // store user details in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse)
}

function handleResponseRefresh(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) logout();
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                refresh(JSON.parse(localStorage.getItem('user')).refresh_token)
                //ocation.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponseLogin(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                if(localStorage.getItem('user')) refresh(JSON.parse(localStorage.getItem('user')).refresh_token)
                //ocation.reload(true);
                return Promise.reject("L'adresse e-mail ou le mot de passe est invalide");
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponses(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                refresh(JSON.parse(localStorage.getItem('user')).refresh_token)
                //ocation.reload(true);
            }
            const errors = (data && data.errors) || response.statusText;
            let error = (data && data.message) || response.statusText
            if (errors.length > 0){            
                let errType = ''
                switch(errors[0].propertyPath) {
                    case 'civility':
                        errType = 'Civilité'
                        break;
                    case 'firstname':
                        errType = 'Prénom'
                        break;
                    case 'lastname':
                        errType = 'Nom'
                        break;
                    case 'city':
                        errType = "Ville"
                        break;                        
                    case 'zip':
                        errType = "Code Postal"
                        break;
                    case 'phone':
                        errType = "Téléphone"
                        break;
                    case 'password':
                        errType = "Mot de passe"
                        break;
                    case 'address1':
                        errType = "Adresse"
                        break;
                    case 'address2':
                        errType = "Complément d’adresse"
                        break;
                    case 'optin':
                        errType = "Recevoir des promotions"
                        break;
                    default:
                        errType = errors[0].propertyPath
                    }
                error = errType + ": " + errors[0].message
            }
            
            return Promise.reject(error);
        }

        return data;
    });
}