import { discountConstants } from '../constants';
import { discountService } from '../services';

export const discountActions = {
    getDiscount,
    initDiscount,
    checkCoupon,
    initCoupon
};

function getDiscount(model, interventions, coupon) {
    return dispatch => {
        dispatch(request());
        discountService.getDiscount(model, interventions, coupon)
            .then(
                response => { 
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure());
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: discountConstants.DISCOUNT_REQUEST } }
    function success(discount) { return { type: discountConstants.DISCOUNT_SUCCESS, discount } }
    function failure() { return { type: discountConstants.DISCOUNT_FAILURE } }
}

function initDiscount() { return { type: discountConstants.INIT_DISCOUNT } }

function initCoupon() { return { type: discountConstants.INIT_COUPON } }

function checkCoupon(coupon) {
    return dispatch => {
        dispatch(request())
        discountService.checkCoupon(coupon)
        .then((response) => {
            dispatch(success(coupon, response))
        }).catch((error) => {
            dispatch(failure(error))
        })
    }
        

    function request() { return { type: discountConstants.COUPON_REQUEST } }
    function success(coupon, msg) { return { type: discountConstants.COUPON_SUCCESS, coupon, msg } }
    function failure(msg) { return { type: discountConstants.COUPON_FAILURE, msg } }
};
