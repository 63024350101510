import Button from 'react-bootstrap/Button';
import './HomeHeader.css';
import RightArrowSvg from "../../assets/svgFiles/rightArrow";
import GoogleMapsRating from "../../components/GoogleMapsRating";
import HomeHeaderImage from "../../assets/images/HomeHeader-Image.png";
import HomeHeaderImageMobile from "../../assets/images/HomeHeader-Image-mobile.png";

export default function HomeHeader({goToBookingPage}) {

  return (
    <>
        <div className="homeV2-header-container">
            <div className="homeV2-header-image-mobile" style={{backgroundImage: `url(${HomeHeaderImageMobile})`}} />
            <div className="homeV2-header-block">
                <h1>On répare vos smartphones chez vous, au travail et partout</h1>
                <p>Smartphone ou tablette, notre équipe prend en charge toutes les pannes de vos appareils et intervient avec nos ateliers mobiles à l’endroit de votre choix.</p>
                <div className="homeV2-rating-container">
                    <Button className="btn-gradient-2"  onClick={goToBookingPage}>
                        <u>{("réserver Une réparation").toUpperCase()}</u>
                        <RightArrowSvg/>
                    </Button>
                    <div className="fixing-rating-margin">
                        <GoogleMapsRating />
                    </div>
                </div>
            </div>
            <div className="homeV2-header-image">
                <div style={{backgroundImage: `url(${HomeHeaderImage})`}} />
            </div>
        </div>
    </>
  );
}

