import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { SmallScreenContext } from "../helpers";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const geocoderService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleMapsAutocomplete(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value);
  const [inputValue, setInputValue] = React.useState(props.value);
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const isSmallScreen = React.useContext(SmallScreenContext).isSmallScreen;

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps') && !document.querySelector('#script-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key='+process.env.REACT_APP_GMAPS_API_KEY+'&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        var request = {
          input: input.input,
          //offset: 3,
          types: ['address'],
          componentRestrictions: {country: 'fr'},
        };
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  /*const fetch2 = React.useMemo(
    () =>
      throttle((input, callback) => {
        var request = {
          placeId: input.place_id,
          //componentRestrictions: {country: 'fr'},
        };
        geocoderService.current.getDetails(request, callback);
      }, 200),
    [],
  );*/

  React.useEffect(() => {
    if (!geocoderService.current && window.google) {
      geocoderService.current = new window.google.maps.Geocoder();
    }
    if (!geocoderService.current) {
      return undefined;
    }
    

    if (value){
      geocoderService.current.geocode({ placeId: value.place_id }, (results, status) => {
        if (status === "OK") props.optionCallback(value,results[0])         
      });
    }
    //eslint-disable-next-line
  }, [value])

  return (
    <Autocomplete
      id="autocomplete-google-map"
      style={{ width: props.width, backgroundColor: "white" }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options?options:[]}
      disablePortal={isSmallScreen}
      autoComplete
      freeSolo
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        props.inputCallback(newInputValue)
      }}
      renderInput={(params) => (
        <TextField label="Saisissez votre adresse" variant="outlined" fullWidth
          {...params}
          InputProps={{ ...params.InputProps, 
            endAdornment: 
            <div style={{marginRight: -23}}>
              <LocationOnOutlinedIcon  color='action' />
            </div>,}}
         />
      )}
      renderOption={(option) => {
        var parts = []
        if (option.structured_formatting) {
          const matches = option.structured_formatting.main_text_matched_substrings;
          parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );
        }

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting? option.structured_formatting.secondary_text:null}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
