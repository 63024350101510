import { discountConstants } from '../constants';

const initialState = {
    discount: null,
    loading: false,
    coupon: null,
    couponLoading: false,
    couponMsg: ''
}

export function discount(state = initialState, action) {
  switch (action.type) {
    case discountConstants.INIT_DISCOUNT:
        return {
            ...state,
            discount: null,
            loading: false,
        };
    case discountConstants.DISCOUNT_REQUEST:
        return{
            ...state,
            loading: true
        }
    case discountConstants.DISCOUNT_SUCCESS:
        return{
            ...state,
            discount: action.discount,
            loading: false
        }
    case discountConstants.DISCOUNT_FAILURE:
        return {
            ...state,
            loading: false
        }
    case discountConstants.INIT_COUPON:
        return {
            ...state,
            coupon: null,
            couponLoading: false,
            couponMsg: ''
        };
    case discountConstants.COUPON_REQUEST:
        return {
            ...state,
            couponLoading: true
        };
    case discountConstants.COUPON_SUCCESS:
        return {
            ...state,
            coupon: action.coupon,
            couponLoading: false,
            couponMsg: action.msg
        };
    case discountConstants.COUPON_FAILURE:
        return {
            ...state,
            coupon: null,
            couponLoading: false,
            couponMsg: action.msg
        };
    default:
        return state
  }
}