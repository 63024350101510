import React from "react";
import Button from "react-bootstrap/Button";
import "./index.css";
import { SmallScreenContext } from "../../helpers";
import { useHistory } from "react-router-dom";
//import ReactPixel from 'react-facebook-pixel';

const style = getComputedStyle(document.body);

export function ReparateurPage() {
  React.useEffect(() => {
    //ReactPixel.pageView()
  }, []);

  const history = useHistory();

  return (
    <SmallScreenContext.Consumer>
      {context => (
        <div className='rep-container'>
          <div className='rep-section'>
            <h1>
              Rejoignez nos équipes !<br />
              Devenez réparateur de smartphones à domicile
            </h1>
            <h3>
              👉 Rejoignez-nous, mettez votre savoir faire au service de nos
              clients et participez au lancement des services de réparation du
              futur 🛸
            </h3>
            <Button
              className='btn-gradient'
              onClick={() => history.push("./reparateur-join")}
            >
              <u>Je m'inscris</u>
            </Button>
            <div className='rep-section1'>
              <div className='rep-section10'>
                <div
                  className='rep-box'
                  style={{
                    backgroundColor: style.getPropertyValue("--secondary-1"),
                  }}
                >
                  <h6>Gardez votre liberté</h6>
                  <p>
                    Choisissez votre zone d'intervention et gérez vos plannings.
                    <br />
                    Définissez vos jours de disponibilité, sans durée imposée.
                  </p>
                </div>
                <div
                  className='rep-box'
                  style={{
                    backgroundColor: style.getPropertyValue("--tertiary-2"),
                  }}
                >
                  <h6>Le choix vous appartient</h6>
                  <p>
                    Recevez instantanément les demandes des clients et acceptez
                    les interventions, gérez votre activité sur notre
                    application.
                  </p>
                </div>
                <div
                  className='rep-box'
                  style={{
                    backgroundColor: style.getPropertyValue("--secondary-2"),
                  }}
                >
                  <h6>Comptez sur le groupe</h6>
                  <p>
                    Intégrez des équipes solidaires qui transmettent leur
                    savoir- faire, apprenez grâce à nos formations et profitez
                    de notre expertise SAV.
                  </p>
                </div>
              </div>
            </div>
            <div className='rep-section2'>
              <div>
                <h1>Des postes en CDI🔥</h1>
                <p>
                  L'aventure commence par notre formation certifiante qui va
                  jusqu'au niveau micro-soudure ! On s'occupe de vous apporter
                  toutes nos connaissances et l'expérience de plus de 20 ans de
                  nos experts.
                  <br />
                  Une fois la certification terminée, vous avez le choix
                  d'intégrer notre service de réparation en atelier ou faire le
                  tour de nos clients en atelier mobile 🚒
                </p>
                <p>
                  Acquérir des nouvelles compétences et vous lancer dans
                  l'aventure du futur vous tente ?
                </p>
                <p>
                  Il y a un bouton spécialement pour ça là haut, rejoignez-nous
                  👆
                </p>
              </div>
              <img className='rep-icon-0' alt='' />
            </div>
            <h2>Simple, easy et rien que pour vous</h2>
            <div className='rep-section3'>
              <div className='rep-section30'>
                <img className='rep-icon-1' alt='' />
                <h4>Faire simple</h4>
                <p>
                  On a tout simplifié, notre application gère tout : de la prise
                  de rdv à l'encaissement.
                </p>
              </div>
              <div className='rep-section30'>
                <img className='rep-icon-2' alt='' />
                <h4>Pratique et écologique !</h4>
                <p>
                  En moto électrique ou en atelier mobile, on prend soin de la
                  planète et de nos clients.
                </p>
              </div>
              <div className='rep-section30'>
                <img className='rep-icon-3' alt='' />
                <h4>Aux petits soins pour vous !</h4>
                <p>
                  Accès à un catalogue VIP d'outils, pièces et accessoires à des
                  tarifs et qualité inégalée.
                </p>
              </div>
            </div>
            <div className='rep-section4'>
              <h1>Booste tes compétences 🌟💪</h1>
              <div className='rep-section40'>
                <div>
                  <h2>Formation initiale</h2>
                  <p>
                    Envie de devenir un réparateur certifié ? Nous proposons des
                    formations uniques en France, avec un organisme agrée au
                    RNCP. Une fois finalisée, vous aurez acquis toutes les
                    compétences nécessaires pour sauver n'importe quel appareil
                    ou objet connecté.
                  </p>
                </div>
                <div>
                  <h2>Formation continue</h2>
                  <p>
                    Vous avez déjà de l'expérience ? L'évaluation et la
                    validation d'acquis (VAE) est également possible chez nous.
                    Profitez aussi de nos séances de coaching et formations
                    micro soudure.
                  </p>
                </div>
              </div>
            </div>
            <div className='rep-section5'>
              <div>
                <h1>Le secret c'est notre esprit d'équipe !</h1>
                <p>
                  Les objets connectés n'auront plus aucun secret pour vous, nos
                  experts techniques vous forment et vous assistent sur les
                  pannes les plus complexes pour rendre la vie aux appareils de
                  nos clients et vous former aux techniques de micro soudre les
                  plus pointues.
                </p>
              </div>
              <img className='rep-icon-4' alt='' />
            </div>
            <div className='rep-section6'>
              <h1>Les étapes de notre collaboration</h1>
              <div className='rep-section60'>
                <div className='circle-option'>
                  <h2>1</h2>
                </div>
                <p>
                  <a onClick={() => history.push("./reparateur-join")}>
                    Inscris-toi,
                  </a>{" "}
                  le bouton est juste en bas 👇
                </p>
                {/* eslint-disable-line */}
              </div>
              <div className='rep-section60'>
                <div className='circle-option'>
                  <h2>2</h2>
                </div>
                <p>Tu passes un entretien avec l'un de nos experts 🥂</p>
              </div>
              <div className='rep-section60'>
                <div className='circle-option'>
                  <h2>3</h2>
                </div>
                <p>
                  Formation ou évaluation, Atelier mobile ou moto, tu choisis 🚀
                </p>
              </div>
              <div className='rep-section60'>
                <div className='circle-option'>
                  <h2>4</h2>
                </div>
                <p>Tu commences déjà à faire tes premières réparations 🪄</p>
              </div>
            </div>
            <h2>
              Des questions ?<br />
              Parlons-en !
            </h2>
            <h3>Sinon, pour les inscriptions c'est par ici 👇</h3>
            <Button
              className='btn-gradient'
              onClick={() => history.push("./reparateur-join")}
            >
              <u>Je rejoins le club</u>
            </Button>
            <div style={{ marginBottom: 50 }} />
          </div>
        </div>
      )}
    </SmallScreenContext.Consumer>
  );
}
