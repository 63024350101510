import React, { useState, useEffect } from 'react';
import GoogleMapsAutocomplete from '../../../components/GoogleMapsAutocomplete';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import './index.css';
import ButtonBStrap from 'react-bootstrap/Button';
import ImageSelect from '../../../components/ImageSelect';
import {
  manufacturersActions,
  demandActions,
  resumeActions,
  interventionsActions,
  settingsActions,
} from '../../../actions';
import { connect } from 'react-redux';
import { Spinner } from 'evergreen-ui';
import { zipDefault } from '../../../config';
import { useHistory } from 'react-router-dom';
//import ReactPixel from 'react-facebook-pixel';
import { staticId } from '../../../config';
//import { SmallScreenContext } from "../../../helpers";
import ModelsList from '../../../components/ModelsList';
import QuestionSvg from '../../../assets/svgFiles/question';
import DemandeFooter from './DemandeFooter';

function DemandeFormP1(props) {
  const [selectedId, setSelectedId] = useState(
    props.model ? props.model.manufacturer?.uuid : null
  );
  const [selectedTypesId, setSelectedTypesId] = useState(
    props.modelsType ? props.modelsType : null
  );
  const [addressInput, setAddressInput] = useState(
    props.address ? props.address.address1 : ''
  );
  const [isAddressOption, setIsAddressOption] = useState(
    props.address ? true : false
  );
  const [valideGMAdress, setValideGMAdress] = useState(
    props.address ? true : false
  );
  const [toKeepBrands, setToKeepBrands] = useState([]);

  const [modelValue, setModelValue] = useState(props.model);
  const [filtredModels, setFiltredModels] = useState([]);
  const [isModelValueChanged, setIsModelValueChanged] = useState(false);
  const [addressErrMsg, setAddressErrMsg] = useState('');
  const [modelErrMsg, setModelErrMsg] = useState('');
  //const isSmallScreen = useContext(SmallScreenContext).isSmallScreen;
  const [openDialog, setOpenDialog] = useState(false);
  const [submitErrMsg, setSubmitErrMsg] = useState(null);
  const history = useHistory();

  const handleAddressInputChange = (input) => {
    setAddressInput(input);
    setAddressErrMsg('');
    //setIsAddressOption(false)
  };

  useEffect(() => {
    if (!props.model) {
      props.setModelDemand(null);
      props.setModelResume(null);
      setSelectedId(null);
      setFiltredModels([]);
      setModelValue(null);
    } else {
      setModelValue(props.model);
    }
    //eslint-disable-next-line
  }, [props.model]);

  useEffect(() => {
    if (props.address) {
      setValideGMAdress(true);
      setIsAddressOption(true);
      setAddressInput(props.address.address1);
    } else {
      setValideGMAdress(false);
      setIsAddressOption(false);
      setAddressInput('');
    }
    //eslint-disable-next-line
  }, [props.address]);

  useEffect(() => {
    props.getManufacturersWithModels();
    setFiltredModels(filterModel(props.models));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFiltredModels(filterModel(props.models));
    //eslint-disable-next-line
  }, [props.models]);

  useEffect(() => {
    var brands = [];
    props.manufacturersWithModels?.map((obj) => {
      if (filterModel(obj.models)?.length) brands.push(obj.uuid);
      return null;
    });
    setToKeepBrands(brands);
    //eslint-disable-next-line
  }, [props.manufacturersWithModels, selectedTypesId]);

  const openCrisp = () => {
    setOpenDialog(false);
    window.$crisp.push(['do', 'chat:open']);
  };

  useEffect(() => {
    if (performance.navigation.type === 1) {
      if (!props.model) props.initModels();
    }
    //eslint-disable-next-line
  }, [window.performance]);

  const handleAddressOptionChange = (autocompleteresult, geocodeResult) => {
    let zipCode = geocodeResult.address_components.filter(function (obj) {
      return obj.types[0] === 'postal_code';
    });
    let city = geocodeResult.address_components.filter(function (obj) {
      return obj.types[0] === 'locality';
    })[0].long_name; //locality
    let address = {
      address1: autocompleteresult.structured_formatting.main_text,
      address2: autocompleteresult.structured_formatting.secondary_text,
      city: city,
      zip: zipCode.length > 0 ? zipCode[0].long_name : zipDefault.notFound,
      longitude: geocodeResult.geometry.location.lng(),
      latitude: geocodeResult.geometry.location.lat(),
    };
    //ReactPixel.track('SEARCH', null)
    settingsActions
      .checkAddress(address)
      .then((res) => {
        if (res.available) {
          setIsAddressOption(true);
          setValideGMAdress(true);
          props.setAddressResume(autocompleteresult.description);
          props.setAddressDemand(address);
        } else {
          setValideGMAdress(false);
          props.setAddressResume(null);
          props.setAddressDemand(null);
        }
        setIsAddressOption(true);
      })
      .catch((error) => {
        //console.log('*****',error)
      });
  };

  const searchModels = (e) => {
    let input = e.target?.value?.toLowerCase();
    let models = filterModel(props.models);
    setFiltredModels(
      models?.filter((model) => model.name.toLowerCase().includes(input))
    );
  };
  const handleModelChange = (model) => {
    // Déclencher l'action d'actualiser la liste des interventions par rapport au modèle choisi
    // Récupérer les interventions du modele id et les placer dans redux => demand => interventions
    const id = model.uuid;
    props.getInterventionsDemand(id);

    setModelValue(model);
    setIsModelValueChanged(true);
    setModelErrMsg('');
    props.setModelResume(model?.manufacturer?.name + ' - ' + model?.name);
    props.setModelDemand(model);
    handleSubmit(model);
  };

  const onSelectModelTypes = (id) => {
    props.setModelDemand(null);
    props.setModelResume(null);
    props.setModelsType(id);
    setSelectedTypesId(id);
    setSelectedId(null);
    setIsModelValueChanged(true);
    setFiltredModels([]);

    // props.getManufacturersWithModels();
    // setFiltredModels(filterModel(props.models));

    //props.getModels(id)
    setModelValue(null);
  };

  const onSelect = (id) => {
    props.setModelDemand(null);
    props.setModelResume(null);
    setSelectedId(id);
    setIsModelValueChanged(true);
    props.getModels(id);
    setModelValue(null);
  };

  const handleSubmit = (_model) => {
    let valide = true;
    if (!addressInput || addressInput.length < 4) {
      valide = false;
      setSubmitErrMsg('Veuillez entrer une adresse valide !');
      // setAddressErrMsg('Veuillez saisir une adresse valide');
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      document
        .querySelector('body')
        .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    if (!modelValue && !_model) {
      valide = false;
      setSubmitErrMsg('Veuillez choisir un modèle');
      setModelErrMsg(
        'Veuillez sélectionner la marque et le modèle de votre appareil !'
      );
    }

    if (valide && isAddressOption) {
      if (isModelValueChanged) {
        props.getInterventions(_model ? _model.uuid : modelValue.uuid);
        props.setInterventions(null);
        props.setPrice(null);
      }
      if (valideGMAdress) props.nextPage();
      else {
        setAddressInput('');
        props.showMenu();
        history.push('/address-unavailable');
      }
    }
  };

  const filterModel = (models) => {

    if (selectedTypesId && models)
      return models.filter((model) => model.modeltype?.id === selectedTypesId);
    else return models;
  };

  const filterOsInWebview = () => {
    let manufacturers = [];
    // filter models type first
    if (selectedTypesId)
      manufacturers = props.manufacturers.filter((brand) =>
        toKeepBrands.includes(brand.uuid)
      );
    else manufacturers = props.manufacturers;
    // filter OS in webview
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (props.isWebview)
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
        if (props.brandId === staticId.appleUuid)
          // if OS  == iOS
          return manufacturers.filter(
            (brand) => brand.uuid === staticId.appleUuid
          );
        else
          return manufacturers.filter(
            (brand) => brand.uuid !== staticId.appleUuid
          );
      else return manufacturers;
    // if OS == android
    else return manufacturers;
  };

  return (
    <>
      <div className="demande-from-container">
        <h1>Quelle est votre adresse ?</h1>
        <p>Indiquez l’adresse à laquelle vous voulez que l’on intervienne.</p>
        <div className="gm-input">
          <GoogleMapsAutocomplete
            value={addressInput}
            optionCallback={handleAddressOptionChange}
            inputCallback={handleAddressInputChange}
            width={props.isSmallScreen ? 270 : 450}
          />
          <em>{addressErrMsg}</em>
        </div>

        <h1>Quel est le type de votre appareil ?</h1>
        <p>
          Smartphones ou tablettes, nous réparons tous les modèles, quelle que
          soit la panne.
        </p>
        {props.modelTypesLoading ? (
          <Spinner style={{ alignSelf: 'center', paddingTop: 20 }} />
        ) : (
          <ImageSelect
            images={props.modelTypes}
            selectedId={selectedTypesId}
            onSelect={onSelectModelTypes}
            type={true}
          />
        )}

        <h1>Quelle est la marque de votre appareil ?</h1>
        <p>Nous réparons toutes les marques présentes sur le marché</p>
        {props.loading ? (
          <Spinner style={{ alignSelf: 'center', paddingTop: 20 }} />
        ) : (
          <ImageSelect
            images={filterOsInWebview()}
            selectedId={selectedId}
            onSelect={onSelect}
          />
        )}

        <h1>
          {filterModel(props.models) &&
          filterModel(props.models)[0] &&
          selectedId
            ? filterModel(props.models)[0].manufacturer.name +
              " ? ils ont d'excellents modèles 😍 lequel souhaitez-vous réparer ?"
            : 'Quel est votre modèle ?'}
        </h1>
        <p>
          Sélectionnez le modèle de smartphone correspondant à la marque que
          vous avez choisie
        </p>
        {/* <Autocomplete
            id="combo-box-model"
            disablePortal={isSmallScreen}
            options={filterModel(props.models)}
            disabled={!selectedId }
            getOptionLabel={(models) => models.name}
            style={{ width: props.isSmallScreen? 250: 400}}
            value={modelValue}
            onChange={(event, newValue) => {
              handleModelChange(newValue)
            }}
            renderInput={(params) => props.modelsLoading ?
              <Spinner style={{alignSelf: "center", paddingButtom: 20}}/>
              :
              <TextField {...params} label="Sélectionner" variant="outlined" />}
          /> */}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className="model-input">
            <TextField
              id="outlined-model-text"
              //label="E-mail"
              onKeyPress={(e) => {
                e.key === 'Enter' && handleSubmit(null);
              }}
              type="text"
              fullWidth
              onChange={searchModels}
              variant="outlined"
              disabled={!selectedId}
              placeholder={modelValue ? modelValue.name : null}
            />
            <em>{modelErrMsg}</em>
          </div>
          <div style={{ marginTop: -25 }}>
            <ButtonBStrap
              className="btn-gradient"
              onClick={() => handleSubmit(null)}
            >
              <u>Étape suivante</u>
            </ButtonBStrap>
          </div>
        </div>
        <div className="ModelsList-container">
          {filtredModels && filtredModels.length ? (
            <ModelsList
              models={filtredModels}
              selected={modelValue}
              onSelect={handleModelChange}
              //disabled={!selectedId }
            />
          ) : (
            selectedId && (
              <div className="dontKnowModel">
                <div>
                  <QuestionSvg />
                </div>
                <p onClick={() => setOpenDialog(true)}>
                  Je Ne Connais Pas Mon Modèle
                </p>
              </div>
            )
          )}
        </div>
      </div>
      <Dialog
        fullWidth={true}
        open={submitErrMsg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => setSubmitErrMsg(null)}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              fontWeight: 'bold',
              color: '#722CC8',
              fontSize: 21,
              marginTop: 25,
            }}
          >
            {submitErrMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
            marginBottom: 20,
            flexWrap: 'wrap',
          }}
        >
          <ButtonBStrap
            className="btn-gradient"
            onClick={() => setSubmitErrMsg(null)}
            style={{ margin: 10 }}
          >
            <u>Continuer</u>
          </ButtonBStrap>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">
          <p
            className="dialog-text"
            style={{
              fontWeight: 'bold',
              color: '#722CC8',
              fontSize: 21,
              marginTop: 25,
            }}
          >
            Comment identifier votre Appareil ?
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4
              className="dialog-text"
              style={{
                fontWeight: 'bold',
                color: '#722CC8',
                fontSize: 16,
                marginTop: 12,
              }}
            >
              Sur les appareils Apple :
            </h4>
            <p
              className="dialog-text"
              style={{ color: '#7F7F7F', fontSize: 15 }}
            >
              Allez sur réglages {'>'} Général {'>'} informations : le nom votre
              modèle est indiqué.
            </p>
            <h4
              className="dialog-text"
              style={{
                fontWeight: 'bold',
                color: '#722CC8',
                fontSize: 16,
                marginTop: 22,
              }}
            >
              Sur les appareils Android :
            </h4>
            <p
              className="dialog-text"
              style={{ color: '#7F7F7F', fontSize: 15 }}
            >
              Allez sur Paramètres {'>'} A Propos du Téléphone {'>'}{' '}
              informations : le nom de votre modèle est indiqué.
            </p>
            <h3 className="dialog-footer-text">
              Vous n’arrivez pas à identifier votre modèle ? Notre équipe peut
              vous aider à le faire. Contactez nous au <u>0180885700</u> ou
              directement sur le <u onClick={openCrisp}>chat</u>.
            </h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
            marginBottom: 20,
            flexWrap: 'wrap',
          }}
        >
          <ButtonBStrap
            className="btn-gradient"
            onClick={() => setOpenDialog(false)}
            style={{ margin: 10 }}
          >
            <u>Super Merci !</u>
          </ButtonBStrap>
        </DialogActions>
      </Dialog>
      <DemandeFooter />
    </>
  );
}

function mapState(state) {
  const {
    manufacturers,
    loading,
    models,
    modelsLoading,
    modelTypes,
    modelTypesLoading,
    manufacturersWithModels,
  } = state.manufacturers;
  const { model, address, modelsType } = state.demand;
  const ddd = state.demand;
  return {
    manufacturers,
    loading,
    models,
    modelsLoading,
    model,
    address,
    modelTypes,
    modelTypesLoading,
    manufacturersWithModels,
    modelsType,
    ddd,
  };
}

const actionCreators = {
  getModels: manufacturersActions.getModels,
  initModels: manufacturersActions.initModels,
  initModelTypes: manufacturersActions.initModelTypes,
  getManufacturersWithModels: manufacturersActions.getManufacturersWithModels,
  setAddressDemand: demandActions.setAddress,
  setModelDemand: demandActions.setModel,
  setModelsType: demandActions.setModelsType,
  setPage: demandActions.setPage,
  nextPage: demandActions.nextPage,
  setInterventions: demandActions.setInterventions,
  setAddressResume: resumeActions.setAddress,
  setModelResume: resumeActions.setModel,
  getInterventions: interventionsActions.getInterventions,
  setPrice: resumeActions.setPrice,
  getInterventionsDemand: demandActions.getInterventionsDemand,
};

const connectedDemandeFormP1 = connect(mapState, actionCreators)(DemandeFormP1);
export { connectedDemandeFormP1 as DemandeFormP1 };
