import { settingsConstants } from '../constants';

const initialState = {
    settings: null,
    loading: false,
}

export function settings(state = initialState, action) {
  switch (action.type) {
    case settingsConstants.SETTINGS_REQUEST:
        return{
            ...state,
            loading: true
        }
    case settingsConstants.SETTINGS_SUCCESS:
        return{
            ...state,
            settings: action.settings,
            loading: false
        }
    case settingsConstants.SETTINGS_FAILURE:
        return {
            ...state,
            loading: false
        }
    default:
        return state
  }
}