import { contactConstants } from '../constants';

const initialState = { loading: false}

export function contact(state = initialState, action) {
  switch (action.type) {
    case contactConstants.CONTACT_REQUEST:
      return { loading: false };
    case contactConstants.CONTACT_SUCCESS:
      return { loading: true };
    case contactConstants.CONTACT_FAILURE:
      return { loading: false };
    default:
      return state
  }
}