import { orderConstants } from '../constants';
import { orderService } from '../services';
import { alertActions } from '.';

export const orderActions = { 
    getOrders,
    getOrder,
    init
};

 function getOrders() {
    return dispatch => {
        dispatch(request());
        orderService.getOrders()
            .then(
                orders => { 
                    dispatch(success(orders.data));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: orderConstants.SET_LOADING } }
    function success(orders) { return { type: orderConstants.SET_ORDERS, orders } }
    function failure() { return { type: orderConstants.ORDERS_FAILED } }
}

function getOrder(id) {
    return dispatch => {
        dispatch(request());
        orderService.getOrder(id)
            .then(
                order => { 
                    dispatch(success(order.data));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: orderConstants.SET_ORDER_LOADING } }
    function success(order) { return { type: orderConstants.SET_ORDER, order } }
    function failure() { return { type: orderConstants.ORDER_FAILED } }
}

function init() { return { type: orderConstants.INIT } }