import React, { useEffect } from "react";
import "./index.css";
import InfoCard from "./InfoCard";
import CommandesCard from "./CommandesCard";
import { connect } from "react-redux";
import { userActions, orderActions } from "../../actions";
import AppDownloadV2 from "../../components/AppDownloadV2";
//import ReactPixel from 'react-facebook-pixel';

function ProfilePage(props) {
  useEffect(() => {
    if (performance.navigation.type === 1) {
      props.getOrders();
      props.getUserInfo();
    }
    //eslint-disable-next-line
  }, [window.performance]);

  useEffect(() => {
    /*window.addEventListener('storage', () => {
      props.getOrders()
      props.getUserInfo()
    });*/
    props.getOrders();
    props.getUserInfo();
    // ReactPixel.pageView()
    //eslint-disable-next-line
  }, []);

  const handleUndefinedField = field => {
    if (field) return field;
    else return "";
  };

  return (
    <div>
      <div className='espace-background'>
        <div className='espace-background-card'>
          <div>
            <div className='info-name'>
              <h1>Bonjour {props.user ? props.user.firstname : ""} 👋</h1>
              <p>
                Retrouvez ici toutes les informations liées à votre compte et
                vos commandes.
              </p>
            </div>
            <div className='espace-cards'>
              {props.user ? (
                <InfoCard
                  adresse={
                    handleUndefinedField(props.user.address1) +
                    " " +
                    handleUndefinedField(props.user.zip) +
                    " " +
                    handleUndefinedField(props.user.address2) +
                    ", " +
                    handleUndefinedField(props.user.city)
                  }
                  email={props.user.email}
                  phoneNumber={props.user.phone}
                  onClick={props.logout}
                />
              ) : null}
              <CommandesCard orders={props.orders} />
            </div>
          </div>
        </div>
      </div>
      <AppDownloadV2 />
    </div>
  );
}

function mapState(state) {
  const { user } = state.users;
  const { authentication } = state;
  const { orders, loading } = state.order;
  return { user, orders, loading, authentication };
}

const actionCreators = {
  logout: userActions.logout,
  getOrders: orderActions.getOrders,
  getUserInfo: userActions.getUserInfo,
};

const connectedProfilePage = connect(mapState, actionCreators)(ProfilePage);
export { connectedProfilePage as ProfilePage };
