import { slotsConstants } from '../constants';
import { slotsService } from '../services';
import { alertActions } from '.';

export const slotsActions = {
    getSlots,
    initSlots
};

function getSlots() {
    return dispatch => {
        dispatch(request());
        slotsService.getSlots()
            .then(
                slots => { 
                    dispatch(success(slots.data));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: slotsConstants.SET_LOADING } }
    function success(slots) { return { type: slotsConstants.SET_SLOTS, slots } }
    function failure() { return { type: slotsConstants.SLOTS_FAILED } }
}

function initSlots() {
    return { type: slotsConstants.INIT };
}