import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from '.';
import { history } from '../helpers';

export const userActions = {
    login,
    forgottenPassword,
    resetPassword,
    getUserInfo,
    logout,
    register,
    getAll,
    delete: _delete
};

function login(email, password, redirect) {
    return dispatch => {
        dispatch(request({ email }));
        userService.login(email, password)
            .then(
                user => { 
                    dispatch(success(user));
                    if(redirect) history.push('/espace');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function forgottenPassword(email) {
    return dispatch => {
        dispatch(request({ email }));
        userService.forgottenPassword(email)
            .then(
                response => { 
                    dispatch(success());
                    dispatch(alertActions.success(response.message.toString()));
                    //history.push('/forgotPassword');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOTTEN_PASSWORD_REQUEST, user } }
    function success() { return { type: userConstants.FORGOTTEN_PASSWORD_SUCCESS } }
    function failure(error) { return { type: userConstants.FORGOTTEN_PASSWORD_FAILURE, error } }
}

function resetPassword(resetToken,newPassword) {
    return dispatch => {
        dispatch(request());
        userService.resetPassword(resetToken,newPassword)
            .then(
                response => { 
                    dispatch(success());
                    dispatch(alertActions.success(response.message.toString()));
                    //history.push('/forgotPassword');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.RESET_PASSWORD_REQUEST } }
    function success() { return { type: userConstants.RESET_PASSWORD_SUCCESS } }
    function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user, alert) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => { 
                    dispatch(authSuccess(user));
                    dispatch(success());
                    //history.push('/espace');
                    if(alert) dispatch(alertActions.success('Inscription réussi'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.REGISTER_REQUEST } }
    function success() { return { type: userConstants.REGISTER_SUCCESS } }
    function authSuccess(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getUserInfo() {
    return dispatch => {
        dispatch(request());
        userService.getUserInfo()
            .then(
                user => { 
                    dispatch(success(user.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.USER_INFO_REQUEST } }
    function success(user) { return { type: userConstants.USER_INFO_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_INFO_FAILURE, error } }
}



function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}