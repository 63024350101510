import './index.css';
import React from 'react';
import { ReviewsItems } from "../../config/staticData";
import { useHistory } from 'react-router-dom';
import StarSvg from "../../assets/icons/star.svg";
import RightArrowSvg from "../../assets/svgFiles/rightArrow";
import Button from 'react-bootstrap/Button';
import { socialMediaUrl } from "../../config";

export default function ProductReviews({showBookingBtn = true}) {
    const history = useHistory()

    const goToBookingPage = () => history.push('/demande')

    const setRating = (nb) =>{
        let stars = []
        for(var i=0; i < nb; i++){
            stars.push(i)
        }
        return  <div className='rating-container' style={{display: "flex", flexDirection: 'row'}}>
                    {stars.map((s,index)=><div key={index} style={{backgroundImage: `url(${StarSvg})`}} />)}
                </div>
    }

    const openGoogleReviews = (e) => {
        e.preventDefault();
        window.open(socialMediaUrl.googleReviews,'_blank')
    } 
    return(
            <div className="product-review-container">
                <h1>Notre équipe a pris soin de leurs appareils</h1>
                <b onClick={openGoogleReviews}>Voir d'autres avis</b>
                <div className='review-scroll-block product-review-scroll-block'>
                    <div className='review-scroll'>
                        {ReviewsItems.map((item, index) => (
                            <div className='review-scroll-item product-review-scroll-item' key={index}>
                                <div>
                                    <h3>{item.name}</h3>
                                    {setRating(item.rating)}       
                                    <p>{item.text}</p>
                                    <small>{item.date}</small>
                                </div>
                            </div>
                        ))}
                    </div>  
                    {
                        showBookingBtn &&
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: 40}}>
                            <Button className="btn-gradient-2" onClick={goToBookingPage}>
                                <u>{("Réserver une Réparation").toUpperCase()}</u>
                                <RightArrowSvg/>
                            </Button>
                        </div>
                    }
                </div>
            </div>
    )
}