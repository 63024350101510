import { useEffect } from "react";
import { useLocation } from "react-router";

const _scrollToTop = () => {
    document.querySelector('body').scrollTo({top:0, left:0,behavior: 'smooth'});
  }

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    _scrollToTop()
    window.scrollTo({top:0, left:0,behavior: 'smooth'});
  }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;