import React, {useContext}  from 'react';
import './HomeRepDom.css';
import HomeRepDomImage from "../../assets/images/home-rep-dom.png";
import HomeRepDomImageMobile from "../../assets/images/home-rep-dom-mobile.png";
import { SmallScreenContext } from "../../helpers";

export default function HomeRepDom() {
    const context = useContext(SmallScreenContext);
   
  return (
    <div className="homeV2-repdom-container" style={{backgroundImage:  context.isSmallScreen? null: `url(${HomeRepDomImage})`}}>
        <div className="homeV2-repdom-block">
            <h1>Réparation à domicile en <span>atelier mobile</span></h1>
            <div className="homeV2-repdom-mobile" style={{backgroundImage: `url(${HomeRepDomImageMobile})`}} />
            <p>Nos techniciens interviennent avec un atelier mobile entièrement équipé à l'adresse de votre choix, pour réparer vos appareils en 20 minutes.</p>
            <p>Prise de rendez-vous en 2mn en ligne.</p>
        </div>
    </div>
  );
}

