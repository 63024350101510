import RepBetterieImage from "../assets/images/rep-batterie.png";
import RepCameraImage from "../assets/images/rep-camera.png";
import RepCarteMImage from "../assets/images/rep-carteM.png";
import RepChargeImage from "../assets/images/rep-charge.png";
import RepEcranImage from "../assets/images/rep-ecran.png";
import RepHpImage from "../assets/images/rep-hp.png";
import RepKitImage from "../assets/images/rep-kit.png";
import RepMicrophoneImage from "../assets/images/rep-microphone.png";
import RepResetImage from "../assets/images/rep-reset.png";
import RepWifiImage from "../assets/images/rep-wifi.png";

export const PRODUCT_ROUTES = {
    path:"remplacement",
    children: [
        { text: <h1>Nous remplaçons<br/>votre haut parleur<br/>en <span>30 minutes</span></h1>, image: RepHpImage, path: "haut_parleur" },
        { text: <h1>Nous remplaçons<br/>votre caméra<br/>en <span>30 minutes</span></h1>, image: RepCameraImage, path: "camera" },
        { text: <h1>Nous réparons<br/>tous vos problèmes<br/>de <span> carte mère</span></h1>, image: RepCarteMImage, path: "carte_mere" },
        { text: <h1>Nous réparons tous<br/>vos problèmes de<br/><span>réseau et Wifi</span></h1>, image: RepWifiImage, path: "reseau_wifi" },
        { text: <h1>On remet tous<br/>vos appareils en état<br/>en <span>30 minutes</span></h1>, image: RepResetImage, path: "remise_en_etat" },
        { text: <h1>Nous remplaçons<br/>votre écran<br/>en <span>30 minutes</span></h1>, image: RepEcranImage, path: "ecran" },
        { text: <h1>Nous remplaçons<br/>votre batterie<br/>en <span>30 minutes</span></h1>, image: RepBetterieImage, path: "batterie" },
        { text: <h1>Nous remplaçons<br/>votre microphone<br/>en <span>30 minutes</span></h1>, image: RepMicrophoneImage, path: "microphone" },
        { text: <h1>Nous remplaçons<br/>votre écouteur<br/>en <span>30 minutes</span></h1>, image: RepKitImage, path: "ecouteur" },
        { text: <h1>Nous réparons<br/>votre problème de charge<br/>en <span>30 minutes</span></h1>, image: RepChargeImage, path: "charge" },
    ]
}