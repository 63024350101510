import "./index.css";
import { externals } from '../../config'

const imagesUrl = externals.phoneImagesUrl
const imageDefault = externals.defaultPhoneImage
  
function ImageSelect(props) {
    const images = props.images;
    
    const Image = (prop) => {
        let imageUrl = imagesUrl + prop.image.image
        let condition = props.type? props.selectedId === prop.image.id: props.selectedId === prop.image.uuid
        return (
            <div className='image-mark-container'>
                <img 
                    className='image-mark' 
                    src={imageUrl? imageUrl: imageDefault} 
                    alt={prop.image.name}
                    style={{
                        opacity: condition ? 1: props.type? 0.5 : 0.3, 
                        filter: condition ? 'grayscale(0%)': props.type? 'grayscale(20%)': 'grayscale(80%)',
                        borderColor: condition? null: '#7f7f7f'
                    }}
                    onClick={() => props.onSelect(props.type?prop.image.id: prop.image.uuid)}
                />
                {props.type && 
                    <p style={{color: condition? '#6232a0': '#a0a0a0', fontWeight: condition? '600': '500'}}
                    >{prop.image.name}</p>}
            </div>
        )
    }

    const listImages = () => {
        return(
            images.map((image) =>
            // Correct! Key should be specified inside the array.
            <Image key={props.type? image.id: image.uuid} image={image} />
            )
        )
    }

    return (
        <div className="container-images">
            {images && images.length ? listImages() : <h6>Disponible prochainement ... </h6>}
        </div>
    );
  }

  export default ImageSelect