import { useState, useEffect, createRef } from "react";
import "./index.css";
import { useHistory, useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonBStrap from "react-bootstrap/Button";
import { Spinner } from "evergreen-ui";
import { connect } from "react-redux";
import { contactActions } from "../../actions";
import { SmallScreenContext } from "../../helpers";
import FooterV2 from "../../components/FooterV2";
import AppDownloadV2 from "../../components/AppDownloadV2";
import ReCAPTCHA from "react-google-recaptcha";
import { regex } from "../../config";
//import ReactPixel from 'react-facebook-pixel';

const reg = regex.email;

const recaptchaRef = createRef();

const minTextcharacter = 2;
const minMessageCharacter = 50;

function ContactPage(props) {
  const location = useLocation();
  const [dialog, setDialog] = useState({ open: false, title: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    nom: location.state?.nom ? location.state.nom : "",
    prenom: location.state?.prenom ? location.state.prenom : "",
    subject: location.state?.sujet ? location.state.sujet : "",
    tel: "",
    message: "",
  });
  const [errorMsg, setErrMsg] = useState({
    email: "",
    nom: "",
    prenom: "",
    subject: "",
    tel: "",
    message: "",
  });
  const [valide, setValide] = useState({
    email: false,
    nom: location.state?.nom ? true : false,
    prenom: location.state?.prenom ? true : false,
    subject: location.state?.sujet ? true : false,
    tel: false,
    message: false,
  });
  const [captachErrMsg, setCaptachErrMsg] = useState("");

  useEffect(() => {
    //ReactPixel.pageView()
  }, []);

  const handleChange = prop => event => {
    const { value } = event.target;
    setValues({
      user: { ...values.user, [prop]: value },
      errorMsg: { ...values.errorMsg },
      valide: { ...values.valide },
    });
    if (["nom", "prenom", "subject"].indexOf(prop) > -1)
      textValidate(prop, value);
    else if (prop === "email") emailValidate(value);
    else if (prop === "tel") phoneValidate(value);
    else if (prop === "message") messageValidate(value);
  };

  const textValidate = (prop, value) => {
    if (value.length < minTextcharacter) {
      setValues({ ...values, [prop]: "" });
      setValide({ ...valide, [prop]: false });
      setErrMsg({ ...errorMsg, [prop]: "Votre texte est trop court" });
    } else {
      setValues({ ...values, [prop]: value });
      setValide({ ...valide, [prop]: true });
      setErrMsg({ ...errorMsg, [prop]: "" });
    }
  };

  const emailValidate = text => {
    if (reg.test(text) === false) {
      setValues({ ...values, email: "" });
      setValide({ ...valide, email: false });
      setErrMsg({ ...errorMsg, email: "Adresse e-mail invalide" });
    } else {
      setValues({ ...values, email: text });
      setValide({ ...valide, email: true });
      setErrMsg({ ...errorMsg, email: "" });
    }
  };

  const phoneValidate = number => {
    if (!regex.phone.test(number)) {
      setValues({ ...values, tel: "" });
      setValide({ ...valide, tel: false });
      setErrMsg({ ...errorMsg, tel: "Numéro de téléphone invalide" });
    } else {
      setValues({ ...values, tel: number });
      setValide({ ...valide, tel: true });
      setErrMsg({ ...errorMsg, tel: "" });
    }
  };

  const messageValidate = text => {
    if (text.length < minMessageCharacter) {
      setValues({ ...values, message: "" });
      setValide({ ...valide, message: false });
      setErrMsg({
        ...errorMsg,
        message:
          "Votre message doit contenir au moins " +
          minMessageCharacter +
          " caractères",
      });
    } else {
      setValues({ ...values, message: text });
      setValide({ ...valide, message: true });
      setErrMsg({ ...errorMsg, message: "" });
    }
  };

  const textValidate2 = () => {
    const { nom, prenom, subject } = values;
    for (var value in [nom, prenom, subject]) {
      if (value.length < minTextcharacter) {
        setValues({ ...values, [value.toString()]: "" });
        setValide({ ...valide, [value.toString()]: false });
        setErrMsg({
          ...errorMsg,
          [value.toString()]: "Votre texte est trop court",
        });
      } else {
        setValues({ ...values, [value.toString()]: value });
        setValide({ ...valide, [value.toString()]: true });
        setErrMsg({ ...errorMsg, [value.toString()]: "" });
      }
    }
  };

  const allTrue = obj => {
    let first = true;
    for (var o in obj)
      if (first) first = false;
      else if (!obj[o]) return false;

    return true;
  };

  const handleSubmit = event => {
    const recaptchaValue = recaptchaRef.current.getValue();
    event.preventDefault();
    if (allTrue(valide)) {
      if (recaptchaValue) {
        setLoading(true);
        setErrMsg({
          email: "",
          nom: "",
          prenom: "",
          subject: "",
          tel: "",
          message: "",
        });

        props.sendContact(values);
      } else {
        setCaptachErrMsg("Cliquez sur la captcha");
      }
    } else {
      if (!valide.message) messageValidate(values.message);
      else if (!valide.tel) phoneValidate(values.tel);
      else if (!valide.email) emailValidate(values.email);
      else textValidate2();
    }
  };

  useEffect(() => {
    if (props.alert.type === "alert-danger") {
      setDialog({
        open: true,
        title: "Erreur d'envoi",
        message: props.alert.message,
      });
      setLoading(false);
    }
    if (props.alert.type === "alert-success") {
      setDialog({
        open: true,
        title: "💌  Message envoyé",
        message:
          "Notre équipe s'occupera de tout, vous pouvez continuer à travailler tranquillement.",
      });
      setLoading(false);
    }
  }, [props.alert]);

  const history = useHistory();
  const handleClose = () => {
    setDialog({ open: false, title: "" });
    if (props.alert.type === "alert-success") history.push("/");
  };

  return (
    <SmallScreenContext.Consumer>
      {context => (
        <div className='contact-container'>
          <div className='espace-background' style={{ height: "100%" }}>
            <div className='espace-background-card'>
              <div style={{ paddingTop: context.isSmallScreen ? "3%" : 20 }} />
              <form className='contact' onSubmit={handleSubmit}>
                <h2>Écrivez-nous</h2>
                <FormControl className='textField'>
                  <TextField
                    id='outlined-error-helper-text1'
                    label='Prénom'
                    defaultValue={
                      location.state?.prenom ? location.state.prenom : null
                    }
                    //error
                    //helperText="Incorrect entry."
                    type='text'
                    onChange={handleChange("prenom")}
                    variant='outlined'
                    color={valide.prenom ? "primary" : "secondary"}
                  />
                </FormControl>
                <em>{errorMsg.prenom}</em>
                <FormControl className='textField'>
                  <TextField
                    id='outlined-error-helper-text2'
                    label='Nom'
                    //error
                    //helperText="Incorrect entry."
                    defaultValue={
                      location.state?.nom ? location.state.nom : null
                    }
                    type='text'
                    onChange={handleChange("nom")}
                    variant='outlined'
                    color={valide.nom ? "primary" : "secondary"}
                  />
                </FormControl>
                <em>{errorMsg.nom}</em>
                <FormControl className='textField'>
                  <TextField
                    id='outlined-error-helper-text3'
                    label='E-mail'
                    //error
                    //helperText="Incorrect entry."
                    type='email'
                    onChange={handleChange("email")}
                    variant='outlined'
                    color={valide.email ? "primary" : "secondary"}
                  />
                </FormControl>
                <em>{errorMsg.email}</em>
                <FormControl className='textField'>
                  <TextField
                    id='outlined-error-helper-text4'
                    label='Téléphone'
                    //error
                    //helperText="Incorrect entry."
                    type='tel'
                    onChange={handleChange("tel")}
                    variant='outlined'
                    color={valide.tel ? "primary" : "secondary"}
                  />
                </FormControl>
                <em>{errorMsg.tel}</em>
                <FormControl className='textField'>
                  <TextField
                    id='outlined-error-helper-text5'
                    label='Sujet'
                    //error
                    //helperText="Incorrect entry."
                    defaultValue={
                      location.state?.sujet ? location.state.sujet : null
                    }
                    type='subject'
                    onChange={handleChange("subject")}
                    variant='outlined'
                    color={valide.subject ? "primary" : "secondary"}
                  />
                </FormControl>
                <em>{errorMsg.subject}</em>
                <FormControl className='textField'>
                  <TextField
                    id='outlined-error-helper-text6'
                    label='message'
                    placeholder='Votre message'
                    multiline
                    minRows={8}
                    type='text'
                    onChange={handleChange("message")}
                    variant='outlined'
                    color={valide.message ? "primary" : "secondary"}
                  />
                </FormControl>
                <em>{errorMsg.message}</em>
                <div>
                  <ReCAPTCHA
                    className='captcha'
                    size='normal'
                    sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                    ref={recaptchaRef}
                    onChange={() => setCaptachErrMsg("")}
                    hl='fr'
                  />
                  <em>{captachErrMsg}</em>
                </div>
                {loading ? <Spinner /> : null}
                <div style={{ flexDirection: "row", flexWrap: "nowrap" }}>
                  <ButtonBStrap className='btn-gradient' onClick={handleSubmit}>
                    <u>Envoyer mon message</u>
                  </ButtonBStrap>
                </div>
                <Dialog
                  fullWidth={true}
                  open={dialog.open}
                  onClose={handleClose}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'
                >
                  <DialogTitle id='alert-dialog-title'>
                    {dialog.title}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                      {dialog.message}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <ButtonBStrap
                      className='btn-gradient'
                      onClick={handleClose}
                      style={{ margin: 7 }}
                    >
                      <u>Ok</u>
                    </ButtonBStrap>
                  </DialogActions>
                </Dialog>
              </form>
            </div>
          </div>
          <AppDownloadV2 />
          <FooterV2 />
        </div>
      )}
    </SmallScreenContext.Consumer>
  );
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  sendContact: contactActions.sendContact,
};

const connectedContactPage = connect(mapState, actionCreators)(ContactPage);
export { connectedContactPage as ContactPage };
