export const manufacturersConstants = {
    SET_MANUFACTURES: 'SET_MANUFACTURES_MANUFACTURES',
    SET_LOADING: 'SET_LOADING_MANUFACTURES',
    SET_MANUFACTURES_FAILED: 'SET_MANUFACTURES_FAILED',

    SET_MODELS: 'SET_MANUFACTURES_MODELS',
    SET_MODELS_LOADING: 'SET_MODELS_LOADING',
    SET_MODELS_FAILED: 'SET_MODELS_FAILED',
    INIT_MODELS: 'INIT_MANUFACTURES_MODELS',

    SET_MODEL_TYPES: 'SET_MODEL_TYPES',
    SET_MODEL_TYPES_LOADING: 'SET_MODEL_TYPES_LOADING',
    SET_MODEL_TYPES_FAILED: 'SET_MODEL_TYPES_FAILED',
    INIT_SET_MODEL_TYPES: 'INIT_SET_MODEL_TYPES',

    SET_MANUFACTURES_WITH_MODELS: 'SET_MANUFACTURES_WITH_MODELS',
    SET_MANUFACTURES_WITH_MODELS_LOADING: 'SET_MANUFACTURES_WITH_MODELS_LOADING',
    SET_MANUFACTURES_WITH_MODELS_FAILED: 'SET_MANUFACTURES_WITH_MODELS_FAILED',
    
    INIT_MANUFACTURES: 'INIT_MANUFACTURES'
};
