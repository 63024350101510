import "./index.css";
import { useEffect, useState, useCallback } from "react";

import { Route, NavLink, Link, Redirect } from "react-router-dom";
import { history, SmallScreenContext } from "../helpers";
import { HomePageV2 } from "./HomeV2";
import { LoginPage, ResetPassword } from "./Login";
import { RegisterPage } from "./Register";
import { MissionPage } from "./Mission";
import { ContactPage } from "./Contact";
import { ProfilePage } from "./Profile";
import { DemandePage } from "./Demande";
import { ReparateurPage } from "./Reparateur";
import { FaqPage } from "./Faq";
import { CguPage } from "./cgu";
import {
  NousRejoindrePage,
  NousRejoindreEntreprisePage,
} from "./NousRejoindre";
import { ResetPassword as PasswordReset } from "./ResetPassword";
import { DemandeFinished } from "./Demande/Forms/DemandeFinished";
import { AddressUnavailable } from "./AddressUnavailable";
import { PrivacyPolicyPage } from "./PrivacyPolicy";
import { EntreprisePageV2 } from "./EntrepriseV2";
import { RepairPage } from "./Repair";
import { PorductsPage } from "./Products";
import {
  alertActions,
  manufacturersActions,
  demandActions,
  userActions,
  orderActions,
  resumeActions,
  slotsActions,
  discountActions,
} from "../actions";
import { connect } from "react-redux";
import { stack as Menu } from "react-burger-menu";
import ScrollToTop from "../helpers/ScrollToTop";
import { PRODUCT_ROUTES } from "../config/api";
import ChangePassword from "./ChangePassword/ChangePassword";
import UpdateUserInfos from "./UpdateUserInfos/UpdateUserInfos";
import HideComponentIfWebViewTrue from "../components/HideComponentIfWebViewTrue";
import ApplicationMobile from "./ApplicationMobile/ApplicationMobile";
import ApplicationReparateur from "./ApplicationReparateur/ApplicationReparateur";
import HeaderNewVersion from "../components/HeaderNewVersion/HeaderNewVersion";
import FooterNewVersion from "../components/FooterNewVersion/FooterNewVersion";

const NavBarRoutes = props => (
  <div className='content'>
    <Route exact path='/' component={HomePageV2} />
    <Route path='/mission' component={MissionPage} />
    <Route
      path='/application-mobile-diagnostic-neero'
      component={ApplicationMobile}
    />
    <Route
      path='/application-mobile-reparateur-neero'
      component={ApplicationReparateur}
    />
    <Route path='/contact' component={ContactPage} />
    <Route
      path='/espace'
      component={localStorage.getItem("user") ? ProfilePage : LoginPage}
    />
    <Route path='/login/resetpassword' component={ResetPassword} />
    <Route path='/register' component={RegisterPage} />
    <Route path='/reparateur' component={ReparateurPage} />
    <Route path='/faq' component={FaqPage} />
    <Route path='/cgu' component={CguPage} />
    <Route path='/reparateur-join' component={NousRejoindrePage} />
    <Route path='/address-unavailable' component={AddressUnavailable} />
    <Route path='/privacy-policy' component={PrivacyPolicyPage} />
    <Route exact path='/demande' component={DemandePage} />
    {props.addDemandeFinishedRoute ? (
      <Route exact path='/demandefinished' component={DemandeFinished} />
    ) : null}
    <Route path='/resetpassword/:id' children={<PasswordReset />} />
    <Route path='/entreprise' component={EntreprisePageV2} />
    <Route path='/entreprise-join' component={NousRejoindreEntreprisePage} />
    <Route path='/remise-en-etat-telephone' component={RepairPage} />

    <Route
      exact
      path='/reparation-haut_parleur'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-haut_parleur",
            search: props.location.search,
          }}
        />
      )}
    />
    <Route
      exact
      path='/reparation-camera'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-camera",
            search: props.location.search,
          }}
        />
      )}
    />
    <Route
      exact
      path='/reparation-reset'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-remise_en_etat",
            search: props.location.search,
          }}
        />
      )}
    />
    <Route
      exact
      path='/reparation-reseau_wifi'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-reseau_wifi",
            search: props.location.search,
          }}
        />
      )}
    />
    <Route
      exact
      path='/reparation-ecran'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-ecran",
            search: props.location.search,
          }}
        />
      )}
    />
    <Route
      exact
      path='/reparation-ecouteur'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-ecouteur",
            search: props.location.search,
          }}
        />
      )}
    />
    <Route
      exact
      path='/reparation-microphone'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-microphone",
            search: props.location.search,
          }}
        />
      )}
    />
    <Route
      exact
      path='/reparation-batterie'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-batterie",
            search: props.location.search,
          }}
        />
      )}
    />
    <Route
      exact
      path='/reparation-carte_mere'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-carte_mere",
            search: props.location.search,
          }}
        />
      )}
    />
    <Route
      exact
      path='/reparation-charge'
      render={props => (
        <Redirect
          to={{
            pathname: "/remplacement-charge",
            search: props.location.search,
          }}
        />
      )}
    />

    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[0].path}`}
      component={PorductsPage}
    />
    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[1].path}`}
      component={PorductsPage}
    />
    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[2].path}`}
      component={PorductsPage}
    />
    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[3].path}`}
      component={PorductsPage}
    />
    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[4].path}`}
      component={PorductsPage}
    />
    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[5].path}`}
      component={PorductsPage}
    />
    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[6].path}`}
      component={PorductsPage}
    />
    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[7].path}`}
      component={PorductsPage}
    />
    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[8].path}`}
      component={PorductsPage}
    />
    <Route
      path={`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[9].path}`}
      component={PorductsPage}
    />

    <Route path={`/change-password`} component={ChangePassword} />
    <Route path='/update-user-infos' component={UpdateUserInfos} />
  </div>
);

function Routes(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMenuOpen, setToggleMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (performance.navigation.type === 1) {
      props.clearAlerts();
    }
    //eslint-disable-next-line
  }, [window.performance]);

  const Header = () => (
    <ul className='header'>
      <li className='navbar-home'>
        <Link exact='true' to='/'>
          <div className='headerLogo' />
        </Link>
      </li>
      {/* <li><NavLink className="navbar" to="/mission"><u>Notre mission</u></NavLink></li> */}
      <li>
        <NavLink className='navbar' to='/entreprise'>
          <u>Entreprises</u>
        </NavLink>
      </li>
      <li>
        <NavLink className='navbar' to='/contact'>
          <u>Contact</u>
        </NavLink>
      </li>
      <li onClick={() => getUserAndOrder()}>
        <NavLink className='navbar' to='/espace'>
          <u>Mon espace personnel</u>
        </NavLink>
      </li>
      <li onClick={() => hideNavBarMenu()}>
        <NavLink className='navbar' to='/demande'>
          <u>Voir les disponibilités</u>
        </NavLink>
      </li>
    </ul>
  );

  const MenuHeader = props => (
    <Menu
      {...props}
      width={window.innerWidth < 400 ? "80%" : "300px"}
      pageWrapId={"page-wrap"}
    >
      {/* <b onClick={() => closeMenu()} className="menu-item" ><NavLink className="navbar" to="/mission"><u>Notre mission</u></NavLink></b> */}
      <b onClick={() => closeMenu()} className='menu-item'>
        <NavLink className='navbar' to='/entreprise'>
          <u>Entreprises</u>
        </NavLink>
      </b>
      <b onClick={() => closeMenu()} className='menu-item'>
        <NavLink className='navbar' to='/contact'>
          <u>Contact</u>
        </NavLink>
      </b>
      <b onClick={() => closeMenuAndGetUser()} className='menu-item'>
        <NavLink className='navbar' to='/espace'>
          <u>Mon espace personnel</u>
        </NavLink>
      </b>
      <b onClick={() => closeMenuAndHideNavBarMenu()} className='menu-item'>
        <NavLink className='navbar' to='/demande'>
          <u>Voir les disponibilités</u>
        </NavLink>
      </b>
    </Menu>
  );

  useEffect(() => {
    history.listen(() => {
      if (props.orderSent) {
        props.initDemand();
        props.initResume();
        props.initDiscount();
        props.initCoupon();
      }
    });
    const unlisten = history.listen(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      document
        .querySelector("body")
        .scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
    return () => {
      unlisten();
    };
    //eslint-disable-next-line
  }, []);

  const closeMenuAndGetUser = () => {
    setToggleMenuOpen(false);
    getUserAndOrder();
    forceUpdate();
  };

  useEffect(() => {
    getUserAndOrder();
    //eslint-disable-next-line
  }, [props.authentication]);

  const getUserAndOrder = () => {
    if (localStorage.getItem("user")) {
      props.getUserInfo();
      props.getOrders();
    }
  };

  const closeMenuAndHideNavBarMenu = () => {
    if (props.appointmentSlots) checkSlotsDate();
    setToggleMenuOpen(false);
    //setShowMenu(false)
    props.getModelTypes();
    props.getManufacturers();
    //props.initDemand()
  };

  const hideNavBarMenu = () => {
    if (props.appointmentSlots) checkSlotsDate();
    setShowMenu(false);
    props.getModelTypes();
    props.getManufacturers();
    //props.initDemand()
  };

  const checkSlotsDate = () => {
    const nowDate = new Date();
    const oldDate = new Date(props.date);
    if (
      Math.abs(nowDate.getFullYear() - oldDate.getFullYear()) > 0 ||
      Math.abs(nowDate.getMonth() - oldDate.getMonth()) > 0 ||
      Math.abs(nowDate.getDate() - oldDate.getDate()) > 0
    ) {
      props.getSlots();
      props.setDate(null);
      props.setSlots(null);
      props.setPage(3);
    }
  };

  const showNavBarMenu = () => {
    setShowMenu(true);
  };

  /*const toggleMenu = ()  => {
    setToggleMenuOpen(!isMenuOpen)
  }*/

  const closeMenu = () => {
    setToggleMenuOpen(false);
    forceUpdate();
  };

  const handleStateChange = state => {
    if (state.isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = "scroll";
      document.body.style.position = "static";
    }
  };
  useEffect(() => {
    // crisp chat
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    // axeptio
    window.axeptioSettings = {
      clientId: process.env.REACT_APP_AXEPTIO_API_ID,
      cookiesVersion: "reparateur-base",
    };
    (function (d, s) {
      var t = d.getElementsByTagName(s)[0],
        e = d.createElement(s);
      e.async = true;
      e.src = "//static.axept.io/sdk-slim.js";
      t.parentNode.insertBefore(e, t);
    })(document, "script");

    // hotjar
    (function (h, o, t, j, a, r) {
      t = "//static.hotjar.com/c/hotjar-";
      j = ".js?sv=";
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = {
        hjid: process.env.REACT_APP_HOTJAR_API_ID,
        hjsv: process.env.REACT_APP_HOTJAR_API_SV,
      };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document);
    //})(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 900px)");

    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  // const event = ({ action, label }) => {
  //   window.gtag('event', action, {
  //     event_label: label,
  //   })
  // }

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      props.clearAlerts();
    });
    // event('pageview', '0QofCLPnzloDEJyqmMAB')
    /* window.dataLayer.push({
      event: 'pageView',
    });*/
    //eslint-disable-next-line
  }, []);

  //useEffect(() => {alert = props.alert}, [alert])

  return (
    <SmallScreenContext.Provider
      value={{
        isSmallScreen: isSmallScreen,
        showMenu: showNavBarMenu,
        hideMenu: hideNavBarMenu,
      }}
    >
      <div className='App-container'>
        <div className='navbar-container'>
          {/* <BrowserRouter
            //history={history}
            //basename="/bachir"
            //forceRefresh={true}
            onUpdate={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              document
                .querySelector("body")
                .scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          > */}
          <ScrollToTop>
            {showMenu ? (
              isSmallScreen ? (
                <HideComponentIfWebViewTrue>
                  {/* <div>
                    <div className='menu-nav-home'>
                      <div style={{ marginLeft: 20 }}>
                        <NavLink
                          className='navbar'
                          onClick={() => closeMenu()}
                          exact
                          to='/'
                        >
                          <div className='headerLogo' />
                        </NavLink>
                      </div>
                      <MenuHeader
                        isOpen={isMenuOpen}
                        onStateChange={state => handleStateChange(state)}
                        pageWrapId={"page-wrap"}
                        outerContainerId={"App"}
                      />
                    </div>
                  </div> */}
                  <HeaderNewVersion />
                </HideComponentIfWebViewTrue>
              ) : (
                <HideComponentIfWebViewTrue>
                  {/* <Header /> */}
                  <HeaderNewVersion />
                </HideComponentIfWebViewTrue>
              )
            ) : null}
            <NavBarRoutes addDemandeFinishedRoute={props.orderSent} />
            <FooterNewVersion />
          </ScrollToTop>
          {/* </BrowserRouter> */}
        </div>
      </div>
    </SmallScreenContext.Provider>
  );
}

function mapState(state) {
  const { alert, authentication } = state;
  const { appointmentSlots, date, orderSent } = state.demand;
  return { alert, authentication, appointmentSlots, date, orderSent };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  getManufacturers: manufacturersActions.getManufacturers,
  getModelTypes: manufacturersActions.getModelTypes,
  getUserInfo: userActions.getUserInfo,
  getOrders: orderActions.getOrders,
  setSlots: demandActions.setSlots,
  setDate: resumeActions.setDate,
  setPage: demandActions.setPage,
  getSlots: slotsActions.getSlots,
  initDemand: demandActions.init,
  initResume: resumeActions.init,
  initDiscount: discountActions.initDiscount,
  initCoupon: discountActions.initCoupon,
};

export default connect(mapState, actionCreators)(Routes);
