import React, { useState, useEffect} from "react";
import "./index.css";
import { useHistory, useParams } from 'react-router-dom';
import IconButtonMUi from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import ButtonBStrap from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Spinner } from 'evergreen-ui'
import { connect } from 'react-redux';
import { userActions } from '../../actions';
//import ReactPixel from 'react-facebook-pixel';
//import { regex } from "../../config";
 
//const reg = regex.email

const useStyles = makeStyles((theme) => ({
    margin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  }));
  
function ResetPassword(props) {
    const classes = useStyles();
    const [values, setValues] = useState({
        newPassword: '',
        showNewPassword: false,
        newPasswordErrorMsg: '',
        newPasswordConfirmation: '',
        showNewPasswordConfirmation: false,
        newPasswordConfirmationErrorMsg: '',
        newPasswordValide: false,
        newPasswordConfirmationValide: false,
        openErrDialog: false,
        openSuccessDialog: false,
        loading: false
    })

    useEffect(() => {
      //  ReactPixel.pageView()
    },[])
    
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === 'newPassword') newPasswordValidate(event.target.value)
        else if (prop === 'newPasswordConfirmation') confirmationPasswordValidate(event.target.value)
    };
    

    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
    };

    const handleClickShowNewPasswordConfirmation = () => {
        setValues({ ...values, showNewPasswordConfirmation: !values.showNewPasswordConfirmation });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const newPasswordValidate = (text) => {
        if (text.length < 8) {
            setValues({ ...values, newPassword:'', newPasswordErrorMsg: 'Mot de passe trop court',  newPasswordValide: false });  
        }
        else{
            setValues({ ...values, newPassword: text, newPasswordErrorMsg: '', newPasswordValide: true }); 
        }
    }

    const confirmationPasswordValidate = (text) => {
        if (text.length < 8) {
            if (values.newPassword === values.newPasswordConfirmation) setValues({ ...values, newPasswordConfirmation:'', newPasswordConfirmationErrorMsg: 'Mot de passe trop court',  newPasswordConfirmationValide: false }); 
        }
        else{
            if (text !== values.newPassword) setValues({ ...values, newPasswordConfirmation:'', newPasswordConfirmationErrorMsg: 'Les mots de passe ne correspondent pas',  newPasswordConfirmationValide: false });  
            else setValues({ ...values, newPasswordConfirmation: text, newPasswordConfirmationErrorMsg: '', newPasswordConfirmationValide: true }); 
        }
    }

    useEffect(() => {
        if (props.alert.type === 'alert-danger')  setValues({...values, openErrDialog: true, loading: false})
        if (props.alert.type === 'alert-success')  setValues({...values, openSuccessDialog: true, loading: false})
        //eslint-disable-next-line
    }, [props.alert])
    
    let { id } = useParams();

    const resetPassword = (e) => {
        e.preventDefault();
        if (values.newPassword && values.newPasswordConfirmation){
            props.resetPassword(id, values.newPassword)
            setValues({ 
                ...values,
                loading: true,
                showNewPassword: false,
                newPasswordErrorMsg: '',
                showNewPasswordConfirmation: false,
                newPasswordConfirmationErrorMsg: ''
             });
        }
        else{
            if (!values.newPassword) setValues({ ...values, newPasswordErrorMsg: 'Mot de passe trop court' }); 
            if (!values.newPasswordConfirmation) setValues({ ...values, newPasswordConfirmationErrorMsg: 'Mot de passe ne correspond pas' }); 
        }
    }
    const history = useHistory();
    const handleClose = () => {
        if (values.openSuccessDialog) {
            setValues({ ...values, openSuccessDialog: false, loading: false });
            history.push("/")
        }
        else setValues({ ...values, openErrDialog: false, loading: false }); 
    }

    return (
        <div className="espace-background" >
            <div className='espace-background-card'>
                <div style={{paddingTop: "10%"}}/>
                    <div className='reset-card'>
                        <form className='reset' onSubmit={resetPassword}>
                        <h2>Réinitialisez le mot de passe</h2> 
                        <FormControl className={clsx(classes.margin)} variant="outlined">
                            <InputLabel  color={values.newPassword ? "primary" : "secondary"}  htmlFor="outlined-adornment-password">
                                Nouveau mot de passe
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showNewPassword ? 'text' : 'password'}
                                onChange={handleChange('newPassword')}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButtonMUi
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButtonMUi>
                                </InputAdornment>
                                }
                                labelWidth={166}
                                color={values.newPasswordValide ? "primary" : "secondary"}
                            />
                            <em>{values.newPasswordErrorMsg}</em>
                        </FormControl>
                        <FormControl className={clsx(classes.margin)} variant="outlined">
                            <InputLabel  color={values.newPasswordConfirmation ? "primary" : "secondary"}  htmlFor="outlined-adornment-password">
                                Confirmation du mot de passe
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showNewPasswordConfirmation ? 'text' : 'password'}
                                onChange={handleChange('newPasswordConfirmation')}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButtonMUi
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPasswordConfirmation}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {values.showNewPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                                    </IconButtonMUi>
                                </InputAdornment>
                                }
                                labelWidth={222}
                                color={values.newPasswordConfirmationValide ? "primary" : "secondary"}
                            />
                            <em>{values.newPasswordConfirmationErrorMsg}</em>
                        </FormControl>
                        <Button className="btn-register" height={40} appearance="minimal" onClick={resetPassword}>
                            <a>Réinitialiser</a>{/* eslint-disable-line */}
                        </Button>
                        { values.loading? <Spinner style={{alignSelf: "center", paddingTop: 20}}/> :null }
                        <div className="add-some-padding-top"/>
                    </form>
                    <Dialog
                        fullWidth={true}
                        open={values.openErrDialog || values.openSuccessDialog}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{
                            values.openErrDialog ? "Erreur": 
                                values.isInEmailcheckForm? "Email envoyer" :
                                    "Mot de passe Modifié"
                        }</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.alert.message}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <ButtonBStrap className="btn-gradient" onClick={handleClose} style={{margin: 7}}>
                            <u>{values.openErrDialog ? "Réessayer": "OK"}</u>
                        </ButtonBStrap>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
 


function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    resetPassword: userActions.resetPassword
};

const connectedResetPassword = connect(mapState, actionCreators)(ResetPassword);
export { connectedResetPassword as ResetPassword };