export const demandConstants = {
  INIT: 'INIT_DEMAND',
  SET_MODEL: 'SET_MODEL_DEMAND',
  SET_INTERVENTIONS: 'SET_INTERVENTIONS_DEMAND',
  SET_ADDRESS: 'SET_ADDRESS_DEMAND',
  SET_SLOTS: 'SET_SLOTS_DEMAND',
  SET_PAGE: 'SET_PAGE_DEMAND',
  NEXT_PAGE: 'NEXT_PAGE_DEMAND',
  PREVIOUS_PAGE: 'PREVIOUS_PAGE_DEMAND',
  SET_MODELS_TYPE: 'SET_MODELS_TYPE',
  ORDER_LOADING: 'ORDER_LOADING',
  ORDER_REQUEST: 'ORDER_REQUEST',
  ORDER_SUCCESS: 'ORDER_SUCCESS',
  ORDER_FAILURE: 'ORDER_FAILURE',
  SET_INTERVENTIONSDEMAND: 'SET_INTERVENTIONSDEMAND',
};
