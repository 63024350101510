import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { IconButton as IconButtonEG, ChevronLeftIcon } from 'evergreen-ui';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ButtonBStrap from 'react-bootstrap/Button';

import './style.css';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const UpdateUserInfos = () => {
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const token = JSON.parse(localStorage.getItem('user')).token;

  const fetchUserData = () => {
    setLoader(true);

    axios
      .get(`${apiBaseUrl}/api/v1/requester/user/infos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUserData(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const updateUserData = () => {
    setLoader(true);

    const {
      company,
      lastname,
      firstname,
      phone,
      civility,
      address1,
      address2,
      zip,
      city,
    } = userData;

    axios
      .put(
        `${apiBaseUrl}/api/v1/requester/user/infos`,
        {
          company,
          lastname,
          firstname,
          phone,
          civility,
          address1,
          address2,
          zip,
          city,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success('Vos informations ont été modifiées avec succès');
        history.push('/espace');
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        if (err.response.data.code === 400) {
          if (err.response.data.errors.length > 0) {
            const errors = err.response.data.errors;

            errors.forEach(({ propertyPath, message }) => {
              let cle = '';

              switch (propertyPath) {
                case 'civility':
                  cle = 'Civilité';
                  break;
                case 'lastname':
                  cle = 'Nom';
                  break;
                case 'firstname':
                  cle = 'Prénom';
                  break;
                case 'company':
                  cle = 'Raison Sociale';
                  break;
                case 'email':
                  cle = 'Adresse Email';
                  break;
                case 'address1':
                  cle = 'Adresse 1';
                  break;
                case 'zip':
                  cle = 'Code Postal';
                  break;
                default:
                  break;
              }

              toast.error(`${cle}, ${message}`);
            });
          }
        }
      });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const { lastname, firstname, phone, civility, address1, zip, city } =
      userData;

    if (
      lastname.length < 2 ||
      firstname.length < 2 ||
      phone.length < 10 ||
      civility.length < 2 ||
      address1.length < 2 ||
      zip.length < 5 ||
      city.length < 2
    ) {
      toast.error('Veuillez remplir tous les champs obligatoires !');
      return;
    }

    updateUserData();
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  if (loader) return <Loader />;

  return (
    <section className="update_user_infos_section">
      <div className="container">
        <div className="icon-title">
          <div>
            <div style={{ marginTop: 5 }} />
            <IconButtonEG
              height={25}
              icon={ChevronLeftIcon}
              iconSize={17}
              onClick={() => {
                history.goBack();
              }}
            />
          </div>
          <h2>Modifier vos informations personnelles</h2>
        </div>
        <form onSubmit={handleSubmitForm}>
          <div className="input_container">
            <TextField
              name="email"
              onChange={handleChange}
              className="input"
              label="Adresse Email *"
              variant="outlined"
              value={userData.email}
              disabled
            />
            <TextField
              name="company"
              onChange={handleChange}
              className="input"
              label="Raison Sociale *"
              variant="outlined"
              value={userData.company}
            />
          </div>
          <div className="input_container">
            <TextField
              name="lastname"
              onChange={handleChange}
              value={userData.lastname}
              className="input"
              label="Nom *"
              variant="outlined"
            />
            <TextField
              name="firstname"
              onChange={handleChange}
              value={userData.firstname}
              className="input"
              label="Prénom *"
              variant="outlined"
            />
          </div>
          <div className="input_container">
            <TextField
              name="phone"
              onChange={handleChange}
              value={userData.phone}
              className="input"
              label="Téléphone *"
              variant="outlined"
            />
            <FormControl className="input" variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Civilité *
              </InputLabel>
              <Select
                name="civility"
                onChange={handleChange}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={userData.civility}
                // onChange={handleChange}
                label="Civilité *"
              >
                <MenuItem value="Ms">Monsieur</MenuItem>
                <MenuItem value="Md">Madame</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="input_container">
            <TextField
              name="address1"
              onChange={handleChange}
              value={userData.address1}
              className="input"
              label="Adresse 1 *"
              variant="outlined"
            />
            <TextField
              name="address2"
              onChange={handleChange}
              value={userData.address2}
              className="input"
              label="Adresse 2"
              variant="outlined"
            />
          </div>
          <div className="input_container">
            <TextField
              name="zip"
              onChange={handleChange}
              value={userData.zip}
              className="input"
              label="Code Postal *"
              variant="outlined"
            />
            <TextField
              name="city"
              onChange={handleChange}
              value={userData.city}
              className="input"
              label="Ville *"
              variant="outlined"
            />
          </div>

          <div className="center btn_submit_container">
            <ButtonBStrap className="btn-gradient" type="submit">
              <u>Modifier</u>
            </ButtonBStrap>
          </div>
        </form>
      </div>
    </section>
  );
};

export default UpdateUserInfos;
