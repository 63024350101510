import React, { useState } from 'react';
import './index.css'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import ButtonBStrap from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Spinner} from 'evergreen-ui'
import { connect } from 'react-redux';
import { userActions } from '../../../actions';
import { useStyles } from '../../../components/CheckBoxStyles'
import { LoginPage } from "../Login";
import { regex } from "../../../config";
// import CancelIcon from '../../../assets/icons/cancel.svg'
// import PayIcon from '../../../assets/icons/Pay-debit-card.svg'
// import WarrantyIcon from '../../../assets/icons/warranty.svg'
 
const reg = regex.email

const minTextcharacter = 2

const _useStyles = makeStyles((theme) => ({
    margin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    inputMargin: { 
      marginTop: theme.spacing(3)
    }
  }));

function RegisterPage(props) {
  const classesCB = useStyles();
  const classes = _useStyles();
  const [values, setValues] = useState({
    user: {
      nom: '',
      prenom: '',
      email: '',
      phone: '',
      raisonSociale: '',
      password: '',
      passwordConfirmation: '',
      adresse: '',
      adresseComplement: '',
      codePostal: '',
      ville: ''
    },
    valide:{
      nom: false,
      prenom: false,
      email: false,
      phone: false,
      password: false,
      passwordConfirmation: false,
      adresse: false,
      adresseComplement: true,
      codePostal: false,
      ville: false,
      radioChecked: false
    }
  });


  const [nomErrMsg, setnomErrMsg] = useState('');
  const [prenomErrMsg, setprenomErrMsg] = useState('');
  const [emailErrMsg, setemailErrMsg] = useState('');
  const [phoneErrMsg, setphoneErrMsg] = useState('');
  const [passwordErrorMsg, setpasswordErrorMsg] = useState('');
  const [passwordConfirmationErrMsg, setpasswordConfirmationErrMsg] = useState('');
  const [adresseErrMsg, setadresseErrMsg] = useState('');
  const [adresseComplementErrMsg, setadresseComplementErrMsg] = useState('');
  const [codePostalErrMsg, setcodePostalErrMsg] = useState('');
  const [villeErrMsg, setvilleErrMsg] = useState('');
  const [radioCheckedErrMsg, setradioCheckedErrMsg] = useState('');

  const [state, setState] = useState({
    showPassword: false,
    showPasswordConfirmation: false,
    loading: false,
    openDialog: false
  })

  const [optinChecked, setOptin] = useState(false);
  const [radioChecked, setRadio] = useState();
  const [openDialog, setOpenDialog] = useState();
  const [adrFactChecked, setAdrFactCheched] = React.useState(true)
  const [optinErrMsg, setOptinErrMsg] = React.useState('')
  const [isLogin, setIsLogin] = React.useState(false)

  const handleRadioChange = (event) => {
    setRadio(event.target.value);
    setValues({
      user: { ...values.user },
      valide: { ...values.valide, radioChecked: true }
    })
    setradioCheckedErrMsg('')
  };

  const optinChange = (e) => {
    setOptin(e.target.checked)
    setOptinErrMsg('')
  }

  React.useEffect(() => {
    let address = props.demand.address
    setValues({
      user: { 
        ...values.user, 
        adresse: address.address1,
        adresseComplement: address.address2,
        codePostal: address.zip,
        ville: address.city
      },
      valide: { 
        ...values.valide,
        adresse: true,
        adresseComplement: true,
        codePostal: true,
        ville: true,
      }
    })
    setadresseErrMsg('')
    setadresseComplementErrMsg('')
    setcodePostalErrMsg('')
    setvilleErrMsg('')
    //eslint-disable-next-line
  }, [])

  const adrFactChange = (e) => {
    let address = props.demand.address
    let checked = e.target.checked
    setAdrFactCheched(checked)
    if (checked){
      setValues({
        user: { 
          ...values.user, 
          adresse: address.address1,
          adresseComplement: address.address2,
          codePostal: address.zip,
          ville: address.city
        },
        valide: { 
          ...values.valide,
          adresse: true,
          adresseComplement: true,
          codePostal: true,
          ville: true,
        }
      })
      setadresseErrMsg('')
      setadresseComplementErrMsg('')
      setcodePostalErrMsg('')
      setvilleErrMsg('')
    }
    else{
      setValues({
        user: { 
          ...values.user, 
          adresse: "",
          adresseComplement: "",
          codePostal: "",
          ville: ""
        },
        valide: { 
          ...values.valide,
          adresse: false,
          adresseComplement: true,
          codePostal: false,
          ville: false,
        }
      })
      setadresseErrMsg('')
      setadresseComplementErrMsg('')
      setcodePostalErrMsg('')
      setvilleErrMsg('')
    }
  }

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword })
  };
  const handleClickShowPasswordConfirmation = () => {
    setState({ ...state, showPasswordConfirmation: !state.showPasswordConfirmation })
  };

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    const { value } = event.target;
    setValues({
      user: { ...values.user, [prop]: value },
      valide: { ...values.valide }
    })
    if (['nom','prenom','adresse','ville'].indexOf(prop) > -1) textValidate(prop, value)
    else if (prop === 'email') emailValidate(value)
    else if (prop === 'phone') phoneValidate(value)
    else if (prop === 'password') passwordValidate(value)
    else if (prop === 'passwordConfirmation') passwordConfirmationValidate(value)
    else if (prop === 'codePostal') CPValidate(value)
  }

  const textValidate = (prop, value) => {
    if(value.length < minTextcharacter){
      setValues({
        user: { ...values.user, [prop]: '' },
        valide: { ...values.valide, [prop]: false }
      })
      if (prop === 'nom') setnomErrMsg('Nom trop court')
      else if (prop === 'prenom') setprenomErrMsg('Prénom trop court')
      else if (prop === 'adresse') setadresseErrMsg('Adresse trop courte, ajouter une adresse valide')
      else if (prop === 'adresseComplement') setadresseComplementErrMsg('Adresse trop courte')
      else setvilleErrMsg('Text trop court')
    }
    else {
      setValues({
        user: { ...values.user, [prop]: value },
        valide: { ...values.valide, [prop]: true }
      })
      if (prop === 'nom') setnomErrMsg('')
      else if (prop === 'prenom') setprenomErrMsg('')
      else if (prop === 'adresse') setadresseErrMsg('')
      else if (prop === 'adresseComplement') setadresseComplementErrMsg('')
      else setvilleErrMsg('')
    }
  }
  const textValidate2 = () => {
    let tab = ['nom' , 'prenom' , 'adresse' , 'ville' ]
    for (const value of tab ){
      if(values.user[value].length < minTextcharacter){ 
        setValues({
          user: { ...values.user, [value]: '' },
          valide: { ...values.valide, [value]: false }
        })
        if (value === 'nom') setnomErrMsg('Nom trop court')
        else if (value === 'prenom') setprenomErrMsg('Prénom trop court')
        else if (value === 'adresse') setadresseErrMsg('Adresse trop courte, ajouter une adresse valide')
        else if (value === 'adresseComplement') setadresseComplementErrMsg('Adresse trop courte')
        else setvilleErrMsg('Text trop court')
      }
      else {
        setValues({
          user: { ...values.user, [value]: value },
          valide: { ...values.valide, [value]: true }
        })
        if (value === 'nom') setnomErrMsg('')
        else if (value === 'prenom') setprenomErrMsg('')
        else if (value === 'adresse') setadresseErrMsg('')
        else if (value === 'adresseComplement') setadresseComplementErrMsg('')
        else setvilleErrMsg('')
      }
    }
  }

  const emailValidate = (text) => {
    if (reg.test(text) === false) {
        setValues({
          user: { ...values.user, email: '' },
          valide: { ...values.valide, email: false }
        }); 
        setemailErrMsg('Adresse e-mail invalide')
    }
    else {
        setValues({ 
          user: { ...values.user, email: text },
          valide: { ...values.valide, email: true }
        }); 
        setemailErrMsg('')
    }
  }

  const phoneValidate = (number) => {
    if (!regex.phone.test(number)) {
        setValues({
          user: { ...values.user, phone: '' },
          valide: { ...values.valide, phone: false }
        }); 
        setphoneErrMsg('Numero Invalide')
    }
    else {
        setValues({ 
          user: { ...values.user, phone: number },
          valide: { ...values.valide, phone: true }
        }); 
        setphoneErrMsg('')
    }
  }

  const CPValidate = (number) => {
    if (!regex.zip.test(number)) {
        setValues({
          user: { ...values.user, codePostal: '' },
          valide: { ...values.valide, codePostal: false } 
        }); 
        setcodePostalErrMsg('Code Postal Invalide' )
    }
    else {
        setValues({ 
          user: { ...values.user, codePostal: number },
          valide: { ...values.valide, codePostal: true }
        }); 
        setcodePostalErrMsg('')
    }
  }

  const passwordValidate = (text) => {
    if (text.length < 8) {
      setValues({
        user: { ...values.user, password: '' },
        valide: { ...values.valide, password: false } 
      }); 
      setpasswordErrorMsg('Saisir au moins 8 caractères')
    }
    else {
      setValues({ 
        user: { ...values.user, password: text },
        valide: { ...values.valide, password: true }
      }); 
      setpasswordErrorMsg('')
    }
  }

  const passwordConfirmationValidate = (text) => {
    if (text === values.user.password) {
      setValues({
        user: { ...values.user, passwordConfirmation: text },
        valide: { ...values.valide, passwordConfirmation: true } 
      }); 
      setpasswordConfirmationErrMsg('')
    }
    else {
      setValues({ 
        user: { ...values.user, passwordConfirmation: '' },
        valide: { ...values.valide, passwordConfirmation: false }
      }); 
      setpasswordConfirmationErrMsg( 'Retapez votre mot de passe')
    }
  }
  
  const radioCheckedValidate = () =>{
    if (!values.valide.radioChecked) {setValues({ 
      user: { ...values.user },
      valide: { ...values.valide , radioChecked: false}
    });
    setradioCheckedErrMsg('Sélectionnez une civilité')
    }
    else {setValues({ 
      user: { ...values.user },
      valide: { ...values.valide , radioChecked: true}
    });
    setradioCheckedErrMsg('')
    }
  }
  const allTrue = (obj) => {
    let first = true
    for(var o in obj)
      if (first) first = false
      else
        if(!obj[o]) return false;
      
    return true;
  }

  const handleSubmit = (event) => {
    //console.log('vvvvvv:',values)
    event.preventDefault();
    if (allTrue(values.valide)) {
      //if (optinChecked){
        setState({ loading: true });
        setValues({ 
          user: { ...values.user },
          valide: { ...values.valide }
        });
        setnomErrMsg('')
        setprenomErrMsg('')
        setemailErrMsg('')
        setphoneErrMsg('')
        setpasswordErrorMsg('')
        setpasswordConfirmationErrMsg('')
        setadresseErrMsg('')
        setadresseComplementErrMsg('')
        setcodePostalErrMsg('')
        setvilleErrMsg('')
        setradioCheckedErrMsg('')
        window.dataLayer.push({'event':'userInscription'});
        /*window.gtag('event', 'conversion', {
          'send_to': 'AW-403051804/kXAjCIXE_IoDEJyqmMAB',
        });*/
        props.register({
          ...values.user,
          civility: radioChecked,
          optin: optinChecked
        });
      //} else setOptinErrMsg("Veuillez cocher ce bouton")
      window.scrollTo({top:0, left:0,behavior: 'smooth'})
      document.querySelector('body').scrollTo({top:0, left:0,behavior: 'smooth'});
    }
    else{ 
      setOpenDialog(true)
      emailValidate(values.user.email)
      phoneValidate(values.user.phone)
      passwordValidate(values.user.password)
      passwordConfirmationValidate(values.user.passwordConfirmation)
      CPValidate(values.user.codePostal)
      radioCheckedValidate()
      textValidate2()
    }
  }

  React.useEffect(() => {
    setState({ 
        ...values,
        loading: false,
     });
     //eslint-disable-next-line
  }, [props.openDialog])

  const handleCloseDialog = () =>{
    setOpenDialog(false)
  }

  // const Explication = () =>{
  //   return(
  //     <div style={{marginTop: 10, marginBottom: 40}}>
  //       <div className='explic-row'>
  //         <div className='expli-icon' style={{backgroundImage: `url(${CancelIcon})`}} />
  //         <div className='explic-text'>
  //           <h1>Annulation gratuite</h1>
  //           <p>Annulez votre réservation à n'importe quel moment sans frais depuis votre compte.</p>
  //         </div>
  //       </div>
  //       <div className='explic-row'>
  //         <div className='expli-icon' style={{backgroundImage: `url(${WarrantyIcon})`}} />
  //         <div className='explic-text'>
  //           <h1>Garantie 12 mois</h1>
  //           <p>Toutes nos réparations sont garanties 12 mois. Assistance 7/7j en ligne sur le chat.</p>
  //         </div>
  //       </div>
  //       <div className='explic-row'>
  //         <div className='expli-icon' style={{backgroundImage: `url(${PayIcon})`}} />
  //         <div className='explic-text'>
  //           <h1>Payez sur place</h1>
  //           <p>Aucun prépaiement en ligne n'est requis, payez sur place une fois la réparation réussie.</p>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    
    <div style={{marginBottom: 60}}>
      { isLogin? <LoginPage openDialog={props.openDialog} />:
        <div>
          <div style={{display: "flex", flexDirection: "row", marginTop: 30}}>   
            <p>J’ai déjà un compte.</p>
            <Button className="btn-register" appearance="minimal" onClick={() => {
              setIsLogin(true)
              window.scrollTo({top:0, left:0,behavior: 'smooth'})
              document.querySelector('body').scrollTo({top:0, left:0,behavior: 'smooth'});
            }}>
                <a>Je me connecte</a>{/* eslint-disable-line */}
            </Button>
          </div>
          <form className='from-register' onSubmit={handleSubmit} >
            <h2>Inscription</h2>
            <h3>Informations personnelles</h3>
            <h4>Civilité <em>*</em></h4>
            <RadioGroup aria-label="Civilité" name="Civilité1" value={radioChecked} onChange={handleRadioChange}>
              <div style={{ flexDirection: 'row'}}>
                <FormControlLabel value="Ms" control={<Radio color='default' />} label="Monsieur" />
                <FormControlLabel value="Md" control={<Radio color='default'/>} label="Madame" />
              </div>
            </RadioGroup>
            <em>{radioCheckedErrMsg}</em>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-prenom"
                  label="Prénom"
                  required
                  type="text"
                  onChange={handleChange('prenom')}
                  variant="outlined"
                  color={values.valide.prenom ? "primary" : "secondary"}
              />
              <em>{prenomErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-nom"
                  label="Nom"
                  required
                  type="text"
                  onChange={handleChange('nom')}
                  variant="outlined"
                  color={values.valide.nom ? "primary" : "secondary"}
              />
              <em>{nomErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-email"
                  label="E-mail"
                  required
                  type="email"
                  onChange={handleChange('email')}
                  variant="outlined"
                  color={values.valide.email ? "primary" : "secondary"}
              />
              <em>{emailErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-phone"
                  label="Téléphone"
                  required
                  type="tel"
                  onChange={handleChange('phone')}
                  variant="outlined"
                  color={values.valide.phone ? "primary" : "secondary"}
              />
              <em>{phoneErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-raisonSociale"
                  label="Raison sociale"
                  type="text"
                  onChange={handleChange('raisonSociale')}
                  variant="outlined"
              />
            </FormControl>
            <h3>Mot de passe</h3>
            <FormControl className={clsx(classes.margin)} variant="outlined">
              <InputLabel  color={values.valide.password ? "primary" : "secondary"}  htmlFor="outlined-adornment-password">
                  Mot de passe *
              </InputLabel>
              <OutlinedInput
                  id="outlined-adornment-password"
                  type={state.showPassword ? 'text' : 'password'}
                  onChange={handleChange('password')}
                  endAdornment={
                  <InputAdornment position="end">
                      <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      >
                      {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                  }
                  labelWidth={110}
                  color={values.valide.password ? "primary" : "secondary"}
              />
              <em>{passwordErrorMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.margin)} variant="outlined">
              <InputLabel  color={values.valide.passwordConfirmation ? "primary" : "secondary"}  htmlFor="outlined-adornment-passwordConfirmation">
                Confirmation mot de passe *
              </InputLabel>
              <OutlinedInput
                  id="outlined-adornment-passwordConfirmation"
                  type={state.showPasswordConfirmation ? 'text' : 'password'}
                  onChange={handleChange('passwordConfirmation')}
                  endAdornment={
                  <InputAdornment position="end">
                      <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirmation}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      >
                      {state.showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                  }
                  labelWidth={210}
                  color={values.valide.passwordConfirmation ? "primary" : "secondary"}
              />
              <em>{passwordConfirmationErrMsg}</em>
            </FormControl>
            <h3 style={{marginBottom: -5}}>Adresse de facturation</h3>
            <div className='check-box' >
              <Checkbox 
                  className={classesCB.root}
                  disableRipple
                  checkedIcon={<span className={clsx(classesCB.icon, classesCB.checkedIcon)} />}
                  icon={<span className={classesCB.icon} />}
                  checked={adrFactChecked} 
                  style ={{ backgroundColor: 'white'}} 
                  onChange={adrFactChange}
              />
              <h5>L’adresse de facturation est la même que l’adresse d’intervention.</h5>
            </div>
            { adrFactChecked ? null :
              <FormControl className={clsx(classes.inputMargin)}>
                <TextField
                    id="outlined-error-adresse"
                    label={adrFactChecked ? values.user.adresse: "Adresse"}
                    required={!adrFactChecked}
                    //disabled={adrFactChecked}
                    type="text"
                    onChange={handleChange('adresse')}
                    variant="outlined"
                    color={values.valide.adresse ? "primary" : "secondary"}
                />
                <em>{adresseErrMsg}</em>
              </FormControl>          
            }
            { adrFactChecked ? null :
              <FormControl className={clsx(classes.inputMargin)}>
                <TextField
                    id="outlined-error-adresseComplement"
                    label={adrFactChecked ? values.user.adresseComplement: "Complément d’adresse"}
                    
                    //disabled={adrFactChecked}
                    type="text"
                    onChange={handleChange('adresseComplement')}
                    variant="outlined"
                    color={values.valide.adresseComplement ? "primary" : "secondary"}
                />
                <em>{adresseComplementErrMsg}</em>
              </FormControl>
              }
              { adrFactChecked ? null :
              <FormControl className={clsx(classes.inputMargin)}>
                <TextField
                    id="outlined-error-codePostal"
                    label={adrFactChecked ? values.user.codePostal:"Code Postal"}
                    required={!adrFactChecked}
                    //disabled={adrFactChecked}
                    type="text"
                    onChange={handleChange('codePostal')}
                    variant="outlined"
                    color={values.valide.codePostal ? "primary" : "secondary"}
                />
                <em>{codePostalErrMsg}</em>
              </FormControl>
              }
              { adrFactChecked ? null :
              <FormControl className={clsx(classes.inputMargin)}>
                <TextField
                    id="outlined-error-ville"
                    label={adrFactChecked ? values.user.ville:"Ville"}
                    required={!adrFactChecked}
                    //disabled={adrFactChecked}
                    type="text"
                    onChange={handleChange('ville')}
                    variant="outlined"
                    color={values.valide.ville ? "primary" : "secondary"}
                />
                <em>{villeErrMsg}</em>
              </FormControl>
              }
            <div className='check-box' style={{marginTop: 20}}>
              <Checkbox 
                    className={classesCB.root}
                    disableRipple
                    checkedIcon={<span className={clsx(classesCB.icon, classesCB.checkedIcon)} />}
                    icon={<span className={classesCB.icon} />}
                    checked={optinChecked} 
                    style ={{ backgroundColor: 'white'}} 
                    onChange={optinChange} 
                />
                <h5 >Je souhaite recevoir les promotions et nouveautés de Neero</h5>
            </div>
             <em>{optinErrMsg}</em>
             { state.loading ? <Spinner style={{alignSelf: 'center', marginTop: 40}}/>: null}
            <div className='check-box'>
              <ButtonBStrap className="btn-gradient" onClick={handleSubmit}>
                  <u>S'inscrire</u>
              </ButtonBStrap>
            </div>
            <h4 className='oblig'><em>*</em> Champs obligatoires</h4>
          </form>
          {/* <Explication /> */}
          <Dialog
            fullWidth={true}
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='dialog-from-register'>
            <DialogTitle id="alert-dialog-title"><h1>Attention</h1></DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <h2>Veuillez remplir tous les champs correctement</h2>
              <p>Toutes ces informations nous sont nécessaires pour la création de votre compte et pour que nos techniciens puissent vous recontacter.</p>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <ButtonBStrap className="btn-gradient" onClick={handleCloseDialog} style={{margin: 10}}>
                <u>Ok, j'ai compris</u>
            </ButtonBStrap>
            </DialogActions>
          </Dialog>

        </div>
        }
      </div>
  );

}

function mapState(state) {
  const { alert, demand } = state;
    const { registering } = state.registration;
    return { registering, alert, demand };
}

const actionCreators = {
    register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };