import React  from 'react';
import './index.css'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';

const btnStyle = { color: '#6232A0', fontSize: 28 }

export default function ShowMoreBox({title, content, show = false}){
    const [isContentShown, setIsContentShown] = React.useState(show)

    const toogleShowContent = () =>  setIsContentShown(!isContentShown)

    return (
        <div className='showMoreMox-container'>
            <div className='showMoreMox-header'>
                <h3>{title}</h3>
                <div className='showMoreMox-icon' onClick={toogleShowContent}>
                    {isContentShown? <IndeterminateCheckBoxIcon style={btnStyle} /> : <AddBoxIcon style={btnStyle}/>}
                </div>
            </div>
            {
                isContentShown &&
                <div className='showMoreMox-content'>
                    <p>{content}</p>
                </div>
            }
        </div>
    );
}