import { orderConstants } from '../constants';

const initialState = {
    orders: null,
    loading: false,
    order: null,
    orderLoading: false
}

export function order(state = initialState, action) {
  switch (action.type) {
    case orderConstants.INIT:
        return {
            orders: null,
            loading: false,
            order: null,
            orderLoading: false
        };
    case orderConstants.SET_ORDERS:
        return{
            ...state,
            orders: action.orders,
            loading: false
        }
    case orderConstants.SET_LOADING:
        return{
            ...state,
            loading: true
        }
    case orderConstants.ORDERS_FAILED:
        return {
            ...state,
            loading: false
        }

    case orderConstants.SET_ORDER:
        return{
            ...state,
            order: action.order,
            orderLoading: false
        }
    case orderConstants.SET_ORDER_LOADING:
        return{
            ...state,
            orderLoading: true
        }
    case orderConstants.ORDER_FAILED:
        return {
            ...state,
            orderLoading: false
        }
    default:
        return state
  }
}