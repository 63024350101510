import React, { useState, useEffect } from 'react'
import "./index.css";
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import { days } from "../../config";

const style = getComputedStyle(document.body)
const MINUTE_MS = 1800000; //30min
const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 4,
    width: 15,
    height: 15,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: style.getPropertyValue('--tertiary-2'),
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 15,
      height: 15,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: style.getPropertyValue('--tertiary-2'),
    },
  },
});

function SlotsSelect(props) {
    const slots = props.slots;
    const classes = useStyles();

    const nowHour_ = new Date().getHours()
    const TimeSlot = (prop) =>{
      const [checked, setChecked] = useState(prop.day.isChecked)
      const [isHourGone, setIsHourGone] = useState(prop.index? false: prop.day.slotTime.split('-')[0].replace(/\D/g, "") < nowHour_)
  
      const onChecked = (e) => {
        setChecked(e.target.checked)
        props.onSelect(prop.dayId, prop.day.id, e.target.checked)
      }

      const onClick = () => {
        setChecked(!checked)
        props.onSelect(prop.dayId, prop.day.id, !checked)
      }

      useEffect(() => {
        const interval = setInterval(() => {
          let nowHour = new Date().getHours()
          setIsHourGone(prop.index? false: prop.day.slotTime.split('-')[0].replace(/\D/g, "") < nowHour)
        }, MINUTE_MS);
      
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
      
      useEffect(() => {
        if (isHourGone){
          setChecked(false)
          props.onSelect(prop.dayId, prop.day.id, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isHourGone])

      //const isHourGone = prop.index? false: prop.day.slotTime.split('-')[0].replace(/\D/g, "") < nowHour

        return (
            <div>
              {//prop.date style.getPropertyValue('--tertiary-2')
              }
              {
                prop.day.available?
                <div className='container-time'  onClick={isHourGone? null:onClick}>
                  <Checkbox 
                    className={classes.root}
                    disableRipple
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    checked={checked} 
                    disabled={isHourGone}
                    style ={{ backgroundColor: 'white'}} 
                    onChange={onChecked}
                  />
                  {
                    isHourGone?
                    <h2 className={'slot-time'} style={{opacity:0.5}}>{prop.day.slotTime}</h2>
                    :
                    <h2 className={checked? 'slot-time-selected': 'slot-time'}>
                        {prop.day.slotTime}
                    </h2>
                  }
                </div>
                : 
                <div className='container-time-complet'>
                  <p>Complet</p>
                </div>
              }
            </div>
        )
    }

    const DaySlot = (prop) => {
      let date = new Date(prop.day.date)
      return(
        <div className='container-day'>
          <h1>{days[date.getDay()]+' '+("0" +date.getDate()).slice(-2)+'/'+("0" +(date.getMonth()+1)).slice(-2)}</h1>
          {
            prop.day.slots.map((day) =>
            // Correct! Key should be specified inside the array.
            <TimeSlot key={day.id} day={day} dayId={prop.day.id} index={prop.index} />
            )
          }
        </div>
      )
    }

    const listSlots = () => {
        return(
            slots.map((day, index) =>
            // Correct! Key should be specified inside the array.
            <DaySlot key={day.id} day={day} index={index}/>
            )
        )
    }

    return (
        <div className="container-slots">
            {slots ? listSlots() : null} 
        </div>
    );
  }

  export default SlotsSelect