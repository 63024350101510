import { userConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user, mailSend: false } : {mailSend: false};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false
      };
    case userConstants.FORGOTTEN_PASSWORD_REQUEST:
      return{
        user: action.user
      };
    case userConstants.FORGOTTEN_PASSWORD_SUCCESS:
      return{
        mailSend: true
      };
    case userConstants.FORGOTTEN_PASSWORD_FAILURE:
      return{
        mailSend: false,
      }
    case userConstants.RESET_PASSWORD_REQUEST:
      return{};
    case userConstants.REGISTER_SUCCESS:
      return{};
    case userConstants.RESET_PASSWORD_FAILURE:
      return{}
    case userConstants.LOGOUT:
      return {
        loggedIn: false
      };
    default:
      return state
  }
}