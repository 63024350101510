import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const FooterNewVersion = () => {
  return (
    <footer className='footer_main_app'>
      <div className='container'>
        <div className='logo'>
          <Link to='/'>
            <img src='/logo_footer.png' alt='neero' />
          </Link>
        </div>
        <div className='row'>
          <div className='col'>
            <h3 className='col_title'>Neero</h3>
            <ul className='col_list'>
              <li>
                <Link to='/mission' className='list_item_link'>
                  Notre mission
                </Link>
              </li>
              <li>
                <Link to='/espace' className='list_item_link'>
                  Mon compte
                </Link>
              </li>
              <li>
                <Link
                  to='/application-mobile-diagnostic-neero'
                  className='list_item_link'
                >
                  Application mobile Neero
                </Link>
              </li>
              <li>
                <Link
                  to='/application-mobile-reparateur-neero'
                  className='list_item_link'
                >
                  Application mobile réparateur
                </Link>
              </li>
            </ul>
          </div>
          <div className='col'>
            <h3 className='col_title'>Réparation</h3>
            <ul className='col_list'>
              <li>
                <Link to='/entreprise' className='list_item_link'>
                  Offre entreprises
                </Link>
              </li>
              <li>
                <Link to='/reparateur' className='list_item_link'>
                  Devenir réparateur
                </Link>
              </li>
              <li>
                <Link to='/reparation-ecran' className='list_item_link'>
                  Réparation écran cassé
                </Link>
              </li>
              <li>
                <Link to='/reparation-batterie' className='list_item_link'>
                  Réparation batterie
                </Link>
              </li>
            </ul>
          </div>
          <div className='col'>
            <h3 className='col_title'>A propos</h3>
            <ul className='col_list'>
              <li>
                <Link to='/cgu' className='list_item_link'>
                  CGV/CGU
                </Link>
              </li>
              <li>
                <Link to='/privacy-policy' className='list_item_link'>
                  Politique de confidentialité
                </Link>
              </li>
              <li>
                <Link to='/faq' className='list_item_link'>
                  FAQ
                </Link>
              </li>
              <li>
                <Link to='/contact' className='list_item_link'>
                  Contactez-nous
                </Link>
              </li>
            </ul>
          </div>
          <div className='col col_contact'>
            <h3 className='col_title'>
              Vous avez une question ? <br /> Contactez-nous
            </h3>
            <ul className='col_list'>
              <li>
                <a href='tel:+33180885700' className='list_item_link'>
                  Tél: +33 1 80 88 57 00
                </a>
              </li>
              <li>
                <p className='list_item_link'>Rejoignez-nous:</p>
              </li>
              <li>
                <div className='social_icons'>
                  <a href='https://www.facebook.com/neero.fr' rel='noopener' target='_blank'>
                    <img src='/social_icons_footer/facebook.png' alt='' />
                  </a>
                  <a href='https://www.instagram.com/neero.fr/' rel='noopener' target='_blank'>
                    <img src='/social_icons_footer/instagram.png' alt='' />
                  </a>
                  <a
                    href='https://www.linkedin.com/company/neero-paris'
                    target='_blank' rel='noopener'
                  >
                    <img src='/social_icons_footer/linkedin.png' alt='' />
                  </a>
                  <a href='https://www.tiktok.com/@neero.fr' rel='noopener' target='_blank'>
                    <img src='/social_icons_footer/tiktok.png' alt='' />
                  </a>
                  <a
                    href='https://www.pinterest.fr/neeromobile/'
                    target='_blank' rel='noopener'
                  >
                    <img src='/social_icons_footer/pinterest.png' alt='' />
                  </a>
                  <a
                    href='https://youtube.com/channel/UCx3lokcMt90if9_lf96gk9w'
                    target='_blank' rel='noopener'
                  >
                    <img src='/social_icons_footer/youtube.png' alt='' />
                  </a>
                  <a
                    href='https://www.snapchat.com/add/neero.fr'
                    target='_blank' rel='noopener'
                  >
                    <img src='/social_icons_footer/snapchat.png' alt='' />
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className='col col_apps'>
            <h3 className='col_title'>Application mobile</h3>
            <div className='download_app'>
              <a
                target='_blank' rel='noopener'
                href='https://apps.apple.com/us/app/neero/id1587685748?itscg=30200&itsct=apps_box_link'
              >
                <img src='/app_store_footer.png' alt='' />
              </a>
              <a
                target='_blank' rel='noopener'
                href='https://play.google.com/store/apps/details?id=co.trackn.neero&pli=1'
              >
                <img src='/google_play_footer.png' alt='' />
              </a>
            </div>
          </div>
        </div>
        <div className='separator'></div>
        <p className='copyright'>© 2022 Neero®. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default FooterNewVersion;
