import Button from 'react-bootstrap/Button';
import './HomeDetails.css';
import RightArrowSvg from "../../assets/svgFiles/rightArrow";
import HomeDetails1 from "../../assets/images/home-details-1.png";
import HomeDetails2 from "../../assets/images/home-details-2.png";
import HomeDetails3 from "../../assets/images/home-details-3.png";

export default function HomeDetails({goToBookingPage}) {

  return (
    <div className="homeV2-details-container">
        <div className="homeV2-details-block">
            <div className="homeV2-details-image-1" style={{backgroundImage: `url(${HomeDetails1})`}} />
            <div>
                <h1>Où vous voulez...</h1>
                <div className="homeV2-details-image-mobile" style={{backgroundImage: `url(${HomeDetails1})`}} />
                <p>Indiquez l’adresse de votre réparation.</p>
            </div>
        </div>
        <div className="homeV2-details-block">
            <div>
                <h1>... Quand vous voulez !</h1>
                <div className="homeV2-details-image-mobile" style={{backgroundImage: `url(${HomeDetails2})`}} />
                <p>Vous sélectionnez les créneaux (date et heure) selon vos disponibilités.</p>
            </div>
            <div className="homeV2-details-image-2" style={{backgroundImage: `url(${HomeDetails2})`}} />
        </div>
        <div className="homeV2-details-block">
            <div className="homeV2-details-image-3" style={{backgroundImage: `url(${HomeDetails3})`}} />
            <div>
                <h1>Garantie 1 an</h1>
                <div className="homeV2-details-image-mobile" style={{backgroundImage: `url(${HomeDetails3})`}} />
                <p>Neero s’occupe de votre réparation et nos pièces sont garanties pendant 12 mois.</p>
            </div>
        </div>
        <Button className="btn-gradient-2"  onClick={goToBookingPage}>
            <u>{("faire une demande de réparation").toUpperCase()}</u>
            <RightArrowSvg/>
        </Button>
    </div>
  );
}

