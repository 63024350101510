import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const HideComponentIfWebViewTrue = (props) => {
  const { search,pathname } = useLocation();
  const webview = useMemo(() => new URLSearchParams(search), [search]).get('webview');

  if(pathname === '/cgu' || pathname === '/reparateur-join' || pathname === '/privacy-policy'){
    if(!webview || webview !== 'true') return props.children;
    else return <></>;
  }

  return props.children;
};

export default HideComponentIfWebViewTrue;
