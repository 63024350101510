export const discountConstants = {
    INIT_DISCOUNT: 'INIT_DISCOUNT',
    DISCOUNT_REQUEST: 'DISCOUNT_REQUEST',
    DISCOUNT_SUCCESS: 'DISCOUNT_SUCCESS',
    DISCOUNT_FAILURE: 'DISCOUNT_FAILURE',

    INIT_COUPON: 'INIT_COUPON',
    COUPON_REQUEST: 'COUPON_REQUEST',
    COUPON_SUCCESS: 'COUPON_SUCCESS',
    COUPON_FAILURE: 'COUPON_FAILURE',
};
