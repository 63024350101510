import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './index.css';
import ButtonBStrap from 'react-bootstrap/Button';
import SlotsSelect from '../../../components/SlotsSelect'
import { demandActions,resumeActions } from '../../../actions';
import { connect } from 'react-redux';
import { Spinner } from 'evergreen-ui'
import DemandeFooter from "./DemandeFooter";

const createSelectedIdsArray = (slots, appointmentSlots) => {
  let originalArray2D = []
  let  tab = []
  if(appointmentSlots && appointmentSlots.length > 0) originalArray2D = appointmentSlots
  else {
    if (slots){
      let tabs = []
      for (let i=0; i<slots.length; i++){
        let day = slots[i]
        let tab = []
        for (let j=0; j<day.slots.length; j++){
          let slot = day.slots[j]
          tab[j] = {id:j+1, date: day.date, slotTime: slot.slotTime, available: slot.available, isChecked: false}
        }
        tabs[i]= {id:i+1, date: day.date, slots: tab}
      }
      originalArray2D = tabs
    }
    else originalArray2D = []
  }

  let cpt = 0
  for(var n in originalArray2D){
    for (var m in originalArray2D[n].slots){
      tab[cpt] = originalArray2D[n].slots[m]
      cpt++
    }
  }
  return {selectedIds: originalArray2D, _1dArray: tab}
}

function DemandeFormP3(props) {

  const [selectedIds, setSelectedIds] = useState(createSelectedIdsArray(props.slots, props.appointmentSlots));
  const [openDialog, setOpenDialog] = useState(false)
  const [errMsg, seterrMsg] = useState({})
  const [ignoreBtn,showIgnoreBtn] = useState(false)

  const onSelect=(dayId, id, isChecked) => {
    let cpt = 0
    for (var i in selectedIds.selectedIds) {
      for (var j in selectedIds.selectedIds[i].slots)
      {
        if(selectedIds.selectedIds[i].slots[j].id === id && selectedIds.selectedIds[i].id === dayId){
          selectedIds.selectedIds[i].slots[j].isChecked = isChecked;
          selectedIds._1dArray[cpt].isChecked = isChecked;
          props.setSlots(selectedIds.selectedIds)
          props.setDate(selectedIds._1dArray.filter(function( obj ) { return obj.isChecked === true }))
          break;
        }
        cpt++
      }
    }
  }


  const handleSubmit = () => {
    let long = selectedIds._1dArray.filter(function( obj ) { return obj.isChecked === true }).length
    if ( long < 1){
      seterrMsg({
        err1: "Vous devez sélectionner au moins un créneaux !",
        err2: "Pour envoyer votre demande à un maximum de techniciens et obtenir un rendez-vous, nous vous conseillons d'ajouter des créneaux.",
        //err3: "Pour poursuivre votre demande avec uniquement les créneaux renseignés, cliquez sur ignorer et continuer."
      })
      showIgnoreBtn(false)
      setOpenDialog(true)
    }
    else if ( long < 3){
      seterrMsg({
        err1: "Vous avez sélectionné moins de 3 créneaux !",
        err2: "Veuillez ajouter vos autres disponibilités",
      })
      showIgnoreBtn(true)
      setOpenDialog(true)
    }
    /*else if (long >5){
      seterrMsg({
        err1: "Vous avez sélectionné plus de 5 créneaux.",
        err2: "Cette collection doit contenir exactement 5 éléments",
        err3: "Pour poursuivre votre demande avec uniquement les créneaux renseignés, cliquez sur ignorer et continuer."
      })
      showIgnoreBtn(true)
      setOpenDialog(true)
    }*/
    else {
      showIgnoreBtn(false)
      props.nextPage()
    }
  }

  const handleClose = () => {
    setOpenDialog(false); 
  }

  const  handleCloseAndContinue = () => {
    setOpenDialog(false); 
    props.nextPage()
  }

  useEffect(() => setSelectedIds(createSelectedIdsArray(props.slots, props.appointmentSlots)),[props.slots, props.appointmentSlots])

  return (
    <>
      <div className="demande-from-container">
        <h1>À quel moment voulez-vous que l’on intervienne ?</h1>
        <p>Sélectionnez tous les créneaux sur lesquels vous êtes disponible.</p>
        <p>Dès validation de votre commande, vous recevrez un sms avec l'heure exacte d'intervention de notre technicien.</p>
        { props.loading? 
          <Spinner style={{ paddingTop: 20, paddingBottom: 50}}/> :
          <SlotsSelect slots={selectedIds.selectedIds} onSelect={onSelect}/>
        }

        <ButtonBStrap className="btn-gradient" style={{marginBottom: 50}} onClick={handleSubmit}>
          <u>Étape suivante</u>
        </ButtonBStrap>
      </div>
      <Dialog
        fullWidth={true}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className='dialog-text' style={{fontWeight: 'bold', color:'#722CC8',fontSize: 21, marginTop: 25}}>Attention</p>
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <h4 className='dialog-text' style={{fontWeight: 'bold', color:'#722CC8',fontSize: 16}}>{errMsg.err1}</h4>
          <p className='dialog-text' style={{color:'#7F7F7F',fontSize: 15}}>{errMsg.err2}</p>
          {/*<p>{errMsg.err3}</p>*/}
        </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: ignoreBtn ? 'center': null, marginBottom: 20, flexWrap: 'wrap'}}>
        {
          ignoreBtn &&
          <ButtonBStrap className="btn-cancel" onClick={handleCloseAndContinue} style={{backgroundColor: 'transparent'}}>
            <u>Ignorer et continuer</u>
          </ButtonBStrap>
        }
        <ButtonBStrap className="btn-gradient"  onClick={handleClose} style={{margin: 21}}>
          <u>Ajouter des créneaux</u>
        </ButtonBStrap>
        </DialogActions>
      </Dialog>
      <DemandeFooter />
    </>
  );

}



function mapState(state) {
  const { slots, loading } = state.slots;
  const appointmentSlots = state.demand.appointmentSlots
  return { slots, loading, appointmentSlots };
}

const actionCreators = {
  setSlots : demandActions.setSlots,
  nextPage: demandActions.nextPage,
  setDate: resumeActions.setDate,
};

const connectedDemandeFormP3 = connect(mapState, actionCreators)(DemandeFormP3);
export { connectedDemandeFormP3 as DemandeFormP3 };