import React, { useEffect } from "react";
import "./index.css";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { manufacturersActions } from "../../actions";
import RightArrowSvg from "../../assets/svgFiles/rightArrow";
import FooterV2 from "../../components/FooterV2";
import AppDownloadV2 from "../../components/AppDownloadV2";
//import ReactPixel from 'react-facebook-pixel';
import ProductReviews from "../Products/ProductReviews";
import HomeHeader from "./HomeHeader";
import HomeRepDom from "./HomeRepDom";
import HomeDetails from "./HomeDetails";
import HomeBrands from "./HomeBrands";
import HomeEnd from "./HomeEnd";
import ProductReparations from "../Products/ProductReparations";

function HomePageV2(props) {
  const history = useHistory();
  const goToBookingPage = () => history.push("/demande");

  useEffect(() => {
    //ReactPixel.pageView()
    props.getManufacturers();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <HomeHeader goToBookingPage={goToBookingPage} />
      <HomeRepDom />
      <HomeDetails goToBookingPage={goToBookingPage} />
      <HomeBrands brands={props.manufacturers} />
      <ProductReviews />
      <HomeEnd />
      <div className='product-reparations-backgroudBlue'>
        <ProductReparations />
        <Button
          className='btn-gradient-2 btn-align-center'
          onClick={goToBookingPage}
        >
          <u>{"réserver Une réparation".toUpperCase()}</u>
          <RightArrowSvg />
        </Button>
      </div>
      <AppDownloadV2 />
      <FooterV2 />
    </>
  );
}

function mapState(state) {
  const { manufacturers } = state.manufacturers;
  return { manufacturers };
}
const actionCreators = {
  getManufacturers: manufacturersActions.getManufacturers,
};

const connectedHomePageV2 = connect(mapState, actionCreators)(HomePageV2);
export { connectedHomePageV2 as HomePageV2 };
