import { resumeConstants } from '../constants';

const initialState = {
    address: null,
    model: null,
    price: null,
    date: null
}

export function resume(state = initialState, action) {
  switch (action.type) {
    case resumeConstants.INIT:
        return {
            address: null,
            model: null,
            price: null,
            date: null
        };
    case resumeConstants.SET_ADDRESS:
        return {
            ...state,
            address: action.address
        };
    case resumeConstants.SET_MODEL:
        return {
            ...state,
            model: action.model
        };
    case resumeConstants.SET_PRICE:
        return {
            ...state,
            price: action.price
        };

    case resumeConstants.SET_DATE:
        return {
            ...state,
          date: action.date
        };
    default:
        return state
  }
}