import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core";
import "./style.css";

// install Swiper modules
SwiperCore.use([Pagination]);

const ApplicationReparateur = () => {
  return (
    <main className='page_application_reparateur'>
      <section className='hero'>
        <div className='container'>
          <div className='col_1'>
            <p className='test_appareils_pannes'>
              La (super) app des (supers) techniciens 🦸🏻‍♂️
            </p>
            <h1 className='main_title'>
              L'application quotidienne des réparateurs (où qu'ils soient) by
              Neero.
            </h1>
            <img
              className='main_image_mobile'
              src='/application_reparateur/hero.png'
              alt='Application Mobile Neero'
            />
            <p className='test_gratuit'>
              La première application mobile - tout en un - développée par et
              pour nos techniciens.
            </p>
            <div className='center'>
              <Link to='/reparateur' className='cta_button'>
                Devenir réparateur &nbsp; <i className='fas fa-chevron-right'></i>
              </Link>
            </div>
            <div className='stores'>
              <a
                href='https://play.google.com/store/apps/details?id=co.trackn.reparateur'
                target='_blank' rel='noopener'
              >
                <img src='/play_store.png' alt='Google Play Store' />
              </a>
              <a
                href='https://apps.apple.com/us/app/reparateur/id1549673793'
                target='_blank' rel='noopener'
              >
                <img src='/apple_store.png' alt='Apple Store' />
              </a>
            </div>
          </div>
          <div className='col_2'>
            <img
              className='bounce'
              src='/application_reparateur/hero.png'
              alt='Application Réparateur'
            />
          </div>
        </div>
      </section>

      <section className='utilite_app_neero'>
        <div className='container'>
          <div className='col_1'>
            <img
              className='bounce'
              src='/application_reparateur/Screenshots.png'
              alt="Que faire avec l'application Nerro"
            />
          </div>
          <div className='col_2'>
            <p className='yes_we_can'>Yes, we can !</p>
            <h2 className='main_title'>
              Des fonctionnalités spécifiques pour une utilisation sans faille
            </h2>
            <div className='second_title'>
              <img
                src='/diag.png'
                alt='Diagnostic complet en moins de 5 minutes'
              />
              <h3>
                Pourquoi une application mobile dédiée aux réparateurs Neero ?
              </h3>
            </div>
            <p className='description'>
              Parce que la réparation de smartphones et tablettes à domicile en
              atelier mobile requiert des process spécifiques et une
              organisation différente de celle d'un atelier fermé ! <br />
              <br />
              Nous avons centralisé l'intégralité des fonctionnalités sur notre
              application, celle-ci vous permettra de tout gérer en quelques
              clics. Le tout sur une interface simple, fluide et intuitive.
            </p>
            <div className='center'>
              <Link to='/reparateur' className='cta_button'>
                tester l'application &nbsp; <i className='fas fa-chevron-right'></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className='guidage_duree_diagnostic'>
        <div className='container'>
          <p className='desc'>Parce que c’est mieux en vrai</p>
          <h2 className='main_title'>On vous montre tout ça en images !</h2>

          <div className='slider_steps_diagnostic'>
            <Swiper
              pagination={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  centeredSlides: true,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  centeredSlides: true,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1000: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1400: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide>
                <img
                  className='swiper_slide_image'
                  src='/application_reparateur/slide_1.png'
                  alt=''
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className='swiper_slide_image'
                  src='/application_reparateur/slide_2.png'
                  alt=''
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className='swiper_slide_image'
                  src='/application_reparateur/slide_3.png'
                  alt=''
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className='swiper_slide_image'
                  src='/application_reparateur/slide_4.png'
                  alt=''
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className='swiper_slide_image'
                  src='/application_reparateur/slide_5.png'
                  alt=''
                />
              </SwiperSlide>
            </Swiper>
          </div>

          {/* <div className='center'>
            <Link className='link_reserver_reparation' to='/demande'>
              Réserver une réparation &nbsp;{" "}
              <i className='fas fa-chevron-right'></i>
            </Link>
          </div> */}
        </div>
      </section>

      <section className='compatibilite'>
        <div className='forme_violette desktop'>
          <img src='/forme_violette.png' alt='' />
        </div>
        <div className='container'>
          <div className='col_1'>
            <img
              className='bounce'
              src='/application_reparateur/Screenshot_2.png'
              alt='Compatibilite'
            />
          </div>
          <div className='col_2'>
            <p className='yes_we_can'>
              Faisons un petit tour des fonctionnalités de l'app
            </p>
            <h2 className='main_title'>
              Plus qu'un outil, l'app réparateur est le compagnon indispensable
              de tous nos techniciens, elle permet de (tout) faire :
            </h2>
            <div className='main_image_mobile'>
              <img
                className='main'
                src='/application_reparateur/Screenshot_2.png'
                alt='Compatibilite'
              />
            </div>
            <ul className='liste'>
              <li>
                <p>Gestion des plannings</p>
              </li>
              <li>
                <p>Encaissements et facturation</p>
              </li>
              <li>
                <p>Gestion de l'atelier mobile</p>
              </li>
              <li className=''>
                <p>Gestion des commandes</p>
              </li>
              <li>
                <p>Optimisation des parcours</p>
              </li>
              <li>
                <p>Gestion CRM</p>
              </li>
              <li>
                <p>Gestion des stocks</p>
              </li>
              <li className=''>
                <p>Chat & Appel in-App</p>
              </li>
            </ul>
            <footer className='compta'>
              <p className='description'>Et bien plus...</p>
              <Link to='/reparateur' className='cta_button'>
                Devenir reparateur &nbsp; <i className='fas fa-chevron-right'></i>
              </Link>
            </footer>
          </div>
        </div>
      </section>

      <section className='tester_notre_app'>
        <div className='container'>
          <img
            src='/elipses-left-bottom.png'
            alt=''
            className='elipses_left_bottom'
          />
          <img
            src='/elipses-right-top.png'
            alt=''
            className='elipses_right_top'
          />
          <img src='/elipses_mobile.png' alt='' className='elipses_mobile' />
          <div className='col_1'>
            <h2 className='title'>
              Rejoignez l’équipe Neero et devenez (super) technicien 🦸🏻‍♂️
            </h2>
            <div className='center'>
              <Link to='/reparateur' className='cta_button'>
                devenir reparateur &nbsp; <i className='fas fa-chevron-right'></i>
              </Link>
            </div>
            <div className='download_app'>
              <a
                href='https://apps.apple.com/us/app/reparateur/id1549673793'
                target='_blank' rel='noopener'
              >
                <img
                  src='/apple_store.png'
                  alt='Apple Store'
                  className='app_store'
                />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=co.trackn.reparateur'
                target='_blank'
              >
                <img
                  src='/play_store.png'
                  alt='Google Play Store'
                  className='google_play'
                />
              </a>
            </div>
          </div>
          <div className='col_2'>
            <img
              className='bounce'
              src='/application_reparateur/iPhone 13 Pro.png'
              alt='Testez notre application Neero'
            />
          </div>
        </div>
      </section>

      <section className='section_contact'>
        <div className='container'>
          <h2 className='title'>Contact</h2>
          <h3 className='second_title'>
            Vous avez d'autres questions et vous souhaitez échanger avec notre
            équipe ?
          </h3>
          <div className='ctas'>
            <button
              onClick={() => window.$crisp.push(["do", "chat:open"])}
              className='cta'
            >
              par chat &nbsp; <i className='fas fa-chevron-right'></i>
            </button>
            <Link to='/contact' className='cta'>
              par email &nbsp; <i className='fas fa-chevron-right'></i>
            </Link>
          </div>
        </div>
        <div className='center'>
          <img src='/pre_footer.png' alt='' className='contact_image' />
        </div>
      </section>
    </main>
  );
};

export default ApplicationReparateur;
