import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={58}
    height={86}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.538.904C10.173 1.922 1.622 11.189.875 27.947h16.508c.207-5.805 3.447-10.144 9.48-10.784 5.921-.629 11.54.782 13.24 4.83 1.839 4.374-2.287 9.456-4.249 11.59-3.63 3.955-9.529 6.84-12.586 11.109-2.997 4.182-3.53 9.684-3.76 16.42h14.549c.204-4.307.49-8.431 2.45-11.108 3.186-4.348 7.945-6.385 11.933-9.82 3.81-3.281 7.817-7.237 8.498-13.36C58.981 8.499 44.023-.187 26.538.904Z"
      fill="#722CC8"
    />
    <path
      d="M26.912 85.188c5.073 0 9.185-4.05 9.185-9.047 0-4.996-4.112-9.046-9.185-9.046s-9.186 4.05-9.186 9.046c0 4.996 4.113 9.046 9.186 9.046Z"
      fill="#722CC8"
    />
  </svg>
)

export default SvgComponent
