export const orderConstants = {
    INIT: 'INIT_ORDER',
    SET_ORDERS: 'SET_ORDERS_ORDERS',
    SET_LOADING: 'SET_LOADING_ORDERS',
    ORDERS_FAILED: 'ORDERS_FAILED_ORDERS',

    SET_ORDER: 'SET_ORDER_ORDER',
    SET_ORDER_LOADING: 'SET_ORDER_LOADING_ORDER',
    ORDER_FAILED: 'ORDER_FAILED_ORDER',

};
