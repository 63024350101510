import { externals } from "../config";

const apiUrl = externals.apiUrl

export const slotsService = { getSlots };


function getSlots() {
    const requestOptions = {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    };

    return fetch(`${apiUrl}/api/v1/requester/free-slots`, requestOptions)
    .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
