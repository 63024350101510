import React, { useState, useEffect } from 'react';
import './index.css'
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import ButtonBStrap from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Spinner} from 'evergreen-ui'
import { connect } from 'react-redux';
import { userActions, alertActions } from '../../actions';
import { useStyles } from '../../components/CheckBoxStyles'
import { regex } from "../../config";
//import ReactPixel from 'react-facebook-pixel';

 
const reg = regex.email

const minTextcharacter = 2

const _useStyles = makeStyles((theme) => ({
    margin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    inputMargin: { 
      marginTop: theme.spacing(3)
    }
  }));

function RegisterPage(props) {
  const classesCB = useStyles();
  const classes = _useStyles();
  const [values, setValues] = useState({
    user: {
      nom: '',
      prenom: '',
      email: '',
      phone: '',
      raisonSociale: '',
      password: '',
      passwordConfirmation: '',
      adresse: '',
      adresseComplement: '',
      codePostal: '',
      ville: ''
    },
    valide:{
      nom: false,
      prenom: false,
      email: false,
      phone: false,
      password: false,
      passwordConfirmation: false,
      adresse: false,
      adresseComplement: true,
      codePostal: false,
      ville: false,
      radioChecked: false
    }
  });


  const [nomErrMsg, setnomErrMsg] = useState('');
  const [prenomErrMsg, setprenomErrMsg] = useState('');
  const [emailErrMsg, setemailErrMsg] = useState('');
  const [phoneErrMsg, setphoneErrMsg] = useState('');
  const [passwordErrorMsg, setpasswordErrorMsg] = useState('');
  const [passwordConfirmationErrMsg, setpasswordConfirmationErrMsg] = useState('');
  const [adresseErrMsg, setadresseErrMsg] = useState('');
  const [adresseComplementErrMsg, setadresseComplementErrMsg] = useState('');
  const [codePostalErrMsg, setcodePostalErrMsg] = useState('');
  const [villeErrMsg, setvilleErrMsg] = useState('');
  const [radioCheckedErrMsg, setradioCheckedErrMsg] = useState('');

  const [state, setState] = useState({
    showPassword: false,
    showPasswordConfirmation: false,
    loading: false,
    openDialog: false
  })

  const [optinChecked, setOptin] = useState(false);
  const [radioChecked, setRadio] = useState();
  const [openDialog, setOpenDialog] = useState();
  //const [CGUChecked, setCGUCheched] = React.useState(false)
  //const [CGUerrMsg, setCGUerrMsg] = React.useState('')
  const [optinErrMsg, setOptinErrMsg] = React.useState('')

  const handleRadioChange = (event) => {
    setRadio(event.target.value);
    setValues({
      user: { ...values.user },
      valide: { ...values.valide, radioChecked: true }
    })
    setradioCheckedErrMsg('')
  };
  
  useEffect(() => {
    //ReactPixel.pageView()
  },[])

  /*const cguChange = (e) => {
    setCGUCheched(e.target.checked)
    setCGUerrMsg('')
  }*/


  const optinChange = (e) => {
    setOptin(e.target.checked)
    setOptinErrMsg('')
  }

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword })
  };
  const handleClickShowPasswordConfirmation = () => {
    setState({ ...state, showPasswordConfirmation: !state.showPasswordConfirmation })
  };

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    const { value } = event.target;
    setValues({
      user: { ...values.user, [prop]: value },
      valide: { ...values.valide }
    })
    if (['nom','prenom','adresse','ville'].indexOf(prop) > -1) textValidate(prop, value)
    else if (prop === 'email') emailValidate(value)
    else if (prop === 'phone') phoneValidate(value)
    else if (prop === 'password') passwordValidate(value)
    else if (prop === 'passwordConfirmation') passwordConfirmationValidate(value)
    else if (prop === 'codePostal') CPValidate(value)
  }

  const textValidate = (prop, value) => {
    if(value.length < minTextcharacter){
      setValues({
        user: { ...values.user, [prop]: '' },
        valide: { ...values.valide, [prop]: false }
      })
      if (prop === 'nom') setnomErrMsg('Nom trop court')
      else if (prop === 'prenom') setprenomErrMsg('Prénom trop court')
      else if (prop === 'adresse') setadresseErrMsg('Adresse trop courte, ajouter une adresse valide')
      else if (prop === 'adresseComplement') setadresseComplementErrMsg('Adresse trop courte')
      else setvilleErrMsg('Text trop court')
    }
    else {
      setValues({
        user: { ...values.user, [prop]: value },
        valide: { ...values.valide, [prop]: true }
      })
      if (prop === 'nom') setnomErrMsg('')
      else if (prop === 'prenom') setprenomErrMsg('')
      else if (prop === 'adresse') setadresseErrMsg('')
      else if (prop === 'adresseComplement') setadresseComplementErrMsg('')
      else setvilleErrMsg('')
    }
  }

  const textValidate2 = () => {
    let tab = ['nom' , 'prenom' , 'adresse'  , 'ville' ]
    for (const value of tab ){
      if(values.user[value].length < minTextcharacter){ 
        setValues({
          user: { ...values.user, [value]: '' },
          valide: { ...values.valide, [value]: false }
        })
        if (value === 'nom') setnomErrMsg('Nom trop court')
        else if (value === 'prenom') setprenomErrMsg('Prénom trop court')
        else if (value === 'adresse') setadresseErrMsg('Adresse trop courte, ajouter une adresse valide')
        else if (value === 'adresseComplement') setadresseComplementErrMsg('Adresse trop courte')
        else setvilleErrMsg('Text trop court')
      }
      else {
        setValues({
          user: { ...values.user, [value]: value },
          valide: { ...values.valide, [value]: true }
        })
        if (value === 'nom') setnomErrMsg('')
        else if (value === 'prenom') setprenomErrMsg('')
        else if (value === 'adresse') setadresseErrMsg('')
        else if (value === 'adresseComplement') setadresseComplementErrMsg('')
        else setvilleErrMsg('')
      }
    }
  }

  const emailValidate = (text) => {
    if (reg.test(text) === false) {
        setValues({
          user: { ...values.user, email: '' },
          valide: { ...values.valide, email: false }
        }); 
        setemailErrMsg('Adresse e-mail invalide')
    }
    else {
        setValues({ 
          user: { ...values.user, email: text },
          valide: { ...values.valide, email: true }
        }); 
        setemailErrMsg('')
    }
  }

  const phoneValidate = (number) => {
    if (!regex.phone.test(number)) {
        setValues({
          user: { ...values.user, phone: '' },
          valide: { ...values.valide, phone: false }
        }); 
        setphoneErrMsg('Numero Invalide')
    }
    else {
        setValues({ 
          user: { ...values.user, phone: number },
          valide: { ...values.valide, phone: true }
        }); 
        setphoneErrMsg('')
    }
  }

  const CPValidate = (number) => {
    if (!regex.zip.test(number)) {
        setValues({
          user: { ...values.user, codePostal: '' },
          valide: { ...values.valide, codePostal: false },
        }); 
        setcodePostalErrMsg('Code Postal Invalide' )
    }
    else {
        setValues({ 
          user: { ...values.user, codePostal: number },
          valide: { ...values.valide, codePostal: true }
        }); 
        setcodePostalErrMsg('')
    }
  }

  const passwordValidate = (text) => {
    if (text.length < 8) {
      setValues({
        user: { ...values.user, password: '' },
        valide: { ...values.valide, password: false }
      }); 
      setpasswordErrorMsg('Saisir au moins 8 caractères')
    }
    else {
      setValues({ 
        user: { ...values.user, password: text },
        valide: { ...values.valide, password: true }
      });  
      setpasswordErrorMsg('')
    }
  }

  const passwordConfirmationValidate = (text) => {
    if (text === values.user.password) {
      setValues({
        user: { ...values.user, passwordConfirmation: text },
        valide: { ...values.valide, passwordConfirmation: true }    
      }); 
      setpasswordConfirmationErrMsg('')
    }
    else {
      setValues({ 
        user: { ...values.user, passwordConfirmation: '' },
        valide: { ...values.valide, passwordConfirmation: false }
      }); 
      setpasswordConfirmationErrMsg( 'Retapez votre mot de passe')
    }
  }
  
  const radioCheckedValidate = () =>{
    if (!values.valide.radioChecked) {setValues({ 
      user: { ...values.user },
      valide: { ...values.valide , radioChecked: false}
    });
    setradioCheckedErrMsg('Sélectionnez une civilité')
    }
    else {setValues({ 
      user: { ...values.user },
      valide: { ...values.valide , radioChecked: true}
    });
    setradioCheckedErrMsg('')
    }
  }

  const allTrue = (obj) => {
    let first = true
    for(var o in obj)
      if (first) first = false
      else
        if(!obj[o]) return false;
      
    return true;
  }

  const handleSubmit = (event) => {
    //console.log(values.valide)
    event.preventDefault();
    if (allTrue(values.valide)) {
      //if (optinChecked){
        setState({ loading: true });
        setValues({ 
          user: { ...values.user },
          valide: { ...values.valide }
        });
        setnomErrMsg('')
        setprenomErrMsg('')
        setemailErrMsg('')
        setphoneErrMsg('')
        setpasswordErrorMsg('')
        setpasswordConfirmationErrMsg('')
        setadresseErrMsg('')
        setadresseComplementErrMsg('')
        setcodePostalErrMsg('')
        setvilleErrMsg('')
        setradioCheckedErrMsg('')
        props.register({
          ...values.user,
          civility: radioChecked,
          optin: optinChecked
        }, true);
      //} else setOptinErrMsg("Veuillez cocher ce bouton")
    }
    else{ 
      setOpenDialog(true)
      emailValidate(values.user.email)
      phoneValidate(values.user.phone)
      passwordValidate(values.user.password)
      passwordConfirmationValidate(values.user.passwordConfirmation)
      CPValidate(values.user.codePostal)
      radioCheckedValidate()
      textValidate2()
    }
  }

  const history = useHistory();

  const handleCloseDialog = () =>{
    setOpenDialog(false)
  }

  useEffect(() => {
    if (props.alert.type === 'alert-danger')  setState({...state, openDialog: true, loading: false})
    //if (props.alert.type === 'alert-success')  setState({...state, openDialog: true, loading: false})
    if (props.alert.type === 'alert-success') {
      setState({ ...state, loading: false}) 
      //ReactPixel.track('CompleteRegistration', null)
      /*window.gtag('event', 'conversion', {
        'send_to': 'AW-403051804/kXAjCIXE_IoDEJyqmMAB',
      });*/
      window.dataLayer.push({'event':'userInscription'});
      history.push('/espace')
      props.clearAlert()
    }
    //eslint-disable-next-line
  }, [props.alert.message])

  const handleClose = () => {
    setState({ ...state, loading: false, openDialog: false}) 
    if (props.alert.type === 'alert-success') {
     // ReactPixel.track('CompleteRegistration', null)
      window.dataLayer.push({
        event: 'inscription',
      });
      history.push('/espace')
    }
    props.clearAlert()
  } 

  return (
    
    <div className="espace-background-register" >
      <div className='espace-background-card'>
        <div style={{paddingTop: 20}}/>
        <div className='register-card'>
          <form className='register' onSubmit={handleSubmit} >
            <h2>Inscription</h2>
            <h3>Informations personnelles</h3>
            <h4>Civilité <em>*</em></h4>
            <RadioGroup aria-label="Civilité" name="Civilité1" value={radioChecked} onChange={handleRadioChange}>
              <div style={{ flexDirection: 'row'}}>
                <FormControlLabel value="Ms" control={<Radio color='default' />} label="Monsieur" />
                <FormControlLabel value="Md" control={<Radio color='default'/>} label="Madame" />
              </div>
            </RadioGroup>
            <em>{radioCheckedErrMsg}</em>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-prenom"
                  label="Prénom"
                  required
                  type="text"
                  onChange={handleChange('prenom')}
                  variant="outlined"
                  color={values.valide.prenom ? "primary" : "secondary"}
              />
              <em>{prenomErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-nom"
                  label="Nom"
                  required
                  type="text"
                  onChange={handleChange('nom')}
                  variant="outlined"
                  color={values.valide.nom ? "primary" : "secondary"}
              />
              <em>{nomErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-email"
                  label="E-mail"
                  required
                  type="email"
                  onChange={handleChange('email')}
                  variant="outlined"
                  color={values.valide.email ? "primary" : "secondary"}
              />
              <em>{emailErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-phone"
                  label="Téléphone"
                  required
                  type="tel"
                  onChange={handleChange('phone')}
                  variant="outlined"
                  color={values.valide.phone ? "primary" : "secondary"}
              />
              <em>{phoneErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-raisonSociale"
                  label="Raison sociale"
                  type="text"
                  onChange={handleChange('raisonSociale')}
                  variant="outlined"
              />
            </FormControl>
            <h3>Mot de passe</h3>
            <FormControl className={clsx(classes.margin)} variant="outlined">
              <InputLabel  color={values.valide.password ? "primary" : "secondary"}  htmlFor="outlined-adornment-password">
                  Mot de passe *
              </InputLabel>
              <OutlinedInput
                  id="outlined-adornment-password"
                  type={state.showPassword ? 'text' : 'password'}
                  onChange={handleChange('password')}
                  endAdornment={
                  <InputAdornment position="end">
                      <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      >
                      {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                  }
                  labelWidth={110}
                  color={values.valide.password ? "primary" : "secondary"}
              />
              <em>{passwordErrorMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.margin)} variant="outlined">
              <InputLabel  color={values.valide.passwordConfirmation ? "primary" : "secondary"}  htmlFor="outlined-adornment-passwordConfirmation">
                Confirmation mot de passe *
              </InputLabel>
              <OutlinedInput
                  id="outlined-adornment-passwordConfirmation"
                  type={state.showPasswordConfirmation ? 'text' : 'password'}
                  onChange={handleChange('passwordConfirmation')}
                  endAdornment={
                  <InputAdornment position="end">
                      <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirmation}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      >
                      {state.showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                  }
                  labelWidth={210}
                  color={values.valide.passwordConfirmation ? "primary" : "secondary"}
              />
              <em>{passwordConfirmationErrMsg}</em>
            </FormControl>
            <h3>Adresse de facturation</h3>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-adresse"
                  label="Adresse"
                  required
                  type="text"
                  onChange={handleChange('adresse')}
                  variant="outlined"
                  color={values.valide.adresse ? "primary" : "secondary"}
              />
              <em>{adresseErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-adresseComplement"
                  label="Complément d’adresse"
                  type="text"
                  onChange={handleChange('adresseComplement')}
                  variant="outlined"
                  color={values.valide.adresseComplement ? "primary" : "secondary"}
              />
              <em>{adresseComplementErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-codePostal"
                  label="Code Postal"
                  required
                  type="text"
                  onChange={handleChange('codePostal')}
                  variant="outlined"
                  color={values.valide.codePostal ? "primary" : "secondary"}
              />
              <em>{codePostalErrMsg}</em>
            </FormControl>
            <FormControl className={clsx(classes.inputMargin)}>
              <TextField
                  id="outlined-error-ville"
                  label="Ville"
                  required
                  type="text"
                  onChange={handleChange('ville')}
                  variant="outlined"
                  color={values.valide.ville ? "primary" : "secondary"}
              />
              <em>{villeErrMsg}</em>
            </FormControl>
            <div className='check-box' style={{marginTop: 10}}>
              <Checkbox 
                    className={classesCB.root}
                    disableRipple
                    checkedIcon={<span className={clsx(classesCB.icon, classesCB.checkedIcon)} />}
                    icon={<span className={classesCB.icon} />}
                    checked={optinChecked} 
                    style ={{ backgroundColor: 'white'}} 
                    onChange={optinChange} 
                />
                <h5 >Je souhaite recevoir les promotions et nouveautés de Neero</h5>
            </div>
             <em>{optinErrMsg}</em>
             { state.loading ? <Spinner style={{ marginTop: 40}}/>: null}
            {/*<div className='check-box'>
              <Checkbox 
                  className={classesCB.root}
                  disableRipple
                  checkedIcon={<span className={clsx(classesCB.icon, classesCB.checkedIcon)} />}
                  icon={<span className={classesCB.icon} />}
                  checked={CGUChecked} 
                  style ={{ backgroundColor: 'white'}} 
                  onChange={cguChange}
              />
              <h5 >Accépter les CGU</h5>
              { state.loading ? <Spinner style={{alignSelf: 'center', marginLeft: 50}}/>: null}
            </div>*/}
            <div className='check-box'>
              <ButtonBStrap className="btn-gradient" onClick={handleSubmit}>
                  <u>S'inscrire</u>
              </ButtonBStrap>
            </div>
            <h4 className='oblig'><em>*</em> Champs obligatoires</h4>
          </form>
          <Dialog
            fullWidth={true}
            open={false}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='dialog-register'>
            <DialogTitle id="alert-dialog-title"><h1>Attention</h1></DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <h2>Veuillez remplir tous les champs obligatoires</h2>
              <p>Toutes ces informations nous sont nécessaires pour la création de votre compte et pour que nos techniciens puissent vous recontacter.</p>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <ButtonBStrap className="btn-gradient" onClick={handleCloseDialog} style={{margin: 10}}>
                <u>Ok, j'ai compris</u>
            </ButtonBStrap>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth={true}
            open={state.openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.alert.type === 'alert-success'? "Inscription réussi": "Erreur"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.alert.type === 'alert-success'? "Vérifier la boîte mail pour valider votre compte": props.alert.message}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <ButtonBStrap className="btn-gradient" onClick={handleClose} style={{margin: 7}}>
                <u>{props.alert.type === 'alert-success'? "Ok": "Réessayer"}</u>
            </ButtonBStrap>
            </DialogActions>
          </Dialog>
          </div>
        </div>
      </div>
  );

}

function mapState(state) {
  const { alert } = state;
    const { registering } = state.registration;
    return { registering, alert };
}

const actionCreators = {
    register: userActions.register,
    clearAlert: alertActions.clear
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };