import React, { useState, useEffect } from 'react';
import './index.css';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from '../CheckBoxStyles';
import clsx from 'clsx';
import InterventionOffert from '../../assets/icons/interventionOffert.svg';
import InterventionUnvailable from '../../assets/icons/interventionSearching.png';
import InterventionUnknow from '../../assets/icons/interventionThinking.png';
import { externals, staticId, discounts } from '../../config';

const imagesUrl = externals.phoneImagesUrl;

function InterventionSelect(props) {
  const interventions = props.interventions;
  const classes = useStyles();
  const [discount, setDiscount] = useState({
    apply: false,
    maxId: null,
    maxPrice: null,
  });
  const [newMax, setNewMax] = useState(false);

  useEffect(() => {
    let exist =interventions ? interventions.filter(function (obj) {
      return obj.isChecked === true;
    }) : [];
    if (!discount.apply && exist.length > 0) {
      let maxPrice = 0;
      let maxId;
      for (const obj of exist) {
        if (obj.price > maxPrice) {
          maxPrice = obj.price;
          maxId = obj.uuid;
        }
      }
      setDiscount({ apply: true, maxId: maxId, maxPrice: maxPrice });
      setNewMax(!newMax);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  const Interv = (prop) => {
    const [checked, setChecked] = useState(prop.intervention.isChecked);
    const [price, setPrice] = useState({
      price: prop.intervention.price,
      dicounted: false,
    });
    let imageUrl = imagesUrl + prop.intervention.image;

    var id = prop.intervention.uuid

    useEffect(() => setChecked(prop.intervention.isChecked), [prop]);

    useEffect(
      () => {
        if (
          discount.apply &&
          checked &&
          !price.dicounted &&
          // prop.intervention.id !== discount.maxId
          id !== discount.maxId
        )
          setPrice({
            price: price.price * discounts.interventionDiscount,
            dicounted: true,
          });
        //if (discount.apply && checked && price.dicounted && prop.intervention.id === discount.maxId)
        /*if (!discount.apply && checked ) {
                let max = Math.max.apply(Math, interventions.map(function(obj) { return obj.price; }))
                if (price.price === max )
                    setDiscount({apply: true, maxId: prop.intervention.id, maxPrice: price.price})
            }*/
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [discount]
    );

    const onChecked = (e) => {
      setChecked(e.target.checked);
      // props.onSelect(prop.intervention.id, e.target.checked);
      props.onSelect(id, e.target.checked);
      if (e.target.checked) {
        if (!discount.apply)
          setDiscount({
            apply: true,
            // maxId: prop.intervention.id,
            maxId: id,
            maxPrice: price.price,
          });
        else {
          if (price.price <= discount.maxPrice)
            setPrice({
              price: price.price * discounts.interventionDiscount,
              dicounted: true,
            });
          else
            setDiscount({
              apply: true,
              // maxId: prop.intervention.id,
              maxId: id,
              maxPrice: price.price,
            });
        }
      } else {
        setPrice({ price: prop.intervention.price, dicounted: false });
        // if (prop.intervention.id === discount.maxId)
        if (id === discount.maxId)
          setDiscount({ apply: false, maxId: null, maxPrice: null });
      }
    };

    const onCheckedStatic = (e) => {
      setChecked(e.target.checked);
      // props.onSelect(prop.intervention.id, e.target.checked);
      props.onSelect(id, e.target.checked);
      setDiscount({ apply: false, maxId: null, maxPrice: null });
    };
    return price.price ? (
      <div style={{ marginTop: -60, marginBottom: 60 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {prop.intervention.image ? (
            <img
              alt="img"
              className="interv-icon"
              src={imageUrl}
              //alt={prop.intervention.name}
            />
          ) : (
            <div
              className="interv-icon-default"
              style={{ backgroundImage: `url(${InterventionOffert})` }}
            />
          )}

          <div className="interv-row-container">
            <div style={{ flex: 2 }}>
              <p>
                {
                  prop.intervention.publicIntervention.name
                }
              </p>
            </div>
            <div className="interv-row-price">
              <h1>{parseFloat(price.price).toFixed(2)}€</h1>
              {price.dicounted ? (
                <h2>{parseFloat(prop.intervention.price).toFixed(2)}</h2>
              ) : (
                // eslint-disable-next-line
                <h2></h2>
              )}
            </div>
            <Checkbox
              disabled={prop.intervention.disabled}
              className={classes.root}
              disableRipple
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              checked={checked}
              style={{ backgroundColor: 'white' }}
              onChange={onChecked}
            />
          </div>
        </div>
        <div className="interv-line" />
      </div>
    ) : (
      <div>
        {/* {prop.intervention.id === staticId.InterventionUnvailable ? ( */}
        {id === staticId.InterventionUnvailable ? (
          <div className="interv-line" />
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <img
            alt="img"
            className="interv-icon-static"
            src={
              // prop.intervention.id === staticId.InterventionUnvailable
              id === staticId.InterventionUnvailable
                ? InterventionUnvailable
                : InterventionUnknow
            }
            //alt={prop.intervention.name}
          />
          <div className="interv-row-container">
            <div>
              <p>{prop.intervention.publicIntervention.name}</p>
            </div>
            <h1></h1> {/*eslint-disable-line*/}
            <Checkbox
              className={classes.root}
              disableRipple
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              checked={checked}
              style={{ backgroundColor: 'white' }}
              onChange={onCheckedStatic}
            />
          </div>
        </div>
        <div className="interv-line" />
      </div>
    );
  };

  const listInterv = () => {
    return interventions.map((intervention) => {
      return (
        // Correct! Key should be specified inside the array.
        // <Interv key={intervention.id} intervention={intervention} />
        <Interv key={intervention.uuid } intervention={intervention} />
      )
    });
  };

  return (
    <div className="container-interv">
      {interventions && interventions.length ? (
        <div>
          <div className="title-row-container">
            <p style={{ width: props.isSmallScreen ? 80 : 200, flex: 2 }}>
              Type de panne
            </p>
            <div className="interv-row-price">
              <p style={{ flex: 1 }}>Prix TTC</p>
            </div>
          </div>
          <div className="interv-line" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div
              className="interv-icon-default"
              style={{ backgroundImage: `url(${InterventionOffert})` }}
            />
            <div className="interv-row-container">
              <div style={{ flex: 2 }}>
                <p>Déplacement technicien certifié</p>
              </div>
              <div className="interv-row-price">
                <h1>Offert</h1>
                <h2></h2> {/*eslint-disable-line*/}
              </div>
              <Checkbox
                className={classes.root}
                disableRipple
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                checked
                disabled
                style={{ backgroundColor: 'white' }}
              />
            </div>
          </div>
          <div className="interv-line" style={{ marginBottom: 60 }} />
          {listInterv()}
        </div>
      ) : (
        <div>
          <h4> Aucune réparation n'est disponible pour ce modle</h4>
        </div>
      )}
    </div>
  );
}

export default InterventionSelect;
