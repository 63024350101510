import { authHeader } from '../helpers';
import { externals } from "../config";
import { userService } from "./user.service"

const apiUrl = externals.apiUrl

export const demandService = {
    setDemand
};

function setDemand(demand, resume, coupon) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            "model": demand.model.uuid.toString(),
            "interventions": resume.price.map((obj) => ({ "uuid":  obj.uuid.toString() })),
            "address": demand.address,
            "appointmentSlots": resume.date.map((obj) => ({ "slotDate": obj.date, "slotTime": obj.slotTime })),
            "voucher": coupon
          })
    };


    return fetch(`${apiUrl}/api/v1/requester/request-order`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) userService.refresh(JSON.parse(localStorage.getItem('user')).refresh_token)
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}