import "./index.css";
import { useEffect } from "react";
import ButtonBStrap from "react-bootstrap/Button";
//import ReactPixel from 'react-facebook-pixel';
import RepairPhoneJpg from "../../assets/images/repairPhone.jpg";
import ImageR1 from "../../assets/images/imageR1.png";
import ImageR2 from "../../assets/images/imageR2.png";
import StarSvg from "../../assets/icons/star.svg";
import {
  EngagementsItems,
  InterventionsItems,
  ReviewsItems,
} from "../../config/staticData";
import FooterV2 from "../../components/FooterV2";
import { useHistory } from "react-router-dom";
import { socialMediaUrl } from "../../config";

export function RepairPage() {
  const history = useHistory();
  const goToBooking = () => {
    history.push("/demande");
  };
  useEffect(() => {
    //ReactPixel.pageView()
  }, []);

  const setRating = nb => {
    let stars = [];
    for (var i = 0; i < nb; i++) {
      stars.push(i);
    }
    return (
      <div
        className='rating-container'
        style={{ display: "flex", flexDirection: "row" }}
      >
        {stars.map((s, index) => (
          <div key={index} style={{ backgroundImage: `url(${StarSvg})` }} />
        ))}
      </div>
    );
  };

  return (
    <>
      <div className='repair-container'>
        <div className='repair-title'>
          <h1>On remet tous vos Appareils en état</h1>
          <p>
            A domicile, au travail ou ailleurs, on intervient là où vous voulez.
          </p>
          <ButtonBStrap className='btn-gradient' onClick={goToBooking}>
            <u>Réserver une intervention</u>
          </ButtonBStrap>
        </div>
        <div className='repair-main-image'>
          <div style={{ backgroundImage: `url(${RepairPhoneJpg})` }} />
        </div>
        <div className='repair-first-block'>
          <h2>Les engagements Neero ❤</h2>
          <p>Ne perdez plus de temps à chercher où déposer vos appareils !</p>
          <p>
            Nos techniciens vous apportent leur expertise à l’endroit de votre
            choix. Au volant de nos ateliers mobiles 🚐, le technicien Neero
            vous rejoint à l'adresse de votre choix pour une intervention sur
            place en 30 minutes.
          </p>
          <div className='repair-engage'>
            {EngagementsItems.map((item, index) => {
              return (
                <div className='repair-engage-item' key={index}>
                  {item.icon}
                  <p>{item.title}</p>
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonBStrap className='btn-gradient' onClick={goToBooking}>
              <u>{"réserver ma réparation".toUpperCase()}</u>
            </ButtonBStrap>
          </div>
        </div>
        <div className='repair-second-block'>
          <div className='repair-sb'>
            <img src={ImageR1} alt='' className='repair-sb-img-1' />
            <div>
              <h2>
                Une intervention en moins de 30 minutes où vous le souhaitez !
              </h2>
              <p>
                L’atelier mobile vous rejoint à l'adresse de votre choix pour
                une remise à neuf sur place.
              </p>
            </div>
          </div>
          <div className='repair-sb'>
            <img src={ImageR2} alt='' className='repair-sb-img-2-mobile' />
            <div>
              <h2>Le lieu de rendez-vous ?</h2>
              <p>
                A domicile, en bas de vos bureaux, devant un centre commercial,
                une gare, ou même un parc. Pendant ces petites 30 minutes, vous
                avez le choix : vaquer à vos occupations, finir une réunion ou
                discuter avec le technicien à bord de l’atelier mobile.
              </p>
              <ButtonBStrap className='btn-gradient' onClick={goToBooking}>
                <u>Voir les disponibilités</u>
              </ButtonBStrap>
            </div>
            <img src={ImageR2} alt='' className='repair-sb-img-2' />
          </div>
          <div className='repair-sb-s'>
            <h2>Une intervention de 30mn pour 12 mois de sérénité</h2>
            <p>
              Nos interventions de remise en état de vos appareils sont
              assorties d'une garantie de 12 mois.
            </p>
            <p>
              Quelque soit votre dysfonctionnement, nos techniciens vous
              apporteront la solution pour une remise en état sans tracas. On
              intervient sur toutes vos pannes{" "}
            </p>
          </div>
          <div className='repair-interv'>
            {InterventionsItems.map((item, index) => {
              return (
                <div className='repair-interv-item' key={index}>
                  {item.icon}
                  <h4>{item.title}</h4>
                </div>
              );
            })}
          </div>
          <div className='rapair-last-block'>
            <h2>Nous avons pris soin de leurs smartphone</h2>
            <small
              onClick={e => {
                e.preventDefault();
                window.open(socialMediaUrl.comments, "_blank");
              }}
            >
              Voir plus de commentaires
            </small>
          </div>
        </div>
        <div className='review-scroll-block'>
          <div className='review-scroll'>
            {ReviewsItems.map((item, index) => (
              <div className='review-scroll-item' key={index}>
                <div>
                  <h3>{item.name}</h3>
                  {setRating(item.rating)}
                  <p>{item.text}</p>
                  <small>{item.date}</small>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
          >
            <ButtonBStrap className='btn-gradient' onClick={goToBooking}>
              <u>Voir les disponibilités</u>
            </ButtonBStrap>
          </div>
        </div>
        <div className='repair-footer'>
          <p>
            (1) : 90% de nos interventions sont faites sur place, certaines
            nécessitent une immobilisation de 24h. Nous consulter pour plus
            d'informations.
          </p>
          <p>
            (2) : Hors oxydation, casses, dommages externes et garantie
            batteries 3 mois.
          </p>
        </div>
      </div>
      <FooterV2 />
    </>
  );
}
