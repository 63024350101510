import React, {useContext, useState, useEffect}  from 'react';
import GoogleMapsAutocomplete from '../../components/GoogleMapsAutocomplete';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
//import ReactPixel from 'react-facebook-pixel';
import { SmallScreenContext } from "../../helpers";
import { CITIES_TAG } from "../../config/staticData";
import './index.css'
import { demandActions, resumeActions, settingsActions } from '../../actions';
import { connect } from 'react-redux';
import { zipDefault } from "../../config";
import MAP from "../../assets/images/footer-map.png";
import RightArrowSvg from "../../assets/svgFiles/rightArrow";

function FooterV2(props){
    const [isAddressOption, setIsAddressOption] = useState(false);
    const [addressInput, setAddressInput] = useState('');
    const [valideGMAdress, setValideGMAdress] = useState(false);
  
    const history = useHistory();
  
    const context = useContext(SmallScreenContext);

    const handleAddressInputChange = (input) => {
      setAddressInput(input)
      setIsAddressOption(false)
    }
  
    useEffect(() => {
      //ReactPixel.pageView()
    },[])
  
    const handleAddressOptionChange = (autocompleteresult, geocodeResult) => {
      let zipCode = geocodeResult.address_components.filter(function( obj ) { return obj.types[0] === "postal_code" })
      let city = geocodeResult.address_components.filter(function( obj ) { return obj.types[0] === "locality" })[0].long_name //administrative_area_level_2
      let address = {
        "address1": autocompleteresult.structured_formatting.main_text,
        "address2": autocompleteresult.structured_formatting.secondary_text,
        "city": city,
        "zip": zipCode.length>0 ? zipCode[0].long_name: zipDefault.notFound,
        "longitude": geocodeResult.geometry.location.lng(),
        "latitude": geocodeResult.geometry.location.lat(),
      }
      //ReactPixel.track('SEARCH', null)
      settingsActions.checkAddress(address)
        .then((res) => {
          if (res.available){
            setValideGMAdress(true)
            setIsAddressOption(true)
            props.setAddressResume(autocompleteresult.description)
            props.setAddressDemand(address)
          }
          else{
            setValideGMAdress(false)
            props.setAddressResume(null)
            props.setAddressDemand(null)
          }
          setIsAddressOption(true)
        })
        .catch((error) =>  {
          //console.log('*****',error)
        })
    }
  
    const handleSubmit = () => {
      if(!isAddressOption) {
        let address = {
          "address1": addressInput,
          "address2": "",
          "city": addressInput,
          "zip": zipDefault.notUsed,
          "longitude": 0,
          "latitude": 0
        }
        settingsActions.checkAddress(address)
        .then((res) => {
          if (res.available){
            props.setAddressResume(addressInput)
            props.setAddressDemand(address)
          }
          else{
            setAddressInput("")
            setIsAddressOption("")
            props.setAddressResume(null)
            props.setAddressDemand(null)
            history.push('/address-unavailable')
          }
        })
        .catch((error) =>  {

        })
      }
      else{
        if (valideGMAdress) {
          context.hideMenu()
          history.push('/demande')
        }
        else {
          setAddressInput("")
          history.push('/address-unavailable')
        }
      }
    }

    const renderCitiesTag = () => (
        <div className='footerV2-cities'>
        {
            CITIES_TAG.map((city, index)=> 
                <div className='footerV2-city' key={index}>
                    <h3>{city.name}<span>{city.indice}</span></h3>
                </div>
            )
        }
        </div>
    )
    const renderMap = () => (
         <div className='footerV2-map-container'>
            <div className='footerV2-map' style={{backgroundImage: `url(${MAP})`}}/>
        </div>
    )
    return (
        <>
        <div className='footerV2-container'>
            <div className='footeV2-block'>
                <h1>Des interventions là où vous avez besoin <span role="img" aria-label="sheep">📍</span></h1>
                <p>Neero est présent dans 6 départements d’Ile-de-France.<br/>Nous proposons aussi des solutions hors de ces zones.</p>
                <h6>Où souhaitez-vous que l'on vienne ? </h6>
                <div className='footerV2-address'>
                    <GoogleMapsAutocomplete 
                        optionCallback={handleAddressOptionChange} 
                        inputCallback={handleAddressInputChange} 
                        width={context.isSmallScreen? 330: 350}
                    />
                    <Button className="btn-gradient-2"  onClick={() => handleSubmit()}>
                        <u>{("réserver ma réparation").toUpperCase()}</u>
                        <RightArrowSvg/>
                    </Button>
                </div>
                {context.isSmallScreen ?renderMap(): renderCitiesTag()}
            </div>
            {context.isSmallScreen? renderCitiesTag(): renderMap()}
        </div>
        <div className='repair-footer'>
            <p>(1) : 90% de nos interventions sont faites sur place, certaines nécessitent une immobilisation de 24h. Nous consulter pour plus d'informations.</p>
            <p>(2) : Hors oxydation, casses, dommages externes et garantie batteries 3 mois.</p>
        </div>
        </>
    );
}


const actionCreators = {
    setAddressDemand: demandActions.setAddress,
    setAddressResume: resumeActions.setAddress,
  };
  
  const connectedFooterV2 = connect(null, actionCreators)(FooterV2);
  export default connectedFooterV2;