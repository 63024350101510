import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core";
import "./style.css";

// install Swiper modules
SwiperCore.use([Pagination]);

const ApplicationMobile = () => {
  return (
    <main className='page_application_mobile'>
      <section className='hero'>
        <div className='container'>
          <div className='col_1'>
            <p className='test_appareils_pannes'>
              Testez vos appareils vous-même et identifiez les pannes en
              quelques minutes.
            </p>
            <h1 className='main_title'>
              Neero, la première application qui fait le diagnostic de vos
              smartphones et tablettes !
            </h1>
            <img
              className='main_image_mobile'
              src='/image_hero.png'
              alt='Application Mobile Neero'
            />
            <p className='test_gratuit'>
              Testez notre application gratuitement dès maintenant !
            </p>
            <div className='stores'>
              <a
                href='https://apps.apple.com/us/app/neero/id1587685748?itscg=30200&itsct=apps_box_link'
                target='_blank' rel='noopener'
              >
                <img src='/apple_store.png' alt='Apple Store' />
              </a>
              <a
                target='_blank' rel='noopener'
                href='https://play.google.com/store/apps/details?id=co.trackn.neero'
              >
                <img src='/play_store.png' alt='Google Play Store' />
              </a>
            </div>
            <div className='review'>
              <img
                className='main_icon'
                src='/dashicons_smiley.png'
                alt='Smile'
              />
              <div className='text'>
                <p className='first'>+ 2 500</p>
                <p className='second'>sourires retrouvés</p>
              </div>
              <div className='separator'></div>
              <div className='note_stars'>
                <p className='first'>4.9/5</p>
                <div className='stars'>
                  <i className='fas fa-star'></i>
                  <i className='fas fa-star'></i>
                  <i className='fas fa-star'></i>
                  <i className='fas fa-star'></i>
                  <i className='far fa-star'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col_2'>
            <img
              className='bounce'
              src='/image_hero.png'
              alt='Application Mobile Neero'
            />
          </div>
        </div>
      </section>

      <section className='utilite_app_neero'>
        <div className='container'>
          <div className='col_1'>
            <img
              className='bounce'
              src='/iPhone-13-Pro-Front.png'
              alt="Que faire avec l'application Nerro"
            />
          </div>
          <div className='col_2'>
            <p className='yes_we_can'>Yes, we can !</p>
            <h2 className='main_title'>
              Que peut-on faire avec l'application Neero ?
            </h2>
            <div className='second_title'>
              <img
                src='/diag.png'
                alt='Diagnostic complet en moins de 5 minutes'
              />
              <h3>Diagnostic complet en moins de 5 minutes</h3>
            </div>
            <p className='description'>
              Comme 92% des appareils en circulation, votre smartphone ou
              tablette fonctionne au rythme des tâches qu’on leur afflige au
              quotidien : Appels, réseaux, vidéos.... Tout ça finit par user
              votre appareils et sans que vous ne vous rendiez compte, une panne
              peut survenir le rendant inutilisable (le drame 😱 !!) <br />
              <br />
              Pour vous prémunir de ce genre de mauvaises surprises,
              l’application neero vous permet de réaliser des diagnostics
              complets, gratuits et à tout moment de tous vos appareils pour que
              vous puissiez évaluer leur état régulièrement et anticiper les
              besoins.
            </p>
          </div>
        </div>
      </section>

      <section className='etapes_reservation'>
        <div className='container'>
          <div className='col_1'>
            <ul>
              <li>
                <header>
                  <img src='/icon_step.png' alt='Faire un devis' />
                  <h3>Faire un devis</h3>
                </header>
                <p className='description'>
                  Parce qu’il est indispendable de connaitre le montant
                  (éventuel) des réparations dont a besoin votre petit précieux,
                  l’application Neero vous propose à l’issue de chaque
                  diagnostic complet un rapport détaillé accompagné d’un devis
                  précis couvrant les dysfonctionnements que vous avez relevé.
                </p>
              </li>
              <li>
                <header>
                  <img src='/reserver_step.png' alt='Réserver' />
                  <h3>Réserver</h3>
                </header>
                <p className='description'>
                  Nous l’avons aussi inventé 😁 . Vous avez fait un diagnostic
                  complet de votre appareil, consulté le rapport de tests et
                  validé le devis ? Vous pouvez alors poursuivre simplement le
                  process de réservation pour le faire réparer. Vous choisissez
                  l’heure et le lieu de l’intervention et un technicien vient à
                  vous.
                </p>
              </li>
              <li>
                <header>
                  <img src='/controle_step.png' alt='Garder le contrôle' />
                  <h3>Garder le contrôle</h3>
                </header>
                <p className='description'>
                  Parce que votre sérénité n’a pas de prix, l’application Neero
                  vous permet de garder un oeil sur la santé de vos appareils
                  durant toute leur durée de vie, anticiper les bobos et pouvoir
                  les réparer quand il faut. Mais ce n’est pas tout, de
                  nouvelles surprises arrivent sur les mises à jour. Stay tunned
                  🤓.
                </p>
              </li>
            </ul>
          </div>
          <div className='col_2'>
            <img
              className='main_image bounce'
              src='/steps_image.png'
              alt='Faire un devis, réserver et garder le contrôle'
            />
            <img src='/ombre_violette.png' alt='' className='ombre_violette' />
          </div>
        </div>
      </section>

      <section className='guidage_duree_diagnostic'>
        <div className='container'>
          <p className='desc'>
            Tester vos appareils sur plus de 30 points de contrôle !
          </p>
          <h2 className='main_title'>
            L'application vous guide pas à pas durant toute la durée du
            diagnostic
          </h2>

          <div className='slider_steps_diagnostic'>
            <Swiper
              pagination={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  centeredSlides: true,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                  centeredSlides: true,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 3,
                  spaceBetween: 100,
                },
              }}
            >
              <SwiperSlide>
                <img
                  className='swiper_slide_image'
                  src='/steps_diagnostic/step1.png'
                  alt=''
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className='swiper_slide_image'
                  src='/steps_diagnostic/step2.png'
                  alt=''
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className='swiper_slide_image'
                  src='/steps_diagnostic/step3.png'
                  alt=''
                />
              </SwiperSlide>
            </Swiper>
          </div>

          <div className='center'>
            <Link className='link_reserver_reparation' to='/demande'>
              Réserver une réparation &nbsp;{" "}
              <i className='fas fa-chevron-right'></i>
            </Link>
          </div>
        </div>
      </section>

      <section className='compatibilite'>
        <div className='forme_violette desktop'>
          <img src='/forme_violette.png' alt='' />
        </div>
        <div className='container'>
          <div className='col_1'>
            <img
              className='bounce'
              src='/compatibilite.png'
              alt='Compatibilite'
            />
          </div>
          <div className='col_2'>
            <p className='yes_we_can'>Compatibilité</p>
            <h2 className='main_title'>
              L'application Neero est compatible avec la majorité des modèles de
              smartphones et tablettes commercialisés sur les 10 dernières
              années.
            </h2>
            <div className='main_image_mobile'>
              <img
                className='main'
                src='/main_image_compta_mobile.png'
                alt='Compatibilite'
              />
            </div>
            <ul className='liste'>
              <li>
                <p>
                  Tous les appareils sur iOS 10 et ultérieur et à partir de
                  Android Lollipop 5.
                </p>
              </li>
              <li>
                <p>
                  Vous pouvez installer et tester tous vos appareils dès à
                  présent
                </p>
              </li>
              <li className='last'>
                <p>Liste des marques compatibles :</p>
              </li>
            </ul>
            <p className='description'>
              Apple, Samsung, Huawei, Xiaomi, Oppo, OnePlus, Google, Motorola,
              Sony, Wiko ... Et toutes les autres (y compris la votre)
            </p>
          </div>
        </div>
      </section>

      <section className='faq'>
        <div className='container'>
          <h2 className='title'>Notre FAQ</h2>
          <p className='desc'>Vos questions, nos réponses 😊</p>
          <div className='grid'>
            <div className='grid_item'>
              <h3 className='grid_title'>L'application est-elle gratuite ?</h3>
              <p className='grid_desc'>
                Oui ! L'application Neero est 100% gratuite et elle le restera.
              </p>
            </div>
            <div className='grid_item'>
              <h3 className='grid_title'>Combien coûte un diagnostic ?</h3>
              <p className='grid_desc'>
                Le téléchargement ainsi que l'utilisation de l'application est
                totalement gratuit et sans limite de tests.
              </p>
            </div>
            <div className='grid_item'>
              <h3 className='grid_title'>Comment utiliser l'application ?</h3>
              <p className='grid_desc'>
                L'application a été conçue pour une utilisation simple et
                intuitive. Pour commencer votre diagnostic, il suffit de lancer
                les tests dans le section dédiée.
              </p>
            </div>
            <div className='grid_item'>
              <h3 className='grid_title'>
                Combien de temps dure un diagnostic ?
              </h3>
              <p className='grid_desc'>
                L'application vous offre 2 possibilités : Un diagnostic complet
                qui dure moins de 10mn, sinon vous pouvez opter pour le
                diagnostic express réalisable en 4mn.
              </p>
            </div>
            <div className='grid_item'>
              <h3 className='grid_title'>
                Comment faire pour avoir un devis ?
              </h3>
              <p className='grid_desc'>
                A la fin du diagnostic, vous consultez le rapport de tests et
                avez la possibilité d'afficher le devis des réparations
                correspondantes.
              </p>
            </div>
            <div className='grid_item'>
              <h3 className='grid_title'>
                Puis-je réserver une réparation depuis l'application ?
              </h3>
              <p className='grid_desc'>
                Bien sûr. Une fois le devis affiché, il suffit de l'accepter et
                renseigner vos informations pour valider l'intervention.
              </p>
            </div>
            <div className='grid_item'>
              <h3 className='grid_title'>
                Quels modèles sont compatibles avec l'app ?
              </h3>
              <p className='grid_desc'>
                La plupart des modèles sortis sur les 10 dernières années sont
                compatibles avec l'application Neero.
              </p>
            </div>
            <div className='grid_item'>
              <h3 className='grid_title'>
                Que faire si je ne comprend pas le diagnostic ?
              </h3>
              <p className='grid_desc'>
                En cas de besoin, l'équipe d'experts techniques qui a conçu
                l'application se tient à votre disposition pour répondre à vos
                questions 24h/7j.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='tester_notre_app'>
        <div className='container'>
          <img
            src='/elipses-left-bottom.png'
            alt=''
            className='elipses_left_bottom'
          />
          <img
            src='/elipses-right-top.png'
            alt=''
            className='elipses_right_top'
          />
          <img src='/elipses_mobile.png' alt='' className='elipses_mobile' />
          <div className='col_1'>
            <h2 className='title'>
              Testez notre application gratuitement dès maintenant !
            </h2>
            <div className='download_app'>
              <a
                target='_blank' rel='noopener'
                href='https://apps.apple.com/us/app/neero/id1587685748?itscg=30200&itsct=apps_box_link'
              >
                <img
                  src='/apple_store.png'
                  alt='Apple Store'
                  className='app_store'
                />
              </a>
              <a
                target='_blank' rel='noopener'
                href='https://play.google.com/store/apps/details?id=co.trackn.neero&pli=1'
              >
                <img
                  src='/play_store.png'
                  alt='Google Play Store'
                  className='google_play'
                />
              </a>
            </div>
          </div>
          <div className='col_2'>
            <img
              className='bounce'
              src='/tester_notre_app.png'
              alt='Testez notre application Neero'
            />
          </div>
        </div>
      </section>

      <section className='section_contact'>
        <div className='container'>
          <h2 className='title'>Contact</h2>
          <h3 className='second_title'>
            Vous avez d'autres questions et vous souhaitez échanger avec notre
            équipe ?
          </h3>
          <div className='ctas'>
            <button
              onClick={() => window.$crisp.push(["do", "chat:open"])}
              className='cta'
            >
              par chat &nbsp; <i className='fas fa-chevron-right'></i>
            </button>
            <Link to='/contact' className='cta'>
              par email &nbsp; <i className='fas fa-chevron-right'></i>
            </Link>
          </div>
        </div>
        <div className='center'>
          <img src='/pre_footer.png' alt='' className='contact_image' />
        </div>
      </section>
    </main>
  );
};

export default ApplicationMobile;
