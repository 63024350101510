import React from 'react'
import './HomeBrands.css';
import Button from 'react-bootstrap/Button';
import RightArrowSvg from "../../assets/svgFiles/rightArrow";
import { externals } from '../../config'
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const imagesUrl = externals.phoneImagesUrl
const imageDefault = externals.defaultPhoneImage
const numInitBrands = 4

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position:'absolute',
    overflow:'scroll',
    height:'100%',
    display:'block'
  }
}));

export default function HomeBrands({brands}) {
  const [showModal, setShowModal] = React.useState(false) 
  const handleOpen = () => setShowModal(true)
  const handleClose = () => setShowModal(false)
  const classes = useStyles();

  return (
    <>
      <div className="homeV2-HomeBrands-container" >
        <h1>Service de réparation multimarques</h1>
        <div className='homeV2-HomeBrands-block'>
          {
            brands?.slice(0, numInitBrands).map((brand, i)=>{
              const _imageUrl = imagesUrl + brand.image
              return(
                <div key={i}>
                  <img 
                    className='homeV2-HomeBrands-img' 
                    src={_imageUrl? _imageUrl: imageDefault} 
                    alt={brand.name}
                  />
                </div>
              )
            })
          }
        </div>
        <Button className="btn-gradient-2" onClick={handleOpen}>
            <u>{("voir toutes les marques").toUpperCase()}</u>
            <RightArrowSvg/>
        </Button>
      </div>
      <Modal
        open={showModal}
        className={classes.modalStyle}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className="homeV2-HomeBrands-modal">
            <div className='homeV2-HomeBrands-modal-close'> 
                <CloseIcon style={{ color: '#6232A0', fontSize: 28, cursor: 'pointer' }} onClick={handleClose} />
            </div>
            <div className='homeV2-HomeBrands-block'>
              {
                brands?.map((brand, i)=>{
                  const _imageUrl = imagesUrl + brand.image
                  return(
                    <div key={i}>
                      <img 
                        className='homeV2-HomeBrands-img' 
                        src={_imageUrl? _imageUrl: imageDefault} 
                        alt={brand.name}
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

