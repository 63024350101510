import './EntrepriseBlock3.css'
import RightArrowSvg from "../../assets/svgFiles/rightArrow";
import Button from 'react-bootstrap/Button';
import MonkIcon from "../../assets/icons/monk.png"
import PhoneImage from "../../assets/images/ent-phone.png"
import BrokenPhoneImage from "../../assets/images/BrokenPhone.png"

export function EntrepriseBlock3({join}) {
    return(
       <div className='EntrepriseBlock3-container'>
           <div className='EntrepriseBlock3-block'>
               <div className='EntrepriseBlock3-title'>
                    <h1>Restez zen</h1>
                    <div style={{backgroundImage: `url(${MonkIcon})`}} />
               </div>
               <div className='EntrepriseBlock3-image-mobile' style={{backgroundImage: `url(${BrokenPhoneImage})`}} />
               <p>Ne vivez plus le stress de rester sans votre téléphone !</p>
               <p>Quel que soit l'endroit où vos collaborateurs et clients se trouvent, nous venons réparer leurs appareils sur place en 30 minutes maximum. </p>
                <Button 
                    className="btn-gradient-2 btn-align-center"  
                    onClick={join}
                    style={{marginTop: 30}}
                >
                    <u>{("Devenons Partenaires").toUpperCase()}</u>
                    <RightArrowSvg/>
                </Button>
           </div>
           <div className='EntrepriseBlock3-image' style={{backgroundImage: `url(${PhoneImage})`}} />
       </div>
    )
}