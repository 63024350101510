import { demandConstants } from '../constants';
import { demandService } from '../services';
import { alertActions } from '.';
import { externals } from '../config';
import axios from 'axios';

function setDemand(demand, resume, coupon) {
  return (dispatch) => {
    dispatch(request());

    demandService.setDemand(demand, resume, coupon).then(
      (response) => {
        dispatch(success(response.data));
        dispatch(
          alertActions.success('Nous avons bien pris en compte votre demande.')
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: demandConstants.ORDER_REQUEST };
  }

  function success(order) {
    return { type: demandConstants.ORDER_SUCCESS, order };
  }

  function failure() {
    return { type: demandConstants.ORDER_FAILURE };
  }
}

function init() {
  return { type: demandConstants.INIT };
}

function setModel(model) {
  return { type: demandConstants.SET_MODEL, model };
}

function setModelsType(modelsType) {
  return { type: demandConstants.SET_MODELS_TYPE, modelsType };
}

function setAddress(address) {
  return { type: demandConstants.SET_ADDRESS, address };
}

function setInterventions(interventions) {
  return { type: demandConstants.SET_INTERVENTIONS, interventions };
}

function setSlots(slots) {
  return { type: demandConstants.SET_SLOTS, slots };
}

function setPage(page) {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  document
    .querySelector('body')
    .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return { type: demandConstants.SET_PAGE, page };
}

function nextPage() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  document
    .querySelector('body')
    .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return { type: demandConstants.NEXT_PAGE };
}

function previousPage() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  document
    .querySelector('body')
    .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return { type: demandConstants.PREVIOUS_PAGE };
}

const apiUrl = externals.apiUrl;

const getInterventionsDemand = (id) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${apiUrl}/api/v1/requester/model/${id}/interventions`
    );

    dispatch({
      type: demandConstants.SET_INTERVENTIONSDEMAND,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: demandConstants.SET_INTERVENTIONSDEMAND,
      payload: error,
    });
  }
};

export const demandActions = {
  setDemand,
  init,
  setModel,
  setInterventions,
  setAddress,
  setSlots,
  setPage,
  nextPage,
  previousPage,
  setModelsType,
  getInterventionsDemand,
};
