import React, { useEffect } from "react";
import "./index.css";
import FooterV2 from "../../components/FooterV2";
import AppDownloadV2 from "../../components/AppDownloadV2";
import { useHistory } from "react-router-dom";
//import ReactPixel from 'react-facebook-pixel';
import RightArrowSvg from "../../assets/svgFiles/rightArrow";
import Button from "react-bootstrap/Button";
import { EntrepriseBlock1 } from "./EntrepriseBlock1";
import { EntrepriseBlock2 } from "./EntrepriseBlock2";
import { EntrepriseBlock3 } from "./EntrepriseBlock3";
import { EntrepriseReviews } from "./EntrepriseReviews";

export function EntreprisePageV2() {
  const history = useHistory();

  useEffect(() => {
    //ReactPixel.pageView()
  }, []);

  const joinEntreprise = () => history.push("./entreprise-join");
  return (
    <>
      <div className='entreprise-background'>
        <div className='entreprise-car-img-mobile' />
        <div className='entreprise-block-1'>
          <h1>
            METRO, BOULOT... <span>NEERO</span>
          </h1>
          <p>
            Que vous soyez un comité d’entreprise, une start-up ou un revendeur,
            Neero se déplace pour réparer les téléphones de vos collaborateurs
            et clients grâce à son atelier mobile.
          </p>

          <Button
            className='btn-gradient-2 btn-align-center'
            onClick={joinEntreprise}
            style={{ marginTop: 30 }}
          >
            <u>{"Devenons Partenaires".toUpperCase()}</u>
            <RightArrowSvg />
          </Button>
        </div>
        <div className='entreprise-car-img' />
        <div className='entreprise-car-img-suite' />

        <EntrepriseBlock1 />
        <EntrepriseBlock2 />
        <EntrepriseBlock3 join={joinEntreprise} />
        <EntrepriseReviews join={joinEntreprise} />
      </div>
      <AppDownloadV2 />
      <FooterV2 />
    </>
  );
}
