import { externals } from "../config";

const apiUrl = externals.apiUrl

export const manufacturersService = {
    getManufacturers,
    getModels,
    getModelTypes
};


function getManufacturers() {
    const requestOptions = {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    };

    return fetch(`${apiUrl}/api/v1/requester/manufacturers?limit=100&page=1`, requestOptions)
    .then(handleResponse)
}

function getModels(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    };

    return fetch(`${apiUrl}/api/v1/requester/manufacturer/${id}/models`, requestOptions)
    .then(handleResponse)
}

function getModelTypes() {
    const requestOptions = {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    };

    return fetch(`${apiUrl}/api/v1/requester/modeltypes`, requestOptions)
    .then(handleResponse)
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
