import React from 'react'
import './DemandeFooter.css';
import GoogleMapsRating from "../../../components/GoogleMapsRating";
import CancelIcon from '../../../assets/icons/cancel.svg'
import PayIcon from '../../../assets/icons/Pay-debit-card.svg'
import WarrantyIcon from '../../../assets/icons/warranty.svg'
import FHImage from '../../../assets/images/demande-footer-img.png'
import VectorFooterH from '../../../assets/icons/demande-vector-footer.svg'
import ShowMoreBox from "../../../components/ShowMoreBox";
import { FAQ_QR } from "../../../config/staticData";
import { contacts } from "../../../config";

const telFull = "tel:" + contacts.telFull

export default function DemandeFooter() {
    const [showAllFaq, setShowAllFaq] = React.useState(false)

    const toggleShowAllFag = () => setShowAllFaq(!showAllFaq)

    return (
        <div className="demande-footer-container" >
            <div className="demande-footer-header">
                <div className="demande-footer-header-title">
                    <div style={{backgroundImage: `url(${VectorFooterH})`}} />
                    <h2>Vos questions</h2>
                    <p>Nos réponses</p>
                </div>
                <div className="fixing-rating-margin-2">
                    <GoogleMapsRating />
                </div>
                <a className="demande-footer-header-img" href={telFull} style={{backgroundImage: `url(${FHImage})`}}> </a>
            </div>

            <div className="demande-footer-explic">
                <div className="demande-footer-explic-1">
                    <div className='demande-footer-expli-icon' style={{backgroundImage: `url(${CancelIcon})`}} />
                    <h1>Annulation gratuite</h1>
                    <p>Annulez votre réservation à n'importe quel moment sans frais depuis votre compte.</p>
                    </div>
                <div className="demande-footer-explic-1">
                    <div className='demande-footer-expli-icon' style={{backgroundImage: `url(${WarrantyIcon})`}} />
                    <h1>Garantie 12 mois</h1>
                    <p>Toutes nos réparations sont garanties 12 mois. Assistance 7/7j en ligne sur le chat.</p>
                </div>
                <div className="demande-footer-explic-1">
                    <div className='demande-footer-expli-icon' style={{backgroundImage: `url(${PayIcon})`}} />
                    <h1>Payez sur place</h1>
                    <p>Aucun prépaiement en ligne n'est requis, payez sur place une fois la réparation réussie.</p>
                </div>
            </div>

            <a className="demande-footer-header-img-mobile" href={telFull}  style={{backgroundImage: `url(${FHImage})`}}> </a>
            <div className="demande-footer-header-title-mobile">
                <div style={{backgroundImage: `url(${VectorFooterH})`}} />
                <h2>Vos questions</h2>
                <p>Nos réponses</p>
            </div>
            <ShowMoreBox title={FAQ_QR[0].question} content={FAQ_QR[0].reponse} show />
            {
                showAllFaq && FAQ_QR.map((qr, index)=>(
                    index !== 0 &&
                    <div key={index} style={{marginTop: 20}}>
                        <ShowMoreBox title={qr.question} content={qr.reponse} />
                    </div>
                ))
            }
            {
                showAllFaq ?
                <div className='demande-footer-more'>
                    <u onClick={toggleShowAllFag}>Voir moins</u>
                </div>
                :
                <div className='demande-footer-more'>
                    <u onClick={toggleShowAllFag}>Voir plus</u>
                </div>
            }
        </div>
    );
}

