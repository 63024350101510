import React, { useState, useEffect} from "react";
import "./index.css";
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IconButtonMUi from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import ButtonBStrap from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Spinner, IconButton, ChevronLeftIcon } from 'evergreen-ui'
import { connect } from 'react-redux';
import { userActions } from '../../../actions';
import { regex } from "../../../config";
 
const reg = regex.email

const useStyles = makeStyles((theme) => ({
    margin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  }));
  
function ResetPassword(props) {
    const classes = useStyles();
    const [values, setValues] = useState({
        isInEmailcheckForm : true /*!localStorage.getItem('mailSend')*/,
        email: '',
        emailErrorMsg: '',
        emailValide: false,
        oldPassword: '',
        showOldPassword: false,
        oldPasswordErrorMsg: '',
        newPassword: '',
        showNewPassword: false,
        newPasswordErrorMsg: '',
        newPasswordConfirmation: '',
        showNewPasswordConfirmation: false,
        newPasswordConfirmationErrorMsg: '',
        oldPasswordValide: false,
        newPasswordValide: false,
        newPasswordConfirmationValide: false,
        openErrDialog: false,
        openSuccessDialog: false,
        loading: false
    })
    
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === 'email') emailValidate(event.target.value)
        else if (prop === 'oldPassword') oldPasswordValidate(event.target.value)
        else if (prop === 'newPassword') newPasswordValidate(event.target.value)
        else if (prop === 'newPasswordConfirmation') confirmationPasswordValidate(event.target.value)
    };
    
    const handleClickShowOldPassword = () => {
        setValues({ ...values, showOldPassword: !values.showOldPassword });
    };

    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
    };

    const handleClickShowNewPasswordConfirmation = () => {
        setValues({ ...values, showNewPasswordConfirmation: !values.showNewPasswordConfirmation });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const emailValidate = (text) => {
        if (reg.test(text) === false) {
            setValues({ ...values, email:'', emailErrorMsg: 'Adresse e-mail invalide', emailValide: false });   
        }
        else {
            setValues({ ...values, email:text, emailErrorMsg: '', emailValide: true }); 
        }
    } 

    const oldPasswordValidate = (text) => {
        if (text.length < 8) {
            setValues({ ...values, oldPassword:'', oldPasswordErrorMsg: 'Mot de passe trop court',  oldPasswordValide: false });  
        }
        else{
            setValues({ ...values, oldPassword: text, oldPasswordErrorMsg: '', oldPasswordValide: true }); 
        }
    }

    const newPasswordValidate = (text) => {
        if (text.length < 8) {
            setValues({ ...values, newPassword:'', newPasswordErrorMsg: 'Mot de passe trop court',  newPasswordValide: false });  
        }
        else{
            setValues({ ...values, newPassword: text, newPasswordErrorMsg: '', newPasswordValide: true }); 
        }
    }

    const confirmationPasswordValidate = (text) => {
        if (text.length < 8) {
            if (values.newPassword === values.newPasswordConfirmation) setValues({ ...values, newPasswordConfirmation:'', newPasswordConfirmationErrorMsg: 'Mot de passe trop court',  newPasswordConfirmationValide: false });  
            else setValues({ ...values, newPasswordConfirmation:'', newPasswordConfirmationErrorMsg: 'Les mots de passe ne correspondent pas',  newPasswordConfirmationValide: false });  
        }
        else{
            setValues({ ...values, newPasswordConfirmation: text, newPasswordConfirmationErrorMsg: '', newPasswordConfirmationValide: true }); 
        }
    }

    useEffect(() => {
        if (props.alert.type === 'alert-danger')  setValues({...values, openErrDialog: true, loading: false})
        if (props.alert.type === 'alert-success')  setValues({...values, openSuccessDialog: true, loading: false})
        //eslint-disable-next-line
    }, [props.alert])

    const emailCheck = (e) => {
        e.preventDefault()
        if (values.emailValide){
            props.forgottenPassword(values.email)
            setValues({ 
                ...values,
                loading: true,
                emailErrorMsg: '',
             });
        }
        else{
            setValues({ ...values, emailErrorMsg: 'Adresse e-mail invalide' });
        }
       // setValues({ ...values, emailErrorMsg:'',emailValide:false, passwordErrorMsg:'', passwordValide:false }); 
    }
    
    const resetPassword = (e) => {
        e.preventDefault();
        if (values.oldPassword && values.newPassword && values.newPasswordConfirmation){
            //props.login(values.email, values.password)
            setValues({ 
                ...values,
                loading: true,
                showOldPassword: false,
                oldPasswordErrorMsg: '',
                showNewPassword: false,
                newPasswordErrorMsg: '',
                showNewPasswordConfirmation: false,
                newPasswordConfirmationErrorMsg: ''
             });
        }
        else{
            if (!values.oldPassword) setValues({ ...values, oldPasswordErrorMsg: 'Mot de passe trop court' }); 
            if (!values.newPassword) setValues({ ...values, newPasswordErrorMsg: 'Mot de passe trop court' }); 
            if (!values.newPasswordConfirmation) setValues({ ...values, newPasswordConfirmationErrorMsg: 'Mot de passe trop court' }); 
        }
       // setValues({ ...values, emailErrorMsg:'',emailValide:false, passwordErrorMsg:'', passwordValide:false }); 
    }
    const handleClose = () => {
        if (values.openSuccessDialog) setValues({ ...values, /*isInEmailcheckForm: false,*/ openSuccessDialog: false, loading: false });
        else setValues({ ...values, openErrDialog: false, loading: false }); 
    }

    useEffect(() => {
        setValues({ ...values, loading: false });
        //eslint-disable-next-line
    }, [values.isInEmailcheckForm])

    const history = useHistory();

    const emailCheckForm = () => {
        return(
            <form className='login' onSubmit={emailCheck}>
                <div className='icon-title'>
                    <div>
                        <div style={{marginTop: 5}} />
                        <IconButton
                            height={25}
                            icon={ChevronLeftIcon} 
                            iconSize={17} 
                            onClick={() => history.push('/espace')}
                        />
                    </div>
                    <h2>Entrez votre adresse email</h2>
                </div>
                <FormControl>
                    <TextField
                        id="outlined-error-helper-text"
                        label="E-mail"
                        onKeyPress={(e) => { e.key === 'Enter' && emailCheck(e) }}
                        //error
                        //helperText="Incorrect entry."
                        type="email"
                        onChange={handleChange('email')}
                        variant="outlined"
                        color={values.emailValide ? "primary" : "secondary"}
                    />
                    <em>{values.emailErrorMsg}</em>
                </FormControl>
                <div style={{paddingTop: 10}} />
                <ButtonBStrap className="btn-gradient" onClick={emailCheck} style={{alignSelf: 'center', marginLeft:0}}>
                    <u>Réinitialiser</u>
                </ButtonBStrap>
                { values.loading? <Spinner style={{alignSelf: "center", paddingTop: 20}}/> :null }
                <div className="add-some-padding-top"/>
            </form>
        )
    }

    const resetPasswordForm = () => {
        return(
            <form className='login' onSubmit={resetPassword}>
                <div className='icon-title'>
                    <div>
                        <div style={{marginTop: 5}} />
                        <IconButton
                            height={25}
                            icon={ChevronLeftIcon} 
                            iconSize={17} 
                            onClick={() => setValues({...values, isInEmailcheckForm: true})}
                        />
                    </div>
                    <h2>Réinitialisez le mot de passe</h2> 
                </div>
                <FormControl className={clsx(classes.margin)} variant="outlined">
                    <InputLabel  color={values.oldPassword ? "primary" : "secondary"}  htmlFor="outlined-adornment-password">
                        Ancien mot de passe
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showOldPassword ? 'text' : 'password'}
                        onChange={handleChange('oldPassword')}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButtonMUi
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButtonMUi>
                        </InputAdornment>
                        }
                        labelWidth={155}
                        color={values.oldPasswordValide ? "primary" : "secondary"}
                    />
                    <em>{values.oldPasswordErrorMsg}</em>
                </FormControl>
                <FormControl className={clsx(classes.margin)} variant="outlined">
                    <InputLabel  color={values.newPassword ? "primary" : "secondary"}  htmlFor="outlined-adornment-password">
                        Nouveau mot de passe
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showNewPassword ? 'text' : 'password'}
                        onChange={handleChange('newPassword')}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButtonMUi
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButtonMUi>
                        </InputAdornment>
                        }
                        labelWidth={166}
                        color={values.newPasswordValide ? "primary" : "secondary"}
                    />
                    <em>{values.newPasswordErrorMsg}</em>
                </FormControl>
                <FormControl className={clsx(classes.margin)} variant="outlined">
                    <InputLabel  color={values.newPasswordConfirmation ? "primary" : "secondary"}  htmlFor="outlined-adornment-password">
                        Confirmation du mot de passe
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showNewPasswordConfirmation ? 'text' : 'password'}
                        onChange={handleChange('newPasswordConfirmation')}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButtonMUi
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPasswordConfirmation}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {values.showNewPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                            </IconButtonMUi>
                        </InputAdornment>
                        }
                        labelWidth={222}
                        color={values.newPasswordConfirmationValide ? "primary" : "secondary"}
                    />
                    <em>{values.newPasswordConfirmationErrorMsg}</em>
                </FormControl>
                <Button className="btn-register" height={40} appearance="minimal" onClick={resetPassword}>
                    <a>Réinitialiser</a>{/* eslint-disable-line */}
                </Button>
                { values.loading? <Spinner style={{alignSelf: "center", paddingTop: 20}}/> :null }
                <div className="add-some-padding-top"/>
            </form>
        )
    }


    return (
        <div className="espace-background" >
            <div className='espace-background-card'>
                <div style={{paddingTop: "10%"}}/>
                <div className='login-card'>
                    { values.isInEmailcheckForm? emailCheckForm(): resetPasswordForm()}
                    <Dialog
                        fullWidth={true}
                        open={values.openErrDialog || values.openSuccessDialog}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{
                            values.openErrDialog ? "Erreur": 
                                values.isInEmailcheckForm? "La demande de réinitialisation a été effectuée" :
                                    "Mot de passe Modifié"
                        }</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {values.openSuccessDialog? "Vérifiez vos emails et suivez les instructions" :
                            props.alert.message}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <ButtonBStrap className="btn-gradient" onClick={handleClose} style={{margin: 7}}>
                            <u>{values.openErrDialog ? "Réessayer": "OK"}</u>
                        </ButtonBStrap>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
 


function mapState(state) {
    const { loggingIn, mailSend } = state.authentication;
    const { alert } = state;
    return { loggingIn, alert, mailSend };
}

const actionCreators = {
    forgottenPassword: userActions.forgottenPassword
};

const connectedResetPassword = connect(mapState, actionCreators)(ResetPassword);
export { connectedResetPassword as ResetPassword };