import { manufacturersConstants } from '../constants';

const initialState = {
    manufacturers: null,
    loading: false,
    models: null,
    modelsLoading: false,
    modelTypes: null,
    modelTypesLoading: false,
    manufacturersWithModels: null,
    manufacturersWithModelsLoading: false,
}

export function manufacturers(state = initialState, action) {
  switch (action.type) {
    case manufacturersConstants.INIT_MANUFACTURES:
        return {
            manufacturers: null,
            loading: false,
            models: null,
            modelsLoading: false,
            modelTypes: null,
            modelTypesLoading: false,
            manufacturersWithModels: null,
            manufacturersWithModelsLoading: false,
        };
    case manufacturersConstants.SET_MANUFACTURES:
        return {
            ...state,
            manufacturers: action.manufacturers,
            loading: false
        };
    case manufacturersConstants.SET_LOADING:
        return{
            ...state,
            loading: true
        }
    case manufacturersConstants.SET_MANUFACTURES_FAILED:
        return{
            ...state,
            loading: false
        }
    case manufacturersConstants.SET_MODELS:
        return {
            ...state,
            models: action.models,
            modelsLoading: false
        };
    case manufacturersConstants.SET_MODELS_LOADING:
        return{
            ...state,
            modelsLoading: true
        }
    case manufacturersConstants.SET_MODELS_FAILED:
        return{
            ...state,
            modelsLoading: false
        }
    case manufacturersConstants.INIT_MODELS:
        return{
            ...state,
            models: null
        }
    case manufacturersConstants.SET_MODEL_TYPES:
        return {
            ...state,
            modelTypes: action.modelTypes,
            modelTypesLoading: false
        };
    case manufacturersConstants.SET_MODEL_TYPES_LOADING:
        return{
            ...state,
            modelTypesLoading: true
        }
    case manufacturersConstants.SET_MODEL_TYPES_FAILED:
        return{
            ...state,
            modelTypesLoading: false
        }
    case manufacturersConstants.INIT_SET_MODEL_TYPES:
        return{
            ...state,
            modelTypes: null
        }
    case manufacturersConstants.SET_MANUFACTURES_WITH_MODELS:
        return {
            ...state,
            manufacturersWithModels: action.manufacturersWithModels,
            manufacturersWithModelsLoading: false
        };
    case manufacturersConstants.SET_MANUFACTURES_WITH_MODELS_LOADING:
        return{
            ...state,
            manufacturersWithModelsLoading: true
        }
    case manufacturersConstants.SET_MANUFACTURES_WITH_MODELS_FAILED:
        return{
            ...state,
            manufacturersWithModelsLoading: false
        }
    default:
        return state
  }
}