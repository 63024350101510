import { useEffect } from "react";
import "./index.css";
import { typeFormUrl } from "../../config";
//import ReactPixel from 'react-facebook-pixel';
  
export function NousRejoindreEntreprisePage() {

    useEffect(() => {
        //ReactPixel.pageView()
    },[])

    return (
        <div className="typeform-background" >
            
          <iframe 
            className='typeform'
            title="Nous rejoindre"
            src={typeFormUrl.entreprise} 
            scrolling="auto"
        >
        </iframe>
        </div>
    );
}
 
