import PE1 from "../../assets/icons/pe1.png";
import PE2 from "../../assets/icons/pe2.png";
import PE3 from "../../assets/icons/pe3.png";
import PE4 from "../../assets/icons/pe4.png";
import PE5 from "../../assets/icons/pe5.png";
import PE6 from "../../assets/icons/pe6.png";

import PR1 from "../../assets/icons/pr1.svg";
import PR2 from "../../assets/icons/pr2.svg";
import PR3 from "../../assets/icons/pr3.svg";
import PR4 from "../../assets/icons/pr4.svg";
import PR5 from "../../assets/icons/pr5.svg";
import PR6 from "../../assets/icons/pr6.svg";
import PR7 from "../../assets/icons/pr7.svg";
import PR8 from "../../assets/icons/pr8.svg";
import PR9 from "../../assets/icons/pr9.svg";
import PR10 from "../../assets/icons/pr10.svg";

export const PEngagementsItems = [
    {icon: <div style={{backgroundImage: `url(${PE1})`}} />, title: 'Techniciens certifiés'},
    {icon: <div style={{backgroundImage: `url(${PE2})`}} />, title: 'Diagnostic gratuit'},
    {icon: <div style={{backgroundImage: `url(${PE3})`}} />, title: "Prix transparents et connus d'avance"},
    {icon: <div style={{backgroundImage: `url(${PE4})`}} />, title: 'Réparations garanties 1 an'},
    {icon: <div style={{backgroundImage: `url(${PE5})`}} />, title: 'Interventions à domicile'},
    {icon: <div style={{backgroundImage: `url(${PE6})`}} />, title: 'Pièces reconditionnées ou de qualité originale'},
]

export const PRItems = [
    {icon: <div style={{backgroundImage: `url(${PR1})`}} />, title: "Haut parleur"},
    {icon: <div style={{backgroundImage: `url(${PR2})`}} />, title: "Caméra"},
    {icon: <div style={{backgroundImage: `url(${PR3})`}} />, title: "Carte mère"},
    {icon: <div style={{backgroundImage: `url(${PR4})`}} />, title: "Réseau"},
    {icon: <div style={{backgroundImage: `url(${PR5})`}} />, title: "Remise en état des téléphones"},
    {icon: <div style={{backgroundImage: `url(${PR6})`}} />, title: "Ecran cassé"},
    {icon: <div style={{backgroundImage: `url(${PR7})`}} />, title: "Batterie"},
    {icon: <div style={{backgroundImage: `url(${PR8})`}} />, title: "Microphone"},
    {icon: <div style={{backgroundImage: `url(${PR9})`}} />, title: "Ecouteurs"},
    {icon: <div style={{backgroundImage: `url(${PR10})`}} />, title: "Problème de Charge"},
]