import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={25}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.352 0 9.897 15.102 3.125 8.335 0 11.46l10.415 10.415L25 3.125 21.352 0Z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
