import { resumeConstants } from '../constants';

export const resumeActions = {
    init,
    setAddress,
    setModel,
    setPrice,
    setDate
};

function init() {
    return { type: resumeConstants.INIT };
}

function setAddress(address) {
    return { type: resumeConstants.SET_ADDRESS, address };
}

function setModel(model) {
    return { type: resumeConstants.SET_MODEL, model };
}

function setPrice(price) {
    return { type: resumeConstants.SET_PRICE, price };
}

function setDate(date) {
    return { type: resumeConstants.SET_DATE, date };
}