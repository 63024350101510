import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { resume } from "./resume.reducer";
import { manufacturers } from "./manufacturers.reducer";
import { interventions } from './interventions.reducer';
import { slots } from "./slots.reducer";
import { demand } from "./demand.reducer";
import { order } from "./order.reducer";
import { contact } from "./contact.reducer";
import { discount } from "./discount.reducer";
import { settings } from "./settings.reduce";

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  resume,
  manufacturers,
  interventions,
  slots,
  demand,
  order,
  contact,
  discount,
  settings
});

export default rootReducer;