import './HomeEnd.css';
import HomeEnd1 from "../../assets/images/home-end-1.png";
import HomeEnd2 from "../../assets/images/home-end-2.png";

export default function HomeEnd() {
   
  return (
    <div className="homeV2-HomeEnd-container" >
      <h1>Notre équipe s’engage</h1>
      <p>Neero est membre d’associations françaises qui luttent contre l’obsolescence programmée et qui défendent l’écologie !</p>
      <div className="homeV2-HomeEnd-block">
        <div style={{backgroundImage: `url(${HomeEnd2})`}} />
        <div style={{backgroundImage: `url(${HomeEnd1})`}} />
      </div>
    </div>
  );
}

