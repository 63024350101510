import React from 'react';
import './index.css';
import { useHistory } from 'react-router-dom';
import { PRItems } from "./StaticData";
import { PRODUCT_ROUTES } from "../../config/api";
import { SmallScreenContext } from "../../helpers/SmallScreenContext";

export default function ProductReparations() {
    const history = useHistory()
    const context = React.useContext(SmallScreenContext)

    const goToProductPage = (i) => {
        context.showMenu()
        history.push(`/${PRODUCT_ROUTES.path}-${PRODUCT_ROUTES.children[i].path}`)
    }

    return(
        <>
            <div className='product-reparations-container'>
                <h1>Nos réparations</h1>
                <div className="product-reparations-block">
                    {
                        PRItems.map((item, index) =>
                            <div className="product-reparations-item" key={index} onClick={() => goToProductPage(index)}>
                                {item.icon}
                                <p>{item.title}</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}