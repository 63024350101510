import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlined from '@material-ui/icons/EditOutlined';
import { demandActions } from '../../actions';
import { staticId } from "../../config";

import './index.css';

const style = getComputedStyle(document.body)

const useStyles = makeStyles((theme) => ({
    icon: {
      color: style.getPropertyValue('--secondary-1'),
      marginRight: theme.spacing(2),
    },
  }));

function ResumeSlide(props) {
    const classes = useStyles();

    const _address = () => {
        return(
            <div className='resume-box'>
                <div className='resume-box-hedear'>
                    <u>Adresse</u>
                    <div onClick={()=>props.setPage(1)}>
                        <EditOutlined className={classes.icon}/>
                    </div>
                </div>
               <h4>{props.address}</h4> 
            </div>
        )
    }

    const _model = () => {
        return(
            <div className='resume-box'>
                <div className='resume-box-hedear'>
                    <u>Marque et modèle</u>
                    <div onClick={()=>props.setPage(1)}>
                        <EditOutlined className={classes.icon} />
                    </div>
                </div>
               <h4>{props.model}</h4>
            </div>
        )
    }

    const PriceRow = (prop) =>{
        return(
            <div>
                <div className='resume-box-item'>
                    <h5>{prop.price.name}</h5>
                    <h6>{prop.price.price}{staticId.InterventionUnknow === prop.price.uuid || staticId.InterventionUnvailable === prop.price.uuid ?'':' €'}</h6>
                </div>
            </div>
        )
    }

    const PriceRowDiscount = (prop) =>{
        return(
            <div>
                {
                    prop.price.name === 'Total TTC' ?
                        <div className='resume-box-item-total'>
                            <h5>Total TTC</h5>
                            <h6>{parseFloat(prop.price.price).toFixed(2)} €</h6>
                        </div>
                    :
                        <div className='resume-box-item'>
                            <h5>{prop.price.name}</h5>
                            {
                                prop.price.name === "50 % sur la 2e panne moins chère" ?
                                    <h6 style={{color:style.getPropertyValue('--secondary-1')}}>{parseFloat(prop.price.price).toFixed(2)} €</h6>
                                    : prop.price.name === "Coupon" ?
                                        <h6 style={{color:style.getPropertyValue('--tertiary-2')}}>{parseFloat(prop.price.price).toFixed(2)} €</h6>
                                        : prop.price.price === "Offert" ? 
                                            <h6>Offert</h6>
                                            : <h6>{parseFloat(prop.price.price).toFixed(2)} €</h6>
                            }
                        </div>
                }
            </div>
        )
    }

    const sumTotal = arr => arr.reduce((sum, { price }) => Number.isFinite(price) ? sum + price: sum, 0)

    const _price = () => {
        const prices = props.price
        return(
            <div className='resume-box'>
                <div className='resume-box-hedear'>
                    <u>Panne(s)</u>
                    <div onClick={()=>props.setPage(2)}>
                        <EditOutlined className={classes.icon} />
                    </div>
                </div>
                {
                    props.discount?.length ? 
                        props.discount.map((price, index) =>
                            // Correct! Key should be specified inside the array.
                            <PriceRowDiscount key={index} price={price} />
                        )
                    :
                    <div>
                        <div className='resume-box-item'>
                            <h5>Déplacement technicien certifié</h5>
                            <h6>Offert</h6>
                        </div>
                        {
                        prices.map((price) =>
                        // Correct! Key should be specified inside the array.
                        <PriceRow key={price.uuid} price={price} />
                        )
                        }
                        <div className='resume-box-item-total'>
                            <h5>Total TTC</h5>
                            <h6>{sumTotal(prices).toFixed(2)} €</h6>
                        </div>
                    </div>
                }
            </div>
        )
    }

    const DateRow = (prop) => {
        let hours = prop.slot.slotTime.split('-');
        return(
            <div className='resume-box-item'>
                <b>Le {prop.slot.date} entre {hours[0]} et {hours[1]}</b>
            </div>
        )
    }

    const _date = () => {
        return(
            <div className='resume-box'>
                <div className='resume-box-hedear'>
                    <u>Date(s)</u>
                    <div onClick={()=>props.setPage(3)}>
                        <EditOutlined className={classes.icon} />
                    </div>
                </div>
                {
                   props.date.map((slot, index) =>
                   // Correct! Key should be specified inside the array.
                   <DateRow key={index} slot={slot} />
                   )
                }
            </div>
        )
    }

    return (
        <div className="demande-resume-container">
            <h1>🙌</h1>
            <p>En réparant votre {props.model? props.model.split(' - ')[1]: 'Smartphone'} au lieu d’en acheter un neuf, vous économisez de l'argent et faites un geste pour la planète !</p>
            <h2>- 200g de déchets</h2>
            <h4>évités à la planète</h4>
            <h5>en moyenne par réparation</h5>
            {props.address || props.model ?<h3>Récapitulatif de votre demande</h3>: null}
            { props.address?  _address(): null}
            { props.model?  _model(): null}
            { props.price?  _price(): null}
            { props.date?  _date(): null}
        </div>
    );

}


function mapState(state) {
    const { address, model, price, date } = state.resume;
    const { discount, loading } = state.discount
    return { address, model, price, date, discount, loading };
}

const actionCreators = {
    setPage: demandActions.setPage
};

const connectedResumeSlide = connect(mapState, actionCreators)(ResumeSlide);
export { connectedResumeSlide as ResumeSlide };