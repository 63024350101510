import { useEffect } from "react";
import "./index.css";
import { useHistory } from "react-router-dom";
import { contacts } from "../../config";
//import ReactPixel from 'react-facebook-pixel';

export function PrivacyPolicyPage() {
  const history = useHistory();
  const email = "mailto:" + contacts.email;
  //const telFull = "tel:" + contacts.telFull
  //const mediaTelFull = "tel:" + contacts.mediaTelFull

  /*const mailto = (e) => {
        window.location = email;
        e.preventDefault();
    }*/
  useEffect(() => {
    //ReactPixel.pageView()
  }, []);

  return (
    <div className='pp-container'>
      <div className='pp-header'>
        <h1>POLITIQUE DE CONFIDENTIALITE</h1>
      </div>
      <div className='pp'>
        <p>
          Chez Neero.fr la protection de vos données personnelles est une
          priorité.
        </p>
        <p>
          Dans le cadre de votre utilisation de notre site internet{" "}
          <u onClick={() => history.push("/")}>https://www.neero.fr/</u>{" "}
          (ci-après le « <b>Site</b> »), nous sommes amenés à recueillir des
          données à caractère personnel vous concernant.
        </p>
        <p>
          Le but de cette politique est de vous informer sur les modalités selon
          lesquelles nous traitons ces données en conformité avec le Règlement
          (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes
          physiques à l&#39;égard du traitement des données à caractère
          personnel et à la libre circulation de ces données (ci-après le
          « RGPD »).
        </p>

        <h2>1. Qui est le Responsable de traitement ?</h2>
        <p>
          Le responsable du traitement est la société TRACKN, société par
          actions simplifiée à associé unique, immatriculée au RCS de Paris sous
          le n° 880 624 655 et dont le siège social est situé au 109 Boulevard
          Macdonald 75019 Paris (ci-après « <b>Nous</b> »).
        </p>

        <h2>2. Quelles données personnelles collectons-nous ?</h2>
        <p>
          Une donnée à caractère personnel est une donnée qui permet
          d’identifier un individu directement ou par recoupement avec d’autres
          données.
        </p>
        <p>
          Nous collectons des données qui relèvent des catégories suivantes :
        </p>
        <h6>
          <span>-</span>
          <b>Des données d’identification</b> (notamment vos noms, prénoms,
          adresse postale ; adresse mail, numéro de téléphone) ;
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>
          <b>
            Des données relatives à votre téléphone mobile ou votre tablette
          </b>{" "}
          (notamment le modèle de votre téléphone mobile ou de votre tablette,
          le code de la carte SIM, le code de déverrouillage du téléphone ou de
          la tablette) ;
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>
          <b>Des données relatives à vos réparations en cours ou passées</b> ;
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>
          <b>
            Des données relatives à votre candidature lorsque vous souhaitez
            devenir réparateur
          </b>{" "}
          (notamment votre fonction professionnelle, vos compétences en matière
          de réparation, votre domaine d&#39;action, le code postal de votre
          ville, votre moyen de déplacement)
        </h6>
        <h6 style={{ marginTop: -25 }}>
          <span>-</span>
          <b>Des données relatives à vos cartes bancaires.</b>
        </h6>
        <p>
          Lors de la collecte de vos données personnelles, vous serez informé si
          certaines données doivent être obligatoirement renseignées ou si elles
          sont facultatives. Les données identifiées par un astérisque au sein
          du formulaire de collecte sont obligatoires.
        </p>

        <h2>
          3. Sur quelles bases légales, pour quelles finalités et pendant
          combien de temps conservons-nous vos données personnelles ?
        </h2>
        <div className='pp-table'>
          <div>
            <h5>Finalités du traitement</h5>
          </div>
          <div>
            <h5>Bases légales</h5>
          </div>
          <div>
            <h5>Durées de conservation</h5>
          </div>
          <div>
            <p>
              Traiter vos informations lorsque vous réalisez votre commande de
              Prestation pour réparer votre téléphone mobile ou votre tablette
              (notamment en vous recontactant par courrier électronique pour
              vous communiquer votre devis et finaliser votre inscription sur le
              site internet pour bénéficier de nos services)
            </p>
          </div>
          <div>
            <p>Exécution de mesures précontractuelles prises à votre demande</p>
          </div>
          <div>
            <p>
              Les données sont conservées pour la durée nécessaire à l’émission
              du devis et augmentée de 3 mois à compter de l’émission du devis.
            </p>
            <p>Vos données sont ensuite archivées pendant 3 mois.</p>
          </div>
          <div>
            <p>
              Fournir nos services disponibles sur notre Site suite à la
              création de votre espace personnel
            </p>
          </div>
          <div>
            <p>
              Exécution de mesures précontractuelles prises à votre demande
              et/ou exécution d’un contrat que vous avez souscrit
            </p>
          </div>
          <div>
            <p>
              Vos données sont conservées pour toute la durée de votre compte et
              augmentée d’un délai de 3 ans à compter de la suppression de votre
              compte.
            </p>
            <p>
              En outre, vos données peuvent être archivées à des fins
              probatoires pendant une durée de 5 ans.
            </p>
          </div>
          <div>
            <p>
              Exécuter votre commande, effectuer les opérations relatives à la
              gestion des clients concernant les contrats, commandes,
              livraisons, factures, et suivi de la relation avec les clients
            </p>
          </div>
          <div>
            <p>Exécution d’un contrat que vous avez souscrit</p>
          </div>
          <div>
            <p>
              Les données personnelles sont conservées pendant toute la durée de
              la relation contractuelle et augmentée d’un délai de 3 ans à
              compter de la fin de cette dernière.
            </p>
            <p>
              En outre, les données relatives à vos transactions (à l’exceptions
              de vos données bancaires) sont archivées à des fins probatoires
              pendant une durée de 5 ans.
            </p>
            <p>
              Concernant les données relatives à votre carte bancaire, elles
              sont conservées par notre prestataire de service de paiement
              Stripe jusqu’à la fin de la transaction.
            </p>
            <p>
              Les données relatives au cryptogramme visuel ou CVV2, inscrit sur
              votre carte bancaire, ne sont pas stockées.
            </p>
            <p>
              Les données relatives à vos cartes bancaires pourront être
              conservées, pour une finalité de preuve en cas d’éventuelle
              contestation de la transaction, en archives intermédiaires pendant
              une durée de treize (13) mois suivant la date de débit. Ce délai
              peut être étendu à quinze (15) mois afin de prendre en compte la
              possibilité d’utilisation des cartes de paiement à débit différé.
            </p>
          </div>
          <div>
            <p>Constituer un fichier clients et prospects</p>
          </div>
          <div>
            <p>
              Notre intérêt légitime à développer et promouvoir notre activité
            </p>
          </div>
          <div>
            <p>
              Pour les clients : les données sont conservées pendant toute la
              durée de la relation commerciale et sont supprimées à l’expiration
              d’un délai de 3 ans à compter de la fin de la relation
              commerciale.
            </p>
            <p>
              Pour les prospects : les données sont conservées pendant un délai
              de 3 ans à compter de votre dernier contact.
            </p>
            <p>
              En tout état de cause, vos données peuvent être archivées à des
              fins probatoires pendant une durée de 5 ans.
            </p>
          </div>
          <div>
            <p>
              Adresser des newsletters, sollicitations, et messages
              promotionnels
            </p>
          </div>
          <div>
            <p>Pour les prospects : votre consentement</p>
            <p>
              Pour les clients : notre intérêt légitime à développer et
              promouvoir notre activité
            </p>
          </div>
          <div>
            <p>
              Les données sont conservées pendant 3 ans à compter de votre
              dernier contact
            </p>
          </div>
          <div>
            <p>Répondre à vos demandes d’information</p>
          </div>
          <div>
            <p>Notre intérêt légitime à répondre à vos demandes</p>
          </div>
          <div>
            <p>
              Les données sont conservées pendant le temps nécessaire au
              traitement de votre demande d’information et supprimées une fois
              la demande d’information traitée.
            </p>
          </div>
          <div>
            <p>Traiter vos demandes de candidatures</p>
          </div>
          <div>
            <p>Exécution de mesures précontractuelles prises à votre demande</p>
          </div>
          <div>
            <p>
              Les données sont conservées pendant la durée du traitement de
              votre candidature.
            </p>
            <p>
              Les données sont conservées pendant la durée du traitement de
              votre candidature.
            </p>
            <p>
              En cas d’issue négative à votre candidature, nous pouvons
              souhaiter conserver vos données, le cas échéant nous vous le
              ferons savoir. En l’absence d’opposition de votre part à une telle
              conservation de vos données, nous conservons les données pendant 2
              ans à compter de votre dernier contact.
            </p>
          </div>
          <div>
            <p>
              Se conformer aux obligations légales applicables à notre activité
            </p>
          </div>
          <div>
            <p>Obligations légales et règlementaires</p>
          </div>
          <div>
            <p>
              Pour les factures : les factures sont archivées pendant une durée
              de 10 ans.
            </p>
            <p>
              Les données relatives à vos transactions (à l’exception des
              données bancaires) sont conservées pendant 5 ans.
            </p>
          </div>
          <div>
            <p></p>Gérer les demandes d’exercice de droits
          </div>
          <div>
            <p>
              Notre intérêt légitime à se conformer aux obligations légales
              auxquelles nous sommes soumis
            </p>
          </div>
          <div>
            <p>
              Si nous vous demandons un justificatif d’identité : nous le
              conservons seulement pendant le temps nécessaire à la vérification
              d’identité. Une fois la vérification effectuée, le justificatif
              est supprimé.
            </p>
            <p>
              Si vous exercez votre droit d’opposition à exercer de la
              prospection : nous conservons cette information pendant 3 ans.
            </p>
          </div>
        </div>

        <h2>4. Qui sont les destinataires de vos données personnelles ?</h2>
        <p>Auront accès à vos données à caractère personnel :</p>
        <div className='pp-p-h3'>
          <p>(i)</p>
          <p>Le personnel de notre société ;</p>
        </div>
        <div className='pp-p-h3'>
          <p>(ii)</p>
          <p>
            Nos sous-traitants : prestataires d’hébergement ; prestataire
            d’envoi de newsletters ; prestataire de service de paiement
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>(iii)</p>
          <p>
            Le cas échéant : les organismes publics et privés, exclusivement
            pour répondre à nos obligations légales.
          </p>
        </div>

        <h2>
          5. Vos données sont-elles susceptibles d’être transférées hors de
          l’Union européenne ?
        </h2>
        <p>
          Vos données sont conservées et stockées pendant toute la durée des
          traitements sur les serveurs de la société OVH, situés en France.
        </p>
        <p>
          Dans le cadre des outils que nous utilisons (voir article 4 sur les
          destinataires concernant nos sous-traitants), vos données sont
          susceptibles de faire l’objet de transferts hors de l’Union
          européenne. Le transfert de vos données dans ce cadre est sécurisé au
          moyen des outils suivants :
        </p>
        <div className='pp-p-h3'>
          <p>●</p>
          <p>
            Soit, ces données sont transférées dans un pays ayant été jugé comme
            offrant un niveau de protection adéquat par une décision de la
            Commission européenne ;
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>●</p>
          <p>
            Soit, nous avons conclu avec nos sous-traitants un contrat
            spécifique encadrant les transferts de vos données en dehors de
            l’Union européenne, sur la base des clauses contractuelles types
            entre un responsable de traitement et un sous-traitant approuvé par
            la Commission Européenne ;
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>●</p>
          <p>
            Soit, nous avons recours aux garanties appropriées prévues par la
            réglementation applicable.
          </p>
        </div>

        <h2>6. Quels sont vos droits sur vos données ?</h2>
        <p>
          Vous disposez des droits suivants s’agissant de vos données
          personnelles :
        </p>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>Droit à l’information</b>: c’est justement la raison pour
            laquelle nous avons rédigé la présente politique. Ce droit est prévu
            par les articles 13 et 14 du RGPD.{" "}
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>Droit d’accès :</b>vous avez le droit d’accéder à tout moment à
            l’ensemble de vos données personnelles en vertu de l’article 15 du
            RGPD.{" "}
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>Droit de rectification :</b>vous avez le droit de rectifier à
            tout moment vos données personnelles inexactes, incomplètes ou
            obsolètes conformément à l’article 16 du RGPD.{" "}
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>Droit à la limitation :</b> vous avez le droit d’obtenir la
            limitation du traitement de vos données personnelles dans certains
            cas définis à l’article 18 du RGPD.{" "}
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>Droit à l’effacement :</b> vous avez le droit d’exiger que vos
            données personnelles soient effacées, et d’en interdire toute
            collecte future pour les motifs énoncés à l’article 17 du RGPD.{" "}
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>
              Droit d’introduire une réclamation auprès d’une autorité de
              contrôle compétente
            </b>{" "}
            (en France, la CNIL), si vous considérez que le traitement de vos
            données personnelles constitue une violation des textes applicables.
            (Article 77 du RGPD).
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>
              Droit de définir des directives relatives à la conservation, à
              l’effacement et à la communication de vos données personnelles
              après votre mort,
            </b>{" "}
            conformément à l’article 40-1 de la loi informatique et libertés.{" "}
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>Droit de retirer votre consentement à tout moment :</b>pour les
            finalités fondées sur le consentement, l’article 7 du RGPD dispose
            que vous pouvez retirer votre consentement à tout moment. Ce retrait
            ne remettra pas en cause la légalité du traitement effectué avant le
            retrait.{" "}
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>Droit à la portabilité :</b>selon certaines conditions précisées
            à l’article 20 du RGPD, vous avez le droit de recevoir les données
            personnelles que vous nous avez fournies dans un format standard
            lisible par une machine et d’exiger leur transfert au destinataire
            de votre choix.{" "}
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>-</p>
          <p>
            <b>Droit d’opposition :</b>en vertu de l’article 21 du RGPD, vous
            avez le droit de vous opposer au traitement de vos données
            personnelles. Notez toutefois que nous pourrons maintenir leur
            traitement malgré cette opposition, pour des motifs légitimes ou la
            défense de droits en justice.{" "}
          </p>
        </div>
        <p>
          Vous pouvez exercer ces droits en nous écrivant aux coordonnées
          ci-dessous. Nous pourrons vous demander à cette occasion de nous
          fournir des informations ou documents complémentaires pour justifier
          votre identité.
        </p>

        <h2>7. Point de contact en matière de données personnelles</h2>
        <p>
          Email de contact:{" "}
          <b href={email} target='_blank' rel='noopener'>
            {contacts.email}
          </b>
        </p>
        <p>Adresse de contact : Trackn, 86 Rue Voltaire 93100 Montreuil</p>

        <h2>8. Modifications</h2>
        <p>
          Nous pouvons modifier à tout moment la présente politique. Ces
          modifications s’appliqueront à la date d’entrée en vigueur de la
          version modifiée. Vous êtes donc invité à consulter régulièrement la
          dernière version de cette politique. Néanmoins, nous vous tiendrons
          informé(e) de toute modification significative de la présente
          politique de confidentialité.
        </p>
        <p>Entrée en vigueur : Octobre 2021</p>
      </div>

      <div className='pp-header'>
        <h1>Politique de Cookies</h1>
      </div>
      <div className='pp'>
        <h2>1. Qu’est-ce qu’un Cookie ?</h2>
        <p>
          Lors de votre navigation sur le site{" "}
          <u onClick={() => history.push("/")}>https://www.neero.fr/</u>{" "}
          (ci-après le « <b>Site</b> »), des cookies, pixels et autres traceurs
          (ci-après désignés ensemble le « <b>Cookie</b> » ou les « 
          <b>Cookies</b> ») sont déposés sur votre navigateur.
        </p>
        <p>
          Un Cookie est un petit fichier, souvent crypté, stocké dans votre
          navigateur ou votre terminal et identifié par un nom. Il est déposé
          lors de la consultation d’un site. Chaque fois que vous revenez sur le
          site en question, le Cookie est récupéré sur votre navigateur ou sur
          votre terminal. Ainsi, chaque fois que vous consultez le site, le
          navigateur est reconnu.
        </p>
        <p>
          Le dépôt de ces Cookies est susceptible de nous permettre d’accéder à
          vos données de navigation et/ou à des données à caractère personnel
          vous concernant.
        </p>

        <h2>2. Identification des Cookies</h2>
        <p>
          Nous pouvons utiliser des Cookies publicitaires, de personnalisation
          de contenu, de réseaux sociaux ou analytiques. Vous en serez informé
          lors de votre première visite sur le Site utilisant ces Cookies. Vous
          serez alors invité à les accepter ou à les refuser conformément aux
          modalités décrites ci-après.
        </p>
        <div className='pp-p-h3'>
          <p>➤</p>
          <p>
            <b>Cookies techniques et fonctionnels</b>
          </p>
        </div>
        <p>
          Les Cookies techniques et fonctionnels sont nécessaires au bon
          fonctionnement du Site et pour vous fournir nos services. Ils sont
          utilisés tout au long de votre navigation, afin de la faciliter et
          d’exécuter certaines fonctions.
        </p>
        <p>
          Un Cookie technique peut par exemple être utilisé pour mémoriser vos
          réponses renseignées dans un formulaire ou encore vos préférences
          s’agissant de la langue ou de la présentation du Site, lorsque de
          telles options sont disponibles.
        </p>
        <div className='pp-p-h3'>
          <p>➤</p>
          <p>
            <b>Cookies publicitaires</b>
          </p>
        </div>
        <p>
          Les Cookies publicitaires peuvent être créés non seulement par notre
          Site mais également par d’autres sites internet diffusant des
          publicités, annonces, widgets ou autres éléments sur la page affichée.
        </p>
        <p>
          Ces Cookies peuvent notamment servir à personnaliser et mesurer
          l&#39;efficacité de la publicité ou encore à réaliser du ciblage
          publicitaire.
        </p>
        <div className='pp-p-h3'>
          <p>➤</p>
          <p>
            <b>Cookies de personnalisation de contenu</b>
          </p>
        </div>
        <p>
          Les Cookies de personnalisation de contenu nous permettent de vous
          proposer les contenus les plus susceptibles de correspondre à vos
          centres d’intérêt au regard de votre profil de navigation.
          <br />
          Votre profil de navigation est établi en fonction des contenus que
          vous déjà avez consultés.
        </p>
        <div className='pp-p-h3'>
          <p>➤</p>
          <p>
            <b>Cookies de réseaux sociaux</b>
          </p>
        </div>
        <p>
          Les Cookies de réseaux sociaux vous permettent de partager des
          contenus de notre Site sur les réseaux sociaux et de faire connaître,
          sur ces réseaux, votre opinion ou votre consultation de nos services
          en cliquant notamment sur les liens « j’aime » et « partager ».
          <br /> Ces Cookies peuvent également permettre de tracer la navigation
          des utilisateurs sur le Site.
        </p>
        <p>
          Nous vous invitons à consulter les politiques de protection de la vie
          privée des réseaux sociaux à l’origine de ces Cookies, pour prendre
          connaissance des finalités d’utilisation des informations de
          navigation qu’ils peuvent recueillir grâce à ces Cookies et des
          modalités d’exercice de vos droits auprès de ces réseaux sociaux.
        </p>
        <div className='pp-p-h3'>
          <p>➤</p>
          <p>
            <b>Cookies analytiques</b>
          </p>
        </div>
        <p>
          Ces Cookies nous permettent de mesurer le nombre de visites, le nombre
          de pages vues et l’activité des utilisateurs. Ils peuvent le cas
          échéant collecter votre adresse IP pour déterminer la ville depuis
          laquelle vous vous connectez. Les Cookies analytiques nous permettent
          de générer des statistiques de fréquentation et de navigation de notre
          Site afin d’améliorer nos performances. Les Cookies utilisés
          permettent également d’identifier les problèmes de navigation et à
          terme, de les régler.
        </p>
        <h2>3. Vos préférences en matière de Cookies</h2>
        <div className='pp-p-h3'>
          <p>➤</p>
          <p>
            <b>Cookies pouvant être déposés sans consentement</b>
          </p>
        </div>
        <p>
          Certains Cookies ne nécessitent pas votre consentement, c’est le cas
          des :
        </p>
        <div className='pp-p-h3'>
          <p>●</p>
          <p>
            Cookies techniques et fonctionnels qui sont nécessaires au
            fonctionnement du Site ;
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>●</p>
          <p>
            De certains Cookies de mesure d’audience ou des Cookies qui
            permettent de tester des versions différentes du Site à des fins
            d’optimisation des choix éditoriaux.
          </p>
        </div>
        <div className='pp-p-h3'>
          <p>➤</p>
          <p>
            <b>
              L’acceptation ou le refus des Cookies soumis à votre consentement
              exprès
            </b>
          </p>
        </div>
        <p>
          Tous les autres Cookies nécessitent votre consentement. Il s’agit des
          Cookies publicitaires, de réseaux sociaux, de personnalisation de
          contenu et de certains Cookies d’analyse d’audience. Vous pouvez
          choisir librement d’accepter ou de refuser l’utilisation de ces
          Cookies.
        </p>
        <p>
          Vous pouvez accepter ou refuser ces Cookies lors de votre première
          navigation sur le Site.
        </p>
        <p>
          Vos choix d’accepter ou de refuser les Cookies seront conservés
          pendant une durée de six (6) mois.
        </p>
        <p>
          Vous êtes libre de retirer votre consentement et plus généralement de
          modifier vos préférences à tout moment, via l’icône qui s’affiche, en
          bas, à gauche de votre écran.
        </p>
        <div className='pp-p-h3'>
          <p>➤</p>
          <p>
            <b>Le paramétrage de votre navigateur</b>
          </p>
        </div>
        <p>
          Il est également possible de paramétrer votre navigateur afin qu’il
          accepte ou refuse certains Cookies.
        </p>
        <p>
          Chaque navigateur propose des modalités de configuration différentes.
        </p>
      </div>
    </div>
  );
}
