import { externals } from "../config";
import { handleResponse} from '../helpers';

const apiUrl = externals.apiUrl

export const settingsService = {
    getSettings,
    checkAddress
};


function getSettings() {
    const requestOptions = {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    };

    return fetch(`${apiUrl}/api/v1/requester/settings`, requestOptions).then(handleResponse)
}

function checkAddress(address) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(address)
    };

    return fetch(`${apiUrl}/api/v1/requester/check-address`, requestOptions).then(handleResponse)
}
