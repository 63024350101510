import React, { useState, useEffect } from 'react';
import ButtonBStrap from 'react-bootstrap/Button';
import './index.css';
import { useHistory, Link } from 'react-router-dom';
import { SmallScreenContext } from "../../../helpers";
import { ChevronDownIcon, ChevronUpIcon } from 'evergreen-ui'
import { ResumeSlide } from "../index";
import { alertActions, demandActions, resumeActions } from '../../../actions';
import { connect } from 'react-redux';
//import ReactPixel from 'react-facebook-pixel';
import TunnelCmdItem from "../../../components/TunnelCmdItem";
import { TunnelCmdSteps } from "../../../config/staticData";
import DoneSvg from "../../../assets/svgFiles/done";

function DemandeFinished(props) {
  const [resume, setResume] = useState(false);
  const [isPageReloaded, setPageReloaded] = useState(false);
  var model = props.model

  useEffect(() => {
      setPageReloaded(true)
      //eslint-disable-next-line
  }, [window.performance])

  useEffect(() => {
    //ReactPixel.pageView()
    return () => {
        props.initDemand()
        props.initResume()
    };
    //eslint-disable-next-line
}, [])

  const history = useHistory();

  const hideNavBarMenu = (context) => {
      context.hideMenu()
      setPageReloaded(false)
  }

  const returnToHome = (context) => {
      context.showMenu()
      props.initDemand()
      props.initResume()
      history.push('/')
  }

  const goToMySpace = (context) => {
    context.showMenu()
    props.initDemand()
    props.initResume()
    history.push('/espace')
}

const ConfirmationScreen = (context) => {
  return(
    <div className="demande-from-container">
      <h1>Hourra !</h1>
      <h6>{props.user ? props.user.firstname+"," : ""} votre {model? model.split(' - ')[1]: 'Smartphone'} vous remercie déjà 😋</h6>
      <p style={{marginTop: 50}}>Votre réservation est enregistrée</p>
      <p>Vous recevrez dans quelques instants un email de confirmation qui reprend toutes les informations.</p>
      <p>Vous pouvez modifier votre commande à n'importe quel moment depuis votre espace personnel</p>
      <div className='return-btns'>
        <ButtonBStrap className="btn-gradient" style={{margin: 15}} onClick={() => goToMySpace(context)}>
          <u>Voir mon compte</u>
        </ButtonBStrap>
        <ButtonBStrap className="btn-gradient" style={{margin: 15}} onClick={() => returnToHome(context)}>
          <u>Revenir à l'accueil</u>
        </ButtonBStrap>
      </div>
    </div>
  )
}
    return (
        <SmallScreenContext.Consumer>
        { (context) => (
            <div className="demand-container">
                <div className="demand-navbar">
                    <a onClick={() => returnToHome(context)}>  {/*eslint-disable-line*/}
                        <Link exact to="/"><div className="headerLogo2"/></Link>
                    </a>
                </div>
                {
                    context.isSmallScreen ?
                        <div className="small-demand-core">
                            <div className="small-tunnel-cmd" style={{backgroundColor: "rgb(0,240,118,0.4)"}}>
                                <div className='done-tunnel-cmd'>
                                    <DoneSvg />
                                </div>
                                <p>Demande validée !</p>
                            </div>
                            {
                                resume? null:
                                <div className='recap-btn-container'>
                                    <button 
                                        style={{backgroundColor: 'transparent', border: 'none'}}
                                        onClick={() => setResume(true)}>
                                        <ChevronDownIcon color='white' size={20}/><p>Voir le récapitulatif</p>
                                    </button>
                                </div>
                            }
                            {
                                resume ?
                                    <div className="demand-resume"> 
                                        <ResumeSlide isSmallScreen={context.isSmallScreen}/>
                                    </div>
                                    :
                                    <div className="demand-form">
                                        {ConfirmationScreen(context)}
                                    </div>
                            }
                            {
                                resume ?
                                    <div className='recap-btn-container' style={{backgroundColor: 'white'}}>
                                        <button  
                                            style={{backgroundColor: 'transparent', border: 'none'}}
                                            onClick={() => setResume(false)}>
                                            <ChevronUpIcon color='#722CC8' size={20}/><p style={{color:'#722CC8'}}>Cacher le récapitulatif</p>
                                        </button>
                                    </div>
                                    :
                                    null    
                            }
                        </div>
                        : 
                        <div>
                            <div className="tunnel-cmd">
                                {
                                    TunnelCmdSteps.map((obj, index)=>(
                                        (
                                            !index ?
                                            <TunnelCmdItem text={obj.text} icon={obj.icon} page={props.page} pageId={index+1} isFinished={true}/>
                                            :
                                            <div style={{display: 'flex'}}>
                                                <h6>/</h6>
                                                <TunnelCmdItem text={obj.text} icon={obj.icon} page={props.page} pageId={index+1} isFinished={true}/>
                                            </div>

                                        )
                                    ))
                                }
                            </div>
                            <div className="demand-core">
                                <div className="demand-form">
                                    {ConfirmationScreen(context)}
                                </div>
                                <div className="demand-resume"> 
                                    <ResumeSlide isSmallScreen={context.isSmallScreen}/>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        isPageReloaded ? hideNavBarMenu(context) : null
                    }
            </div>
            )
        }
        </SmallScreenContext.Consumer>
    );

}

function mapState(state) {
    const { alert } = state;
    const { model } = state.resume
    const { user } = state.users;
    const { page } = state.demand
    return { user, model, alert, page };
}
  
const actionCreators = {
    clearAlerts: alertActions.clear,
    initDemand: demandActions.init,
    initResume: resumeActions.init,
}

const connectedDemandeFinished = connect(mapState, actionCreators)(DemandeFinished);
export { connectedDemandeFinished as DemandeFinished };