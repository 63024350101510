import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from '../reducers';

// const loggerMiddleware = createLogger();

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let middleware = null;
let composeEnhancers = null;

if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(thunkMiddleware);
  composeEnhancers = compose;
} else {
  // middleware = applyMiddleware(thunkMiddleware, loggerMiddleware)
  middleware = applyMiddleware(thunkMiddleware);
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export const store = createStore(
  persistedReducer,
  composeEnhancers(middleware)
);

export const persistor = persistStore(store);
