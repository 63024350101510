import { useState } from "react";
import "./index.css";
import { IconButton, ChevronLeftIcon } from "evergreen-ui";
import { days, zipDefault } from "../../config";
import { contacts } from "../../config";
import { useHistory } from "react-router-dom";

const style = getComputedStyle(document.body);

function CommandesTable(props) {
  const [details, setDetails] = useState({ showDetails: false, details: {} });
  const rows = props.rows;
  const history = useHistory();

  const Intervention = (row) => {
    return (
      <div>
        <div className="cmd-list">
          <n style={{ flex: 3 }}>{row.value.name}</n>
          <b>
            {isNaN(row.value.price)
              ? row.value.price
              : `${parseFloat(row.value.price).toFixed(2)} €`}
          </b>
        </div>
        <hr className="row-separator" />
      </div>
    );
  };

  const Slot = (row) => {
    const date = new Date(row.value.slotDate);
    return (
      <div>
        <div className="cmd-list">
          <n style={{ flex: 3 }}>
            {days[date.getDay()] +
              " " +
              ("0" + date.getDate()).slice(-2) +
              "/" +
              ("0" + (date.getMonth() + 1)).slice(-2)}
          </n>
          <b>{row.value.slotTime}</b>
        </div>
        <hr className="row-separator" />
      </div>
    );
  };

  const ItemDetails = () => {
    const date = new Date(details.details.createdAt);
    return (
      <div className="cmd-details">
        <div className="icon-title">
          <div>
            <div style={{ marginTop: 5 }} />
            <IconButton
              height={25}
              icon={ChevronLeftIcon}
              iconSize={17}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                document
                  .querySelector("body")
                  .scrollTo({ top: 0, left: 0, behavior: "smooth" });
                setDetails({ showDetails: false, details: {} });
              }}
            />
          </div>
          <h2>Détails de la commande</h2>
        </div>
        <h4>
          Commande N°: <n>{details.details.orderNum}</n>
        </h4>
        <h4>
          Date de Commande:{" "}
          <n>
            {("0" + date.getDate()).slice(-2) +
              "/" +
              ("0" + (date.getMonth() + 1)).slice(-2) +
              "/" +
              date.getFullYear()}
          </n>
        </h4>
        <h4>
          Modèle: <n>{details.details.model.name}</n>
        </h4>
        <h4>
          Etat:{" "}
          <n style={{ color: details.details.status.color }}>
            {details.details.status.name}
          </n>
        </h4>
        <h4>
          Adresse d'intervention:{" "}
          <n>
            {details.details.customerAddress1}
            {details.details.customerZip === zipDefault.notFound ||
            details.details.customerZip === zipDefault.notUsed
              ? ""
              : ", " + details.details.customerZip}
            {details.details.customerCity === details.details.customerAddress1
              ? ""
              : ", " + details.details.customerCity}
          </n>
        </h4>
        <div style={{ marginBottom: 30 }} />
        <div className="title-cmd-list">
          <n style={{ color: style.getPropertyValue("--black-050"), flex: 3 }}>
            Interventions
          </n>
          <n style={{ color: style.getPropertyValue("--black-050") }}>
            Prix
          </n>
        </div>
        <hr className="title-row-separator" />
        {/* <div className="title-cmd-list">
          <n style={{ color: style.getPropertyValue("--tertiary-1"), flex: 3 }}>
            Deplacement technicien certifié
          </n>
          <n style={{ color: style.getPropertyValue("--tertiary-1") }}>
            Offert
          </n>
        </div> */}
        {/* <hr className="title-row-separator" /> */}
        <ul>
          {details.details.totals.map((row) => (
            <Intervention key={row.uuid.toString()} value={row} />
          ))}
        </ul>
        {/* <div className="title-cmd-list">
          <n style={{ color: style.getPropertyValue("--tertiary-1"), flex: 3 }}>
            Total TTC
          </n>
          <n style={{ color: style.getPropertyValue("--tertiary-2") }}>
            {parseFloat(details.details.amountDiscounted).toFixed(2)} €
          </n>
        </div> */}
        {/* <hr className="title-row-separator" /> */}
        <div style={{ marginBottom: 30 }} />
        <h4>Créneaux sélectionnés: </h4>
        <div className="title-cmd-list">
          <n style={{ color: style.getPropertyValue("--black-050"), flex: 3 }}>
            Date
          </n>
          <n style={{ color: style.getPropertyValue("--black-050") }}>Heure</n>
        </div>
        <hr className="title-row-separator" />
        <ul>
          {details.details.slots.map((row) => (
            <Slot key={row.uuid.toString()} value={row} />
          ))}
        </ul>
        <div style={{ marginBottom: 50 }} />
        <h4>
          Vous souhaitez modifier votre commande ?
          <n
            onClick={() => history.push("/contact")}
            style={{
              cursor: "pointer",
              marginLeft: 10,
              textDecoration: "underline",
            }}
          >
            contactez-nous
          </n>
        </h4>
      </div>
    );
  };

  const ListItem = (row) => {
    // Correct! There is no need to specify the key here:
    const date = new Date(row.value.createdAt);
    return (
      <div>
        <div className="cmd-list">
          <n>{row.value.orderNum}</n>
          <n>
            {("0" + date.getDate()).slice(-2) +
              "/" +
              ("0" + (date.getMonth() + 1)).slice(-2) +
              "/" +
              date.getFullYear()}
          </n>
          <b>{row.value.model.name}</b>
          <c
            className="cmd-status"
            style={{ color: row.value.status.color }}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              document
                .querySelector("body")
                .scrollTo({ top: 0, left: 0, behavior: "smooth" });
              setDetails({ showDetails: true, details: row.value });
            }}
          >
            {row.value.status.name}
          </c>
        </div>
        <hr className="row-separator" />
      </div>
    );
  };

  const listItems = rows.map((row) => (
    <ListItem key={row.orderNum.toString()} value={row} />
  ));

  return details.showDetails ? (
    <ItemDetails />
  ) : (
    <div style={{ paddingBottom: 20 }}>
      <h1 className="infos-perso">Vos commandes</h1>
      <div style={{ marginBottom: 30 }} />
      <div className="title-cmd-list">
        <n>N° de commande</n>
        <n>Date</n>
        <n>Modèle</n>
        <n>Statut</n>
      </div>
      <hr className="title-row-separator" />
      <ul>{listItems}</ul>
      <div style={{ marginTop: 76 }} />
      <div className="cmd-footer">
        <n>
          Besoin d'un conseil ou d'assistance ? Contactez-nous au
          <h>{contacts.tel}</h>
        </n>
      </div>
    </div>
  );
}

export default CommandesTable;
