import './EntrepriseBlock2.css'
import Icon1 from "../../assets/icons/1.png";
import Icon2 from "../../assets/icons/2.png"
import Icon3 from "../../assets/icons/3.png"

export function EntrepriseBlock2() {
    return(
       <div className='EntrepriseBlock2-container'>
           <h1>Devenons partenaires !</h1>
           <p>Notre offre de partenariat s'adapte aux besoins de vos collaborateurs. Nous vous proposons une équipe réactive pour réparer vos smartphones et tablettes ainsi qu'un kit de communication.</p>
           <div className='EntrepriseBlock2-blocks'>
               <div className='EntrepriseBlock2-block'>
                    <div style={{backgroundImage: `url(${Icon1})`}} />
                    <h2>Une mise à disposition de notre application de pré-diagnostic</h2>
               </div>
               <div className='EntrepriseBlock2-block'>
                    <div style={{backgroundImage: `url(${Icon2})`}} />
                    <h2>Une réparation en moins de 30mn là où vous êtes.</h2>
               </div>
               <div className='EntrepriseBlock2-block'>
                    <div style={{backgroundImage: `url(${Icon3})`}} />
                    <h2>Un technicien certifié et un Atelier Mobile qui vient en bas de vos locaux</h2>
               </div>
           </div>
       </div>
    )
}