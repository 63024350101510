import { contactConstants } from '../constants';
import { contactService } from '../services';
import { alertActions } from '.';

export const contactActions = {
    sendContact
};

function sendContact(infoContact) {
    return dispatch => {
        dispatch(request());
        contactService.contact(infoContact)
            .then(
                response => { 
                    dispatch(success());
                    dispatch(alertActions.success(response.data.message.toString()));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: contactConstants.CONTACT_REQUEST } }
    function success() { return { type: contactConstants.CONTACT_SUCCESS } }
    function failure() { return { type: contactConstants.CONTACT_FAILURE } }
}