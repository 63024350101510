import React from "react";
import "./index.css";
import { useHistory } from "react-router-dom";
import { InfoSignIcon, Button, LockIcon, LogOutIcon } from "evergreen-ui";

export default function InfoCard(props) {
  const history = useHistory();
  return (
    <div className="info-card">
      <h1 className="infos-perso">Infos Perso</h1>
      <h2>Adresse</h2>
      <p>{props.adresse}</p>
      <h2>Mobile</h2>
      <p>{props.phoneNumber}</p>
      <h2>E-mail</h2>
      <p>{props.email}</p>
      <hr className="card-separator" />
      <Button
        height={40}
        marginRight={5}
        appearance="minimal"
        iconBefore={LockIcon}
        onClick={() => history.push("/change-password")}
      >
        Changer mot de passe
      </Button>
      <Button
        height={40}
        marginRight={5}
        appearance="minimal"
        iconBefore={InfoSignIcon}
        className="btn_update_user_infos"
        onClick={() => history.push("/update-user-infos")}
      >
        Modifier vos informations personnelles
      </Button>
      <Button
        height={40}
        marginRight={5}
        appearance="minimal"
        iconBefore={LogOutIcon}
        onClick={props.onClick}
      >
        Se déconnecter
      </Button>
      <div style={{ paddingBottom: 60 }} />
    </div>
  );
}
