import './EntrepriseBlock1.css'
import NeeroIcon from "../../assets/icons/neero.png";

const PointNumber = ({number}) => (<div className='entreprise-point-number'><div><h2>{number}</h2></div></div>)
export function EntrepriseBlock1() {
    return(
        <div className='EntrepriseBlock1-container'>
            <div className='EntrepriseBlock1-title'>
                <h1>Pourquoi devenir partenaire</h1>
                <div style={{backgroundImage: `url(${NeeroIcon})`}} />
                <h1>?</h1>
            </div>
            <div className='EntrepriseBlock1-blocks'>
                <div className='EntrepriseBlock1-block'>
                    <PointNumber number={1}/>
                    <h3>Practicité de l’offre</h3>
                    <p>Offrez-leur le meilleur des conforts en leur faisant gagner du temps ! L’atelier mobile se déplace jusqu’à eux pour réparer leur smartphone en un temps record. Ils le déposent en bas de vos locaux et le récupèrent comme neuf juste après leur réunion.</p>
                </div>
                <div className='EntrepriseBlock1-block'>
                    <PointNumber number={2}/>
                    <h3>Tarifs préférentiels</h3>
                    <p>Les entreprises bénéficient d’une remise selon le volume et la fréquence de réparations.<br />Contactez-nous et discutons ensemble de vos besoins !</p>
                </div>
                <div className='EntrepriseBlock1-block'>
                    <PointNumber number={3}/>
                    <h3>Démarche responsable</h3>
                    <p>Chez Neero, jeter, c’est tricher !<br/>Développons le bon geste en faisant réparer les appareils plutôt qu'en en achetant de nouveaux.</p>
                </div>
                <div className='EntrepriseBlock1-block'>
                    <PointNumber number={4}/>
                    <h3>Fiabilité de l’offre</h3>
                    <p>On ne donne pas son téléphone à n’importe qui. Chez Neero, la confidentialité des données est garantie par des techniciens diplômés. Tout se passe sous vos yeux, dans notre atelier mobile, en moins de 30 minutes.</p>
                </div>
            </div>
        </div>
    )
}