import React, { useState, useEffect } from 'react';
import './index.css';
import ButtonBStrap from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { demandActions, resumeActions, alertActions, discountActions } from '../../../actions';
import { connect } from 'react-redux';
import { RegisterPage } from '../Register'
import { Spinner } from 'evergreen-ui'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from '../../../components/CheckBoxStyles'
// import CancelIcon from '../../../assets/icons/cancel.svg'
// import PayIcon from '../../../assets/icons/Pay-debit-card.svg'
// import WarrantyIcon from '../../../assets/icons/warranty.svg'
import clsx from 'clsx';
import { staticId } from "../../../config";
//import ReactPixel from 'react-facebook-pixel';
import DemandeFooter from "./DemandeFooter";

function DemandeFormP4(props) {
  const [openDialog, setOpenDialog] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('user'))
  const [CGUChecked, setCGUCheched] = useState(false)
  const [CGUerrMsg, setCGUerrMsg] = useState('')
  //const [response, setResponse] = useState('')
  const [coupon, setCoupon] = useState({coupon: props.coupon? props.coupon: null, valide: false, errMsg: '', checking: false})
  const [buttonsActive, setButtonsActive] = useState(true)
  //const [couponLoading, setCouponLoading] = useState(false)
  var response= ''

  const history = useHistory();
  const classesCB = useStyles();



  const handleSubmit = () => {

    if (CGUChecked) {
      props.setDemand(props.demand, props.resume, coupon.valide? props.coupon: null)
      setButtonsActive(false)
    }
    else setCGUerrMsg("Vous devez accepter les conditions d'utilisation")
  }

  const handleClose = () => {
    setOpenDialog(false);
    props.clearAlert()
    if (redirect){
      setRedirect(false)
      if (response !== "Mail envoyé,Consultez votre boite mail pour réinitialiser votre mot de passe !"){
        props.initDemand()
        props.initResume()
        props.context.showMenu()
        history.push('/')
      }
    }
  }

  useEffect(() => {
    //ReactPixel.track('ViewContent',props.resume)
    //eslint-disable-next-line
  },[])

  useEffect(() => {
    if (props.alert.type === 'alert-danger')  {
      setOpenDialog(true)
      setButtonsActive(true)
    }
    if (props.alert.type === 'alert-success')  {
      //setResponse(props.alert.message.toString())
      props.clearAlert()
      if (response !== "Mail envoyé,Consultez votre boite mail pour réinitialiser votre mot de passe !"){
       /*  window.dataLayer.push({
          event: 'inscription',
        });
       window.gtag('event', 'conversion', {
          'send_to': 'AW-403051804/1KFRCMP45_8CEJyqmMAB',
          'value': sumTotal(props.resume.price)?.toFixed(2),
          'currency': 'EUR',
        });*/
        window.dataLayer.push({'event':'confirmDemandClick',
          'conversionValue':props.demand.lastOrder.amountDiscounted,
          'conversionEmail':props.demand.lastOrder.customer.email,
          'conversionID':props.demand.lastOrder.uuid,
        });
        history.push('/demandefinished')
      }
      //setRedirect(true)
      //setOpenDialog(true)
    }
    //eslint-disable-next-line
  }, [props.alert.message])

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem('user'))
  },[props.authentication])

  const cguChange = (e) => {
    setCGUCheched(e.target.checked)
    setCGUerrMsg('')
  }

  const checkCoupon = (e) =>{
    e.preventDefault();
    //setCouponLoading(true)
    props.checkCoupon(coupon.coupon)
    /*.then((response) => {
      setCouponLoading(false)
      setCoupon({coupon: coupon.coupon, valide: response.applied, checking: false, errMsg: response.message})
      props.getDiscount(props.demand.model, props.resume.price, coupon.coupon)
    })
    .catch(error => {
      setCouponLoading(false)
      setCoupon({coupon: coupon.coupon, valide: false, checking: false, errMsg: 'Coupon non valide'})
      console.log('******ERERERER******',error)
    })*/
  }

  useEffect(() => {
    if (props.couponMsg === 'Validation errors'){
      setCoupon({coupon: coupon.coupon, valide: false, checking: false, errMsg: 'Coupon non valide'})
      props.getDiscount(props.demand.model, props.resume.price, null)
    }
    else{
      setCoupon({coupon: coupon.coupon, valide: props.couponMsg?.applied, checking: false, errMsg: props.couponMsg?.message})
      props.getDiscount(props.demand.model, props.resume.price, coupon.coupon)
    }
    //eslint-disable-next-line
  },[props.coupon])


  // const Explication = () =>{
  //   return(
  //     <div style={{marginTop: 15,marginBottom: 40}}>
  //       <div className='explic-row'>
  //         <div className='expli-icon' style={{backgroundImage: `url(${CancelIcon})`}} />
  //         <div className='explic-text'>
  //           <h1>Annulation gratuite</h1>
  //           <p>Annulez votre réservation à n'importe quel moment sans frais depuis votre compte.</p>
  //         </div>
  //       </div>
  //       <div className='explic-row'>
  //         <div className='expli-icon' style={{backgroundImage: `url(${WarrantyIcon})`}} />
  //         <div className='explic-text'>
  //           <h1>Garantie 12 mois</h1>
  //           <p>Toutes nos réparations sont garanties 12 mois. Assistance 7/7j en ligne sur le chat.</p>
  //         </div>
  //       </div>
  //       <div className='explic-row'>
  //         <div className='expli-icon' style={{backgroundImage: `url(${PayIcon})`}} />
  //         <div className='explic-text'>
  //           <h1>Payez sur place</h1>
  //           <p>Aucun prépaiement en ligne n'est requis, payez sur place une fois la réparation réussie.</p>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  const sumTotal = arr => arr?.reduce((sum, { price }) => Number.isFinite(price) ? sum + price: sum, 0)

  return (
    <>
      <div className="demande-from-container">
          <h1>{isLoggedIn ? "On y est !":"Un dernier effort, c’est promis !"}</h1>
          { isLoggedIn ?
              <p style={{fontSize: 16}}>C'est la dernière étape</p>:
              <p>Pour venir vous voir, il nous faut vos coordonnées<br />Merci de remplir les champs ci-dessous👇</p>
          }
          { isLoggedIn ?
              <div><h1 style={{marginTop: 50, fontWeight: 500, fontSize: 18}}>C'est l'étape où on vous demande de payer d'avance mais on ne le fera pas 🤗<br/>
              Comme on vous l'a dit, vous ne payez qu'une fois la réparation réussie, chez-vous !</h1>
              <h1 style={{margin: "30px 10px 50px 0", fontWeight: 500}}>Vous pouvez réserver dès maintenant, la modification et annulation de votre réservation est gratuite 😇</h1>
              <div className='just-mobile'>
                <h1>Récapitulatif de votre commande : </h1>
                {
                  props.discount ?
                  props.discount.map((row) =>{
                   var fixedPrice = row.price;
                    if(row.code !=='shipping'){
                      fixedPrice = parseFloat(fixedPrice).toFixed(2);
                    }
                   return <p  key={Math.random()} >-  {row.name}   { fixedPrice + ' €' }</p>;
                  }
                  )
                  :null
                }

              </div>
              <h1 style={{paddingTop:20}}>Code de réduction</h1>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <TextField
                  label="Tapez votre code"
                  onKeyPress={(e) => { e.key === 'Enter' && checkCoupon(e) }}
                  type="text"
                  defaultValue={props.coupon}
                  onChange={(text) => setCoupon({ coupon: text.target.value, valide: false, errMsg: '', checking: false})}
                  variant="outlined"
                  color={coupon.valide ? "secondary" : "primary"}
                  disabled={props.couponLoading || !buttonsActive}
                />
                {
                  buttonsActive && !props.couponLoading?
                    <ButtonBStrap className="btn-gradient" onClick={checkCoupon} style={{margin: '3px 0 0 20px', height: 50}}>
                      <u>Valider</u>
                    </ButtonBStrap>
                    : null
                }
                {props.couponLoading ? <Spinner style={{alignSelf: "center", marginLeft: 20}}/>: null}
              </div>
              <em style={{color: coupon.valide? '#27C780' : '#EE4000'}}>{coupon.errMsg}</em>
              {//coupon.valide? <p style={{color:'green'}}>Ce coupon est valide </p>:null
              }
              <div style={{display: "flex", flexDirection: "row", marginBottom:-20, marginTop: 20}}>
                <Checkbox
                    className={classesCB.root}
                    disableRipple
                    checkedIcon={<span className={clsx(classesCB.icon, classesCB.checkedIcon)} />}
                    icon={<span className={classesCB.icon} />}
                    checked={CGUChecked}
                    style ={{ backgroundColor: 'white'}}
                    onChange={cguChange}
                />
                <h5 >J'ai lu et j'acceptes les <u onClick={()=>history.push('/cgu')} style={{ cursor: "pointer"}}>CGU</u> de neero</h5>
              </div>
              <em style={{marginLeft: 32}}>{CGUerrMsg}</em>
              <div style={{display: "flex", flexDirection: "row"}}>
                {
                  buttonsActive ?
                    <ButtonBStrap className="btn-gradient" onClick={handleSubmit} >
                      <u>Valider ma demande</u>
                    </ButtonBStrap>
                  : <div style={{marginTop: 90}} />
                }
                { props.demand.loading ? <Spinner style={{alignSelf: "center", marginTop: 10}}/> : null }
              </div>
              <div className='just-mobile'>
                {buttonsActive ? <u onClick={()=> props.setPage(1)}>Modifier ma commande</u>: null}
              </div>
            </div>
            :<RegisterPage  openDialog={openDialog}/>
          }
        </div>
        <Dialog
          fullWidth={true}
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {redirect ? "Demande envoyée avec succès": "Oups !"}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>{props.alert.message}</p>
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          {
            redirect ? null:
            <ButtonBStrap className="btn-gradient" onClick={()=>history.push('login/resetpassword')} style={{margin: 10}}>
              <u>{ "Réinitialiser le mot de passe"}</u>
            </ButtonBStrap>
          }
          <ButtonBStrap className="btn-gradient" onClick={handleClose} style={{margin: 10}}>
            <u>{redirect ? "Ok" :"Réessayer"}</u>
          </ButtonBStrap>
          </DialogActions>
        </Dialog>
        <DemandeFooter />
      </>
  );

}



function mapState(state) {
  const { demand, resume, alert, authentication } = state;
  const { discount, coupon, couponLoading, couponMsg } = state.discount
  return { demand, resume, alert, authentication, discount, coupon, couponLoading, couponMsg  };
}

const actionCreators = {
  setDemand: demandActions.setDemand,
  initDemand: demandActions.init,
  initResume: resumeActions.init,
  clearAlert: alertActions.clear,
  setPage: demandActions.setPage,
  getDiscount: discountActions.getDiscount,
  checkCoupon: discountActions.checkCoupon
};

const connectedDemandeFormP4 = connect(mapState, actionCreators)(DemandeFormP4);
export { connectedDemandeFormP4 as DemandeFormP4 };