import { slotsConstants } from '../constants';

const initialState = {
    slots: null,
    loading: false,
}

export function slots(state = initialState, action) {
  switch (action.type) {
    case slotsConstants.INIT:
        return {
            slots: null,
            loading: false,
        };
    case slotsConstants.SET_SLOTS:
        return{
            ...state,
            slots: action.slots,
            loading: false
        }
    case slotsConstants.SET_LOADING:
        return{
            ...state,
            loading: true
        }
    case slotsConstants.SLOTS_FAILED:
        return {
            ...state,
            loading: false
        }
    default:
        return state
  }
}