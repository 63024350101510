import { alertConstants } from '../constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    let msg = message
    if (message === "Expired JWT Token") msg = "Session expirée, reconnectez-vous !"
    if (message === "TypeError: Cannot read property 'refresh_token' of null") msg = "L'adresse e-mail ou le mot de passe est invalide"
    if (message === "email: Cette adresse e-mail est déjà utilisée.") msg = "Cette adresse email est déjà inscrite chez nous 🤔"
    return { type: alertConstants.ERROR, message: msg };
}

function clear() {
    return { type: alertConstants.CLEAR };
}