import React from 'react';
import './change-password.css';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { IconButton as IconButtonEG, ChevronLeftIcon } from 'evergreen-ui';
import ButtonBStrap from 'react-bootstrap/Button';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [rpassword, setRpassword] = useState('');
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRpassword, setShowRpassword] = useState(false);

  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!password) {
      toast.error('Veuillez insérer votre ancien mot de passe');
      return;
    }

    if (password.length < 6) {
      toast.error(
        'Votre ancien mot de passe doit avoir une longueur minimum de 6 caractères'
      );
      return;
    }

    if (!rpassword) {
      toast.error('Veuillez insérer votre nouveau mot de passe');
      return;
    }

    if (rpassword.length < 6) {
      toast.error(
        'Votre nouveau mot de passe doit avoir une longueur minimum de 6 caractères'
      );
      return;
    }

    setLoader(true);

    const token = JSON.parse(localStorage.getItem('user')).token;

    axios
      .put(
        `${apiBaseUrl}/api/v1/requester/user/change-password`,
        {
          oldPassword: password,
          newPassword: rpassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setLoader(false);
        toast.success('Mot de passe mis à jour avec succès');
        setPassword('');
        setRpassword('');
        history.push('/espace');
      })
      .catch((err) => {
        setLoader(false);

        if (err.response.data.code === 400) {
          if (err.response.data.errors.length > 0) {
            const errors = err.response.data.errors;

            errors.forEach(({ propertyPath, message }) => {
              if (propertyPath === 'newPassword') {
                toast.error(`Nouveau mot de passe, ${message}`);
              } else if (propertyPath === 'oldPassword') {
                toast.error(`Ancien mot de passe, ${message}`);
              }
            });
          }
        }
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowRpassword = () => {
    setShowRpassword(!showRpassword);
  };

  if (loader) return <Loader />;

  return (
    <section className="section_change_password">
      <div className="icon-title">
        <div>
          <div style={{ marginTop: 5 }} />
          <IconButtonEG
            height={25}
            icon={ChevronLeftIcon}
            iconSize={17}
            onClick={() => {
              history.goBack();
            }}
          />
        </div>
        <h2>Modifier votre mot de passe</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="mpd-actuel">Mot de passe actuel</InputLabel>
          <OutlinedInput
            id="mpd-actuel"
            labelWidth={150}
            className="input"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="rmdp">Nouveau mot de passe</InputLabel>
          <OutlinedInput
            id="rmdp"
            labelWidth={170}
            className="input"
            type={showRpassword ? 'text' : 'password'}
            value={rpassword}
            onChange={(e) => setRpassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowRpassword}
                >
                  {showRpassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <ButtonBStrap className="btn-gradient" type="submit">
          <u>Modifier</u>
        </ButtonBStrap>
      </form>
    </section>
  );
};

export default ChangePassword;
