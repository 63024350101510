import React from "react";
import "./index.css";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import clsx from "clsx";
import ButtonBStrap from "react-bootstrap/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Spinner } from "evergreen-ui";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import { SmallScreenContext } from "../../helpers";
import AppDownloadV2 from "../../components/AppDownloadV2";
import { regex } from "../../config";
//import ReactPixel from 'react-facebook-pixel';

const reg = regex.email;

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

function LoginPage(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
    emailErrorMsg: "",
    passwordErrorMsg: "",
    emailValide: false,
    passwordValide: false,
    openDialog: false,
    loading: false,
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "email") emailValidate(event.target.value);
    else passwordValidate(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const emailValidate = text => {
    if (reg.test(text) === false) {
      setValues({
        ...values,
        email: "",
        emailErrorMsg: "Adresse e-mail invalide",
        emailValide: false,
      });
    } else {
      setValues({
        ...values,
        email: text,
        emailErrorMsg: "",
        emailValide: true,
      });
    }
  };

  const passwordValidate = text => {
    if (text.length < 8) {
      setValues({
        ...values,
        password: "",
        passwordErrorMsg: "Saisir au moins 8 caractères",
        passwordValide: false,
      });
    } else {
      setValues({
        ...values,
        password: text,
        passwordErrorMsg: "",
        passwordValide: true,
      });
    }
  };

  React.useEffect(() => {
    if (props.alert.type === "alert-danger")
      setValues({
        ...values,
        loading: false,
        passwordErrorMsg: "L'adresse e-mail ou le mot de passe est invalide",
      });
    // eslint-disable-next-line
  }, [props.alert]);

  React.useEffect(() => {
    // ReactPixel.pageView()
  }, []);

  const login = e => {
    e.preventDefault();
    if (values.emailValide && values.passwordValide) {
      props.login(values.email, values.password, true);
      setValues({
        ...values,
        loading: true,
        showPassword: false,
        emailErrorMsg: "",
        passwordErrorMsg: "",
      });
    } else {
      if (!values.email)
        if (!values.password)
          setValues({
            ...values,
            emailErrorMsg: "Adresse e-mail invalide",
            passwordErrorMsg: "Saisir au moins 8 caractères",
          });
        else setValues({ ...values, emailErrorMsg: "Adresse e-mail invalide" });
      else if (!values.password)
        setValues({
          ...values,
          passwordErrorMsg: "Saisir au moins 8 caractères",
        });
    }
    // setValues({ ...values, emailErrorMsg:'',emailValide:false, passwordErrorMsg:'', passwordValide:false });
  };

  const handleClose = () => {
    setValues({ ...values, openDialog: false });
  };

  const history = useHistory();

  return (
    <SmallScreenContext.Consumer>
      {context => (
        <div>
          <div className='espace-background'>
            <div className='espace-background-card'>
              <div style={{ paddingTop: context.isSmallScreen ? "5%" : 40 }} />
              <div className='login-card'>
                <form className='login' onSubmit={login}>
                  <h2>Connexion</h2>
                  <FormControl>
                    <TextField
                      id='outlined-error-helper-text'
                      label='E-mail'
                      onKeyPress={e => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      //error
                      //helperText="Incorrect entry."
                      type='email'
                      onChange={handleChange("email")}
                      variant='outlined'
                      color={values.emailValide ? "primary" : "secondary"}
                    />
                    <em>{values.emailErrorMsg}</em>
                  </FormControl>
                  <FormControl
                    className={clsx(classes.margin)}
                    variant='outlined'
                  >
                    <InputLabel
                      color={values.passwordValide ? "primary" : "secondary"}
                      htmlFor='outlined-adornment-password'
                    >
                      Mot de passe
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-password'
                      type={values.showPassword ? "text" : "password"}
                      onChange={handleChange("password")}
                      onKeyPress={e => {
                        e.key === "Enter" && login(e);
                      }}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={100}
                      color={values.passwordValide ? "primary" : "secondary"}
                    />
                    <em>{values.passwordErrorMsg}</em>
                  </FormControl>
                  <div className='login-btns'>
                    <ButtonBStrap
                      className='btn-cancel'
                      onClick={() => history.push("/login/resetpassword")}
                    >
                      <u>Mot de passe oublié</u>
                    </ButtonBStrap>
                    <ButtonBStrap className='btn-gradient' onClick={login}>
                      <u>Se connecter</u>
                    </ButtonBStrap>
                  </div>
                  {values.loading ? (
                    <Spinner style={{ alignSelf: "center" }} />
                  ) : null}
                  <p>Vous n’êtes pas encore membre ?</p>
                  <Button
                    className='btn-register'
                    height={40}
                    appearance='minimal'
                    onClick={() => history.push("/register")}
                  >
                    <a>Créer un compte</a>
                    {/* eslint-disable-line */}
                  </Button>
                  <Dialog
                    fullWidth={true}
                    open={values.openDialog}
                    onClose={handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                  >
                    <DialogTitle id='alert-dialog-title'>
                      {"Erreur de Connexion"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-description'>
                        {props.alert.message}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <ButtonBStrap
                        className='btn-gradient'
                        onClick={handleClose}
                        style={{ margin: 7 }}
                      >
                        <u>Réessayer</u>
                      </ButtonBStrap>
                    </DialogActions>
                  </Dialog>
                </form>
              </div>
            </div>
          </div>
          <AppDownloadV2 />
        </div>
      )}
    </SmallScreenContext.Consumer>
  );
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return { loggingIn, alert };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
